import React, { useState } from 'react'
import Contests from './Contests/Contests';
import Rankings from './Rankings/Rankings';
import './Main.css';

const Main = () => {
    const [view, setView] = useState(0);

    return (
        <div className='my-5 col-md-10 col-11 mx-auto'>
            <div className='d-flex gap-3 fs-6 my-4 d-block d-lg-none'>
                <div
                    className='px-3 py-1 rounded-1'
                    style={{ cursor: 'pointer', background: `${view === 0 ? 'var(--primary)' : 'var(--gray-200)'}`, color: `${view === 0 ? 'white' : 'var(--gray-700)'}` }}
                    onClick={() => setView(0)}
                >Contest</div>
                <div
                    className='px-3 py-1 rounded-1'
                    style={{ cursor: 'pointer', background: `${view === 1 ? 'var(--primary)' : 'var(--gray-200)'}`, color: `${view === 1 ? 'white' : 'var(--gray-700)'}` }}
                    onClick={() => setView(1)}
                >Ranking</div>
            </div>
            <div className='d-flex justify-content-between gap-4'>
                <Contests view={view} />
                <Rankings view={view} />
            </div>
        </div>
    )
}

export default Main
