import React, { useState } from 'react'
import { BsBookmarkFill } from 'react-icons/bs';
import { Offcanvas } from 'react-bootstrap';
import BookMarkQuestionCard from './BookMarkQuestionCard';
import { useSelector } from 'react-redux';

const BookMarkCard = ({ data, bookmarkId, setBookmarkedId, setShowConfirm }) => {
    const [open, setOpen] = useState(false);
    const { jwtToken } = useSelector((state) => state.user)



    return (
        <div className='d-flex flex-column gap-3 col-12 p-md-4 p-3 rounded-2' style={{ background: 'var(--white)', border: '2px solid var(--gray-300)' }}>
            {/* ------------------------------------------------------------------------ */}
            {/* Question Title Section Start */}
            {/* ------------------------------------------------------------------------ */}
            <div className='d-flex justify-content-between align-items-center'>
                <div className='px-2 py-1 rounded-5' style={{ background: 'var(--gray-200)', fontWeight: '600', color: 'var(--gray-700)' }}>
                    Question {data?.number}
                </div>
                <div className='cursor' style={{ color: 'var(--primary)' }} onClick={() => { setShowConfirm(true); setBookmarkedId(bookmarkId) }}>
                    {
                        <BsBookmarkFill />
                    }
                </div>
            </div>
            {/* ------------------------------------------------------------------------ */}
            {/* Question Title Section End */}
            {/* ------------------------------------------------------------------------ */}

            {/* ------------------------------------------------------------------------ */}
            {/* Question Start */}
            {/* ------------------------------------------------------------------------ */}
            <div className='cursor' onClick={() => setOpen(true)} style={{ maxHeight: '100px', overflowX: 'auto' }} dangerouslySetInnerHTML={{ __html: data?.question }}>
            </div>
            {/* ------------------------------------------------------------------------ */}
            {/* Question End */}
            {/* ------------------------------------------------------------------------ */}

            {/* ------------------------------------------------------------------------ */}
            {/* Question Tag Start */}
            {/* ------------------------------------------------------------------------ */}
            <div className='d-flex flex-wrap gap-2'>
                <div className='px-2 py-1 rounded-1' style={{ background: 'var(--gray-200)' }}>
                    {data?.questionSubjectId?.name}
                </div>
                {
                    data?.questionsTags?.map((d, index) =>
                        <div className='p-1 px-2 rounded-2' style={{ background: 'var(--gray-200)' }} key={index}>{d?.name}</div>
                    )
                }
            </div>
            {/* ------------------------------------------------------------------------ */}
            {/* Question Tag End */}
            {/* ------------------------------------------------------------------------ */}

            <Offcanvas placement='end' className={`offcanvas px-1 pt-4`} style={{ maxWidth: '100vw' }} show={open} onHide={() => { setOpen(false) }}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <BookMarkQuestionCard data={data} bookmarkedId={bookmarkId} />
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default BookMarkCard;
