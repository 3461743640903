import React, { useEffect, useState } from 'react';
import boy from '.././home_assets/boy.svg';
import girl from '.././home_assets/girl.svg';
import man from '.././home_assets/man.svg';
import search from '.././home_assets/search.svg';
import './QuestionSection.css';
import Question from './Question';
import { data, searchData } from './QuestionData';

const QuestionSection = () => {
    const sections = ['General', 'Practice', 'Community', 'Compete'];
    const [option, setOption] = useState(2);
    const [inputData, setInputData] = useState('');
    const [choice, setChoice] = useState(true)

    useEffect(() => {
        if (inputData.length > 0) {
            setChoice(false);
            console.log(inputData)
        }
        else {
            setChoice(true);
        }
    }, [inputData])
    return (
        <div className='my-6 col-md-10 col-11 mx-auto'>
            <div className='d-flex gap-2 justify-content-between'>
                <h2 className='font3 fw-bold position-relative' style={{ color: 'var(--primary)' }}>
                    Frequently Asked <br />Questions
                    <div className='fw-bold font6 position-absolute start-0' style={{ zIndex: '-1', color: 'var(--gray-200)', top: '-3rem', start: '-1rem' }}>Questions</div>
                </h2>
                <div className='d-flex gap-2'>
                    <div className='d-flex flex-column gap-3'>
                        <img src={girl} className='position-relative width60' style={{ bottom: '1rem' }} alt='unbind-FAQs' />
                        <img src={boy} className='position-relative width60' style={{ right: '3rem' }} alt='unbind-FAQs' />
                    </div>
                    <img src={man} className='width160' alt='unbind-FAQs' />
                </div>
            </div>
            <div className=''>
                <div className='font1half my-3 d-flex gap-4 p-1 rounded-4 align-items-center' style={{ backgroundColor: 'var(--gray-200)', width: 'fit-content' }}>
                    <input type='text' placeholder='Search Your Questions' value={inputData} onChange={(e) => setInputData(e.target.value)} className='p-2 ps-3 questionSearchBox' style={{ background: 'transparent', border: '0', outline: 'none' }} />
                    <div className=' px-2 me-2 rounded-2' style={{ backgroundColor: 'white' }}>
                        <img src={search} style={{ width: '20px' }} alt='search' />
                    </div>
                </div>
            </div>
            <div className='d-flex gap-2 justify-content-between my-3 font1half align-items-start wrapboxCol'>
                <div className='d-flex gap3 mb-3 wrapboxRow scrollHide' style={{ overflowX: 'auto', maxWidth: '90vw' }}>
                    {
                        sections.map((section, index) =>
                            <h3 className='rounded-5 px-3 py-1 ' style={{ backgroundColor: `${(option === index && choice) ? 'var(--primary)' : 'white'}`, color: `${(option === index && choice) ? 'white' : 'black'}`, cursor: 'pointer', fontWeight: '400' }} key={index} onClick={() => setOption(index)}>
                                {section}
                            </h3>
                        )
                    }
                </div>
                <div className='d-flex flex-column gap-3 questionContainer' style={{ overflowY: 'auto' }}>
                    {
                        choice ?
                            data[option].map((d, index) =>
                                <Question key={index} data={d} />
                            )
                            :
                            searchData.filter((data) => (data.question.includes(inputData) || data.answer.includes(inputData))).map((d, index) =>
                                <Question key={index} data={d} />
                            )
                    }
                </div>
            </div>
        </div>
    )
}

export default QuestionSection
