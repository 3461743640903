import React from 'react'
import TopSection from './components/TopSection'
import { useNavigate, useParams } from 'react-router-dom'
import ListCardView2 from '../Card_components/ListCardView2';
import Pagination from '../Paginations/Pagination';
import { useState } from 'react';
import { useEffect } from 'react';

const Topics = ({ activeSubject, subjectLists, topics, totalPages, activePage, setActivePage }) => {
    const { subjectId } = useParams();
    const [subject, setSubject] = useState(subjectId);
    const navigate = useNavigate();

    const menuFlow = [
        {
            menu: 'Practice',
            link: '/app/practice'
        },
        {
            menu: activeSubject?.name,
            link: `/app/practice/${subjectId}`
        }
    ];

    // useEffect(() => {
    //     setSubject(subjectId);
    // }, []);

    useEffect(() => {
        navigate(`/app/practice/${subject}`);

    }, [subject]);

    return (
        <div className='font-type-5'>
            <TopSection
                section={menuFlow}
                title={'Select Subject'}
                activeSubject={activeSubject}
                selectTitle={activeSubject?.name}
                options={getOptions(subjectLists)}
                setFilter={setSubject}
            />
            <div className='col-md-10 col-11 mx-auto my-3 my-md-5' style={{color:'var(--gray-400)'}}>
                <div className='font-type-2 fw-6'>
                    Select Topic
                </div>
                <div className='subject-topic-card-grid my-2 mb-4'>
                    {
                        topics?.map((item) =>
                            <ListCardView2 key={item.id} data={item} />
                        )
                    }
                </div>
                {
                    totalPages > 0 ?
                    <Pagination activePage={activePage} totalPages={totalPages} setActivePage={setActivePage} />
                    :
                    'Please stay tuned till we add data for you soon...'
                }
            </div>
        </div>
    )
}

export default Topics


const getOptions = (data) => {
    const options = data?.map((option) => {
        return { value: option?.id, title: option?.name }
    });

    return options;
}