import React, { useState } from 'react'
import { AiOutlineClockCircle } from 'react-icons/ai';
import { BsInfoCircle } from 'react-icons/bs';
import { IoMdHeartEmpty } from 'react-icons/io';
import { CiWarning } from 'react-icons/ci'
import { checkQuestionStatus, formatMilliseconds } from './SolutionData';

const Question = ({ data, index }) => {
    const [questionLike, setQuestionLike] = useState(false);
    const [solutionLike, setSolutionLike] = useState(false);
    const number = ['A', 'B', 'C', 'D'];
    const [show, setShow] = useState(false);
    return (
        <div className='border d-flex gap-3 flex-column shadow-sm p-lg-5 p-3 rounded-2' style={{ background: 'var(--gray-100)' }}>

            {/* Top Section Start */}
            <div className='d-flex gap-3 justify-content-between align-items-center'>
                <div className='d-flex align-items-center gap-2 font-80'>
                    <div className='font-100 px-3 rounded-5 py-1' style={{ color: 'var(--gray-700)', background: 'var(--gray-200)', fontWeight: '500' }}>
                        Question {index + 1}
                    </div>
                    <div className='' style={{ color: 'var(--green-100)' }}>
                        Marks Scored:
                        <span className='fw-bolder'>
                            {data?.marksScored ? data?.marksScored : 0}
                        </span>
                    </div>
                </div>
                <div
                    className='d-flex align-items-center gap-1 px-3 font-80 rounded-5 py-1'
                    style={{
                        background: checkQuestionStatus(data?.questionStatus),
                        color: 'var(--gray-900)'
                    }}
                >
                    <div className='d-flex align-items-center font-120'>
                        <AiOutlineClockCircle />
                    </div>
                    <div className=''>
                        Time Taken: <span className='fw-bold'>{data?.timeTaken ? formatMilliseconds(data?.timeTaken) : 0}</span>
                    </div>
                </div>
            </div>
            {/* Top Section End  */}

            {/* Tags Section Start  */}
            <div className='d-flex gap-3 flex-wrap font-80'>
                <div className='px-3 py-1 rounded-5' style={{ background: 'var(--gray-700)', color: 'var(--green-100)', border: '1px solid var(--green-100)' }}>
                    {data?.questionSubjectId?.name}
                </div>
                <div className='px-3 py-1 rounded-5' style={{ background: 'var(--gray-700)', color: 'var(--green-100)', border: '1px solid var(--green-100)' }}>
                    {data?.questionType === 'INTEGER' ? 'Integer' : 'MCQs'}
                </div>
                {
                    data?.tags?.map((tag, index) =>
                        <div className='px-3 py-1 rounded-5' style={{ background: 'var(--gray-700)' }} key={index}>{tag}</div>
                    )
                }
            </div>
            {/* Tags Section End  */}

            {/* Question Section Start  */}
            <div className='d-flex flex-column gap-3 my-3'>
                <div className='d-flex gap-2 justify-content-between'>
                    <div
                        dangerouslySetInnerHTML={{ __html: data?.question }}
                    />
                    {/* <div className='font-150' style={{ width: '3rem' }}>
                        <IoMdHeartEmpty style={{ cursor: 'pointer', background: questionLike ? 'red' : 'transparent' }} onClick={() => setQuestionLike(!questionLike)} />
                    </div> */}
                </div>
            </div>
            {/* Question Section End  */}

            {/* Answer Section Start  */}
            <div className='option-grid'>
                {
                    data?.questionType === 'SINGLE_ANSWER' ?
                        [1, 2, 3, 4].map((option, index) =>
                            <div className='border py-4 rounded-2 px-3 d-flex flex-column gap-2 justify-content-center' key={index}>
                                <div className='d-flex gap-2 align-items-start'>
                                    <div
                                        className={`border rounded-5 p-1 d-flex justify-content-center align-items-center`}
                                        style={{
                                            width: '2rem',
                                            height: '2rem',
                                            background: index + 1 == data?.previousAnswer ? checkQuestionStatus(data?.questionStatus) : 'transparent',
                                            color: index + 1 == data?.previousAnswer ? 'white' : 'var(--gray-700)'
                                        }}
                                    >
                                        {number[index]}
                                    </div>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: data ? data[`option${index + 1}`] : '' }}
                                    />
                                </div>
                                {
                                    index + 1 == data?.answer ?
                                        <div className='ps-4' style={{ color: 'var(--green-100)' }}>Correct Answer</div>
                                        :
                                        data?.previousAnswer &&
                                        index + 1 == data.previousAnswer &&
                                        <div className='ps-4' style={{ color: 'var(--red-100)' }}>Wrong Answer</div>
                                }
                            </div>
                        )
                        :
                        data?.questionType === 'INTEGER' ?
                            <div className=''>
                                <div className='d-flex align-items-start gap-2'>
                                    <div className=''>Your Answer: </div>
                                    <div style={{ fontWeight: '500' }}>{data?.previousAnswer ? data?.previousAnswer : 'No Response'}</div>
                                </div>
                                <div className='d-flex align-items-start gap-2'>
                                    <div className=''>Answer: </div>
                                    <div style={{ fontWeight: '500' }}>{data?.answer}</div>
                                </div>
                            </div>
                            :
                            [1, 2, 3, 4].map((option, index) =>
                                <div className='border py-4 rounded-2 px-3 d-flex flex-column gap-2 justify-content-center' key={index}>
                                    <div className='d-flex gap-2 align-items-start'>
                                        <div
                                            className={`border rounded-5 p-1 d-flex justify-content-center align-items-center`}
                                            style={{
                                                width: '2rem',
                                                height: '2rem',
                                                background: (data?.previousAnswer?.include(index + 1)) ? checkQuestionStatus(data?.questionStatus) : 'transparent',
                                                color: (data?.previousAnswer?.include(index + 1)) ? 'white' : 'var(--gray-700)'
                                            }}
                                        >
                                            {number[index]}
                                        </div>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: data ? data[`option${index + 1}`] : '' }}
                                        />
                                    </div>
                                    {
                                        index + 1 == data?.answer ?
                                            <div className='ps-4' style={{ color: 'var(--green-100)' }}>Correct Answer</div>
                                            :
                                            data?.previousAnswer &&
                                            index + 1 == data.previousAnswer &&
                                            <div className='ps-4' style={{ color: 'var(--red-100)' }}>Wrong Answer</div>
                                    }
                                </div>
                            )
                }
            </div>
            {/* Answer Section End  */}

            <div className='d-flex justify-content-between align-items-center gap-3 my-4'>
                <button className='px-3 py-1 rounded-1' style={{ cursor: 'pointer', border: '0', outline: 'none', background: show ? 'var(--primary)' : 'var(--green-100)', color: 'white' }} onClick={() => setShow(!show)}>
                    {
                        !show ? 'View Solution' : 'Solution'
                    }
                </button>
                <div className='d-flex align-items-center gap-1' style={{ color: 'var(--primary)' }}>
                    <div className='font-100 d-flex align-items-center'>
                        <BsInfoCircle />
                    </div>
                    <div className='font-80 d-flex gap-1 align-items-start'>
                        <span className='fw-bold'>{data?.noOfPeopleCorrectlyAttempted} {data?.noOfPeopleCorrectlyAttempted > 1 ? 'students' : 'student'} </span>
                        <span> answered correctly within {formatMilliseconds(data?.averageTimeSpan)}</span>
                    </div>
                </div>
            </div>

            {/* Solution Section Start */}
            {
                <div className={`solution-dropDown px-md-4 px-3 ${show && 'solution-open py-md-4 py-3 d-flex flex-column gap-4 rounded-3'}`} style={{ border: '1px solid var(--purple-100)', background: 'var(--white)', color:'black' }}>
                    <div className='fw-bold'>Solution</div>
                    {
                        data?.solution === null || data?.solution === '' ?
                            <div className=''>No Solution available for this question</div>
                            :
                            <div
                                dangerouslySetInnerHTML={{ __html: data?.solution }}
                            />
                    }
                    {/* <div className='d-flex gap-3 justify-content-between'>
                        <div className='px-3 py-1 rounded-5 d-flex align-items-center gap-1' style={{ fontWeight: '500', background: 'var(--purple-200)', color: 'var(--primary)' }}>
                            <div className='d-flex align-items-center'><CiWarning /></div>
                            <div>Share Suggestion</div>
                        </div>
                        <div className='font-150' style={{ width: '3rem' }}>
                            <IoMdHeartEmpty style={{ cursor: 'pointer', background: solutionLike ? 'red' : 'transparent' }} onClick={() => setSolutionLike(!solutionLike)} />
                        </div>
                    </div> */}
                </div>
            }
            {/* Solution Section End  */}

        </div>
    )
}

export default Question;

