import { configureStore } from '@reduxjs/toolkit'
import  contestReducer  from './reducers/contest'
import  userReducer  from './reducers/user'
import  appReducer  from './reducers/app'

export default configureStore({
  reducer: {
    user:userReducer,
    contest:contestReducer,
    app:appReducer
  },
})