import React from "react";
import Button from "../Buttons/Button";
import { FaGooglePlay } from "react-icons/fa";
import mobile from "../../assets/mobile.png";
import group2 from "../../assets/community_bg.png";
import user1 from "./community_assets/user1.png";
import user2 from "./community_assets/user2.png";
import user3 from "./community_assets/user3.png";
import user4 from "./community_assets/user4.png";
import Logo from "../../assets/unprep_logo.png";
import GradientBlob3 from "./GradientBlob3";
import Grid from "../../assets/grid.svg";

const DownloadAppV2 = () => {
  const downloadLink = "/";

  const makeThemMove = (e) => {
    document.querySelectorAll(".float-user-icons img").forEach((move) => {
      var moving_value = move.getAttribute("data-value");
      var x = (e.clientX * moving_value) / 150;
      var y = (e.clientY * moving_value) / 150;

      move.style.transform = `translateX(${x}px) translateY(${y}px)`;
    });
    document.querySelectorAll(".float-noti-icons img").forEach((move) => {
      var moving_value = move.getAttribute("data-value");
      var y = (e.clientY * moving_value) / 150;

      move.style.transform = `translateY(${y}px)`;
    });
  };
  return (
    <div
      className="d-flex flex-column align-items-center gap-3 pt-md-3 pt-0 position-relative"
      onMouseMove={makeThemMove}
      style={{ overflow: "hidden", background: `url(${Grid})` }}
    >
      {/* ---------------------------------------------------------------------------- */}
      {/* Gradient section  */}
      {/* ---------------------------------------------------------------------------- */}
      <div className="blob1">
        <GradientBlob3 />
      </div>
      <div className="blob2V2">
        <GradientBlob3 />
      </div>
      {/* ---------------------------------------------------------------------------- */}
      {/* Gradient section  */}
      {/* ---------------------------------------------------------------------------- */}

      {/* ---------------------------------------------------------------------------- */}
      {/* Floating section  */}
      {/* ---------------------------------------------------------------------------- */}
      {[user1, user2, user3, user4].map((item, index) => (
        <div
          className={`float-icons d-none d-md-block float-user-icons float-icon-${index + 11}`}
        >
          <img src={item} data-value={2} alt="unbind-users" />
        </div>
      ))}
      {/* ---------------------------------------------------------------------------- */}
      {/* Floating section  */}
      {/* ---------------------------------------------------------------------------- */}
      <div
        className="py-sm-5 py-4 d-flex flex-column align-items-center gap-3 cursor"
        style={{ margin: "30px" }}
      >
        <div className={`mb-3 text-center`}>
          <img
            src={Logo}
            style={{
              width: "30vw",
              minWidth: 120,
              height: "auto",
              maxWidth: 220,
            }}
            alt="unprep"
          />
        </div>
        <div
          style={{ border: "4px solid #2F2F376B" }}
          className="rounded-pill px-4 py-1 community-font-4 text-center"
        >
          Track, Practice, and Succeed with Ease!
        </div>
        <h1 className="fw-7 text-center community-font-gradient community-font-1">
          Your NEET Study Tracker
        </h1>
        <h2 className="community-font-2 text-center">
          Stay on top of your NEET preparation with a personalized tracking{" "}
          <br className="d-none d-lg-block" />
          system that adapts to your study needs.
        </h2>
        <div className="mt-2">
          <Button
            text={"Coming Soon"}
            icon={<FaGooglePlay />}
            classes={"button-type1 primary-bg"}
            link={downloadLink}
          />
        </div>
      </div>

      <div className="text-center">
        <img
          src={mobile}
          alt="unbind-app-groups"
          style={{ width: "90vw", maxWidth: "1200px", height: "auto" }}
        />
      </div>
      <div className="position-relative" style={{width: '100%', height: '30px'}}>
        <div
          className="community-black-fade"
        />
      </div>
      <div
        className="d-flex flex-column gap-3 community-footer-bg"
        style={{
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, #2823C6E5 100%), url(${group2})`,
          backgroundSize: "cover",
          width: "100%",
        }}
      >
        <div className="col-md-10 col-11 mx-auto">
          <h2 className="community-font-gradient text-center community-font-3 fw-7 mb-4">
            Now AI Wil Ensure Your Medical <br />
            Seat in NEET 2025
          </h2>
          <p className="community-fontSize-4 fw-5 mb-md-5 mb-4 text-center d-none d-md-block">
            Experience the future of NEET preparation with AI-driven insights,
            customized study plans, and progress tracking designed to maximize
            your score and guaranteed success.
          </p>
          <div className="text-center mx-auto pt-1 pt-md-0">
            <Button
              text={"Explore Now"}
              classes={"button-type2 mx-auto text-center primary-color"}
              link={downloadLink}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppV2;
