import React from 'react';
import bg from '../Profile_assets/ProfileBg.png'
import multiavatar from '@multiavatar/multiavatar';
import { HiOutlineShare } from 'react-icons/hi';
import { FiEdit, FiSave } from 'react-icons/fi';
import { getRankData } from '../ProfileData';
import ProfileRankCard from '../../Card_components/ProfileRankCard';
import { FaUserAlt } from 'react-icons/fa';
import { useState } from 'react';
import VirtualCardPopUp from '../../Card_components/VirtualCardPopUp';

const ProfileOverview = ({ data, editProfile, handleProfile, userProfile, handleChange, viewEdit }) => {
    const [openVirtual, setOpenVirtual] = useState(false);

    return (
        <div className='d-flex flex-column gap-1rem font-100'>
            {/* ------------------------------------------------------------ */}
            {/* Name and Rank Section Start */}
            {/* ------------------------------------------------------------ */}
            <div className='rounded p-3' style={{ background: 'white' }}>
                {/* ------------------------------------------------------------ */}
                {/* Name Section Start */}
                {/* ------------------------------------------------------------ */}
                <div className='d-flex flex-column justify-content-center'>
                    <div className='rounded-4 position-relative' style={{ width: '100%', background: `url(${bg})`, minHeight: '100px', height: '15vw', backgroundPosition: 'center', backgroundSize: 'cover' }}>
                        <div className='rounded-4 p-3 shadow d-flex gap-3 justify-content-between position-absolute top-100 start-50 translate-middle' style={{ background: 'var(--white)', width: '95%' }}>
                            <div className='d-flex gap-3 align-items-center'>
                                <div aria-label={data?.name} className='profile-img' dangerouslySetInnerHTML={{ __html: multiavatar(data?.name) }}>
                                </div>
                                <div className='d-flex flex-column justify-content-start gap-1'>
                                    <div className='fw-6 font-120'>
                                        {data?.name}
                                    </div>
                                    <div className=''>
                                        @{data?.userId}
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-end justify-content-end gap-2'>
                                <div
                                    className='d-flex justify-content-center align-items-center p-2 rounded-1 profile-edit-btn font-120'
                                    style={{ background: 'var(--green-200)', color: 'var(--white)' }}
                                // onClick={() => setOpenVirtual(true)} 
                                >
                                    <HiOutlineShare />
                                </div>
                                {
                                    viewEdit &&
                                    <div className='rounded-1 p-2 py-1 d-lg-flex d-none justify-content-center align-items-center cursor profile-btn profile-edit-btn' onClick={handleProfile}>
                                        {editProfile ? 'Save Profile' : `Edit Profile`}
                                    </div>
                                }
                                {
                                    viewEdit &&
                                    <div aria-label='save-button' className='d-flex d-lg-none justify-content-center align-items-center p-2 rounded-1 profile-edit-btn font-120' style={{ background: 'var(--primary)', color: 'var(--white)' }} onClick={handleProfile}>
                                        {editProfile ? <FiSave /> : <FiEdit />}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* ------------------------------------------------------------ */}
                {/* Name Section End */}
                {/* ------------------------------------------------------------ */}

                {/* ------------------------------------------------------------ */}
                {/* Rank Section Start */}
                {/* ------------------------------------------------------------ */}
                <div className='d-flex flex-column gap-2' style={{ paddingTop: '100px' }}>
                    <div className='profile-ranks'>
                        {
                            (editProfile && viewEdit) ?
                                <div className='d-flex flex-column gap-2' style={{ color: 'var(--gray-700)' }}>
                                    <div className='d-flex gap-2 align-items-center'>
                                        <FaUserAlt />
                                        <div className=''>
                                            New Username
                                        </div>
                                    </div>
                                    <input type='text' name='username' value={userProfile?.username} onChange={handleChange} />
                                    {
                                        userProfile?.username?.length > 20 &&
                                        <div className='font-80' style={{ color: 'var(--red-100)' }}>*Can't be greater than 20 characters</div>
                                    }
                                </div>
                                :
                                getRankData(data).map((d, index) =>
                                    <ProfileRankCard data={d} key={index} viewEdit={viewEdit} />
                                )
                        }
                    </div>
                </div>
                {/* ------------------------------------------------------------ */}
                {/* Rank Section End */}
                {/* ------------------------------------------------------------ */}
            </div>
            {/* ------------------------------------------------------------ */}
            {/* Name and Rank Section End */}
            {/* ------------------------------------------------------------ */}

            {
                openVirtual &&
                <VirtualCardPopUp />
            }
        </div>
    )
}

export default ProfileOverview
