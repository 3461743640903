import React from 'react';
import tick from '.././home_assets/tick.svg';
import community from '.././home_assets/community.png';
import './JoinCommunitySection.css';
import { Link } from 'react-router-dom';
import { downloadLink } from '../../../utils/usableData';

const JoinCommunitySection = () => {
    return (
        <div className='mt-5 position-relative overflow-hidden' style={{ backgroundColor: 'var(--primary-dark)', paddingTop: '8rem', paddingBottom: '18rem' }}>
            <div className='position-relative d-flex flex-column col-md-10 gap-5 col-11 mx-auto' style={{ color: 'white' }}>
                <h2 className='font4 fw-bold position-relative'>
                    Join our Community on <br />App
                    <div className='fw-bold font6 position-absolute' style={{ zIndex: '2', color: 'rgba(255,255,255,0.1)', top: '-2rem', left: '-1rem', letterSpacing: '0.2rem' }}>Community</div>
                </h2>
                <div className='d-flex flex-column gap-4 font2' style={{ zIndex: '3' }}>
                    <div className='d-flex align-items-center gap-3'>
                        <img src={tick} alt='tick-icon' />
                        <h3 className='m-0'>Vital social connection & engagement</h3>
                    </div>
                    <div className='d-flex align-items-center gap-3'>
                        <img src={tick} alt='tick-icon' />
                        <h3 className='m-0'>Never being alone</h3>
                    </div>
                    <div className='d-flex align-items-center gap-3'>
                        <img src={tick} alt='tick-icon' />
                        <h3 className='m-0'>Assuring safety and well-being</h3>
                    </div>
                </div>
                <button aria-label='unbind-community-button' className='' type='button' style={{ zIndex: '3', border: 0, outline: 'none', display: 'flex', backgroundColor: "transparent" }}>
                    <Link to={downloadLink} className='rounded-2 font1half fw-bold px-3 py-2 linkFoot' style={{ color: 'var(--primary-dark)', backgroundColor: 'white', outline: 'none', border: '0' }}>Join Our Community</Link>
                </button>
            </div>
            <div className='position-absolute end-0 bottom10'>
                <img src={community} className='width600' alt='community' />
            </div>
        </div>
    )
}

export default JoinCommunitySection
