import { createSlice } from '@reduxjs/toolkit'

export const appReducer = createSlice({
    name: 'app',
    initialState: {
        loading: false,
        timeZone:"Asia/Kolkata"
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setLoading } = appReducer.actions

export default appReducer.reducer