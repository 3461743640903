export const cardData = [
  {
    type: 'studentTotalMarks',
    title: 'Total Marks Scored',
    type2: 'totalMarks',
    content: 'Marks',
    color: '#23B082'
  },
  {
    type: 'totalTimeTaken',
    title: 'Time Taken',
    type2: null,
    content: null,
    color: '#FFC803'
  },
  {
    type: 'rank',
    title: 'Rank',
    type2: null,
    content: null,
    color: '#4873F2'
  },
  {
    type: 'positiveMarks',
    title: 'Total Positive Marks',
    type2: null,
    content: 'Marks',
    color: '#23B082'
  },
  {
    type: 'negativeMarks',
    title: 'Total Negative Marks',
    type2: null,
    content: 'Marks',
    color: '#E36963'
  },
  {
    type: 'unAttemptedMarks',
    title: 'Total Unattempted Marks',
    type2: null,
    content: 'Marks',
    color: '#4873F2'
  }
];

export function convertMillisecondsToHMS(milliseconds) {
  var seconds = Math.floor(milliseconds / 1000);
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds - (hours * 3600)) / 60);
  var remainingSeconds = seconds - (hours * 3600) - (minutes * 60);

  var result = '';

  if (hours > 0) {
    result += hours + ' hr ';
  }

  if (minutes > 0) {
    result += minutes + ' min ';
  }

  if (remainingSeconds > 0) {
    result += remainingSeconds + ' sec';
  }

  return result.trim();
};

export const getSubjectAvgMarks = (sub, data) => {
  const avgSubData = data?.filter((s) => s?.subjectId === sub?.questionSubjectId);
  return avgSubData[0];
}

const data = [
  { subject: 'Math', student1: 90, student2: 85 },
  { subject: 'Science', student1: 80, student2: 95 },
  { subject: 'English', student1: 75, student2: 80 },
  { subject: 'History', student1: 85, student2: 90 },
];
export const getChartData = (result, avg) => {
  console.log(result, avg)
  const data = result?.studentSubjectContestResults?.map((sub1) => {
    const avgTime = (avg?.filter((avgSub) => avgSub?.subjectId === sub1?.questionSubjectId))[0]?.avgTimeTaken;
    return { subject: sub1?.questionSubjectName, Student: sub1?.totalTimeTakenInSubject, Average: avgTime }
  })
  return data;
}

export const getChartData2 = (result, avg) => {
  console.log(result, avg)
  let labels = [];
  let avgTime = [];
  let yourTime = [];
  const data = result?.studentSubjectContestResults?.map((sub1) => {
    labels.push(sub1?.questionSubjectName);
    yourTime.push(((sub1?.totalTimeTakenInSubject)/60000)?.toFixed(2));
    const avgTime1 = (avg?.filter((avgSub) => avgSub?.subjectId === sub1?.questionSubjectId))[0]?.avgTimeTaken;
    avgTime.push((avgTime1/60000)?.toFixed(2));
    return { subject: sub1?.questionSubjectName, Student: sub1?.totalTimeTakenInSubject, Average: avgTime1 }
  })
  return { labels, yourTime, avgTime };
}