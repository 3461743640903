import React from 'react'

const VirtualCardPopUp = () => {
    return (
        <div
            className='d-flex justify-content-center align-items-center position-fixed top-0 start-0'
            style={{ width: '100vw', height: '100vh', background: 'rgba(255,255,255,0.3)', zIndex: '3', backdropFilter: 'blur(4px)' }} >
            <div className='d-flex flex-column align-items-center gap-4 rounded-4 shadow p-5' style={{ background: 'white', maxWidth: '90%', width: 'fit-content' }}>
                <div className=''>
                    {/* virtual test icon  */}
                </div>
                <div className='fw-6 fs-4'>
                    Would you like to start a Virtual Contest?
                </div>
                <div className=''>
                    Virtual Contests are a way to take part in previous contests. The experience is as close as it gets to real participation, but your rank is not affected.
                </div>
                <div className='rounded-2 px-3 py-2 fw-5 cursor shadow' style={{ background: 'var(--green-200)', color: 'white' }}>
                    Start Virtual contest
                </div>
            </div>
        </div>
    )
}

export default VirtualCardPopUp
