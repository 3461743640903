import { toast } from "react-toastify";

export const TabOptions=[
    {
        title:'Upcoming Talk Show',
        value:'upcoming'
    },
    {
        title:'Past Talk Show',
        value:'past'
    }
]

export const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link)
        .then(() => {
            toast.success('Link Copied!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        })
        .catch((err) => {
            toast.error('Error copying link!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        });
}