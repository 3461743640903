import React from 'react'
import JSONPretty from "react-json-prettify";

const JsonAsset = () => {
    const data = [{
        "relation": ["delegate_permission/common.handle_all_urls"],
        "target": {
            "namespace": "android_app",
            "package_name": "com.unbind",
            "sha256_cert_fingerprints":
                ["DB:BE:00:68:3C:1D:F3:C9:EB:AD:45:5F:E7:6F:5B:38:E1:A2:FA:1F:8A:2F:5B:F4:7A:E2:D5:58:41:99:8B:F2"]
        }
    }];
    return (
        <div style={{ background: '#272822', minHeight: '100vh' }}>
            <JSONPretty id="json-pretty" json={data}></JSONPretty>
        </div>
    )
}

export default JsonAsset
