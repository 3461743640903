import React from 'react';
import search from '../Home/home_assets/search.svg';

const Search = ({ searchData, setSearchData }) => {
    return (
        <div className='font1half d-flex gap-4 p-1 rounded-4 align-items-center font-100' style={{ backgroundColor: 'var(--gray-100)', width: 'fit-content' }}>
            <input type='text' value={searchData} placeholder='Search a name' className='p-2 ps-3' style={{ background: 'transparent', border: '0', outline: 'none' }} onChange={(e)=>setSearchData(e.target.value)} />
            <div className='d-flex justify-content-center align-items-center p-2 me-2 rounded-2' style={{ backgroundColor: 'white' }}>
                <img src={search} style={{ width: '20px' }} alt='' />
            </div>
        </div>
    )
}

export default Search
