import React from 'react';
import { intro } from './Data';

const Left = ({ type }) => {

    return (
        <div className='col-md-4 col-12 d-flex flex-column gap-3 p-5 shadow ' style={{ height: 'fit-content', background:'var(--gray-900)' }}>
            <div className='fw-medium font1half'>{type === 'terms' ? 'Terms & Conditions' : 'Privacy Policy'}</div>
            <div
                className='d-flex flex-column gap-2 font1'
                style={{ color: 'var(--gray-400)' }}
                dangerouslySetInnerHTML={{ __html: intro[type] }}
            />
        </div>
    )
}

export default Left
