import React from 'react';
import talk from '../Profile/Profile_assets/talkImg.png';
import { BsCalendar4Week } from 'react-icons/bs';
import { BiCalendar } from 'react-icons/bi';
import { MdShare } from 'react-icons/md';
import multiavatar from '@multiavatar/multiavatar';
import { Link, useNavigate } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import TalkDetailCard from './TalkDetailCard';
import { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../config';
import { header } from '../HeaderData';
import { useSelector } from 'react-redux';
import { alertMsg } from '../Alert/AlertData';

const TalkCard = ({ data, isRegistered, getUserTalks }) => {
    const [detailView, setDetailView] = useState(false);
    const { jwtToken } = useSelector((state) => state.user);

    const navigate = useNavigate();

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    const formattedDateTime = new Date(data?.start_time).toLocaleString('en-US', options);

    const handleDetail = () => {
        setDetailView(true);
    }

    const handleButton = async () => {
        if (!isRegistered) {
            const res = await axios.post(`${API_URL}/registerForTalk?talkId=${data?.id}`, {}, header(jwtToken));
            console.log('register for talk data================================================>', res)
            alertMsg('success', 'Successfully registered');
            getUserTalks();
        }
        if (new Date(data?.start_time) <= new Date() && new (data?.start_time + data?.duration * 60000) >= new Date()) {
            navigate(data?.joinLink)
        }
    }

    return (
        <div className='position-relative' style={{ width: '100%' }}>
            <img src={data?.imageUrl ? data?.imageUrl : talk} alt='' className='talk-container-img cursor' onClick={handleDetail} />
            <div className='p-md-3 p-3 d-flex flex-column gap-md-2 gap-1' style={{ color: 'white', background: `linear-gradient(106.07deg, #FFFFF2 -128.73%, #5764ED -128.71%, #000000 100%)`, width: '100%' }}>
                <div className='font-150 fw-6 cursor' onClick={handleDetail}>
                    {data?.name?.length > 25 ? data?.name?.slice(0, 22) + '...' : data?.name}
                </div>
                <div className='d-flex gap-2 align-items-center'>
                    <div className='font-150'>
                        <BiCalendar />
                    </div>
                    <div className=''>
                        {formattedDateTime}
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center gap-2 mt-2 mb-1'>
                    <div className='d-flex align-items-center gap-2'>
                        <div
                            onClick={handleButton}
                            className='rounded-1 fw-5 px-2 py-1 py-md-2 px-md-3'
                            style={{ background: checkBtnStatus(data?.start_time, data?.duration, isRegistered) ? 'var(--primary)' : 'var(--gray-500)', color: 'var(--white)', cursor: checkBtnStatus(data?.start_time, data?.duration, isRegistered) ? 'pointer' : 'default' }}
                        >
                            {
                                !isRegistered ?
                                    'Register'
                                    :
                                    (new Date(data?.start_time) <= new Date() && new (data?.start_time + data?.duration * 60000) >= new Date()) ?
                                        'Join Talk'
                                        :
                                        'Registered'
                            }
                        </div>
                        <div className='d-flex font-80'>
                            {
                                data?.noOfStudentsRegistered > 0 &&
                                <div className='position-relative' style={{ width: '20px', left: '8px' }} dangerouslySetInnerHTML={{ __html: multiavatar(data?.name[0]) }}>
                                </div>
                            }
                            {
                                data?.noOfStudentsRegistered > 1 &&
                                <div className='position-relative' style={{ width: '20px' }} dangerouslySetInnerHTML={{ __html: multiavatar(data?.name[1]) }}>
                                </div>
                            }
                            {
                                data?.noOfStudentsRegistered > 2 &&
                                <div className='position-relative' style={{ width: '20px', right: '8px' }} dangerouslySetInnerHTML={{ __html: multiavatar(data?.name[2]) }}>
                                </div>
                            }
                            <div className='position-relative'>
                                Register {data?.noOfStudentsRegistered}
                            </div>
                        </div>
                    </div>
                    <div className='font-120'>
                        <MdShare />
                    </div>
                </div>
            </div>
            <Offcanvas placement='end' className={`offcanvas pt-2`} style={{ maxWidth: '100vw' }} show={detailView} onHide={() => { setDetailView(false) }}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <TalkDetailCard data={{ ...data, isRegistered, formattedDateTime }} />
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default TalkCard;

const checkBtnStatus = (start, duration, registered) => {
    if (registered) {
        if (new Date(start) <= new Date() && new (start + duration * 60000) >= new Date()) {
            return true;
        }
        return false;
    }
    return true;
}
