import { createSlice } from '@reduxjs/toolkit'

export const contestReducer = createSlice({
    name: 'contest',
    initialState: {
        categories: [],
    },
    reducers: {
        setCategories: (state, action) => {
            state.categories = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setCategories } = contestReducer.actions

export default contestReducer.reducer