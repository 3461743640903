import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import TestContext from '../context/testContext';
import closeIcon from '../assets/close.svg'

export default function QuestionSidebar() {
    const context = useContext(TestContext)

    const handleClose = () => context.setShowOffCanvasSideBar(false);
    const handleShow = () => context.setShowOffCanvasSideBar(true);

    return (
        <>
            <Offcanvas placement='end' show={context.showOffCanvasSideBar} onHide={handleClose}>
                {/* <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Questions</Offcanvas.Title>
                </Offcanvas.Header> */}
                <Offcanvas.Body>
                    <div id='mobile-sidebar-outer-cnt' className='my-3 bg-white'>
                        <div className="d-flex align-items-center justify-content-between">
                        <div className="sidebar-title fw-m">
                            Questions
                        </div>
                        <button onClick={()=>context.setShowOffCanvasSideBar(false)} id='mobile-sidebar-close-btn' className='bg-white'>
                            <img src={closeIcon} alt="" />
                        </button>
                        </div>
                        <div className="text-secondary fw-l fs-xsm mt-3" style={{ marginBottom: "45px" }}>
                            Attemp all
                        </div>
                        {context.questionIds && Object.keys(context.questionIds).map((category, index) => {
                            return <div
                                key={index} className="category-cnt">
                                <div className="category-title fs-xsm fw-sb">
                                    {category}
                                </div>
                                <div className="questions-cnt">
                                    {context.questionIds[category].map((question, questionIndex) => {
                                        return <button
                                            disabled={context.fetchingQuestionData}
                                            key={questionIndex}
                                            onClick={() => {
                                                context.updateTimeTaken();
                                                if (context.currentQuestion) {
                                                    context.sendResponse(context.cache.current["submittedResponse"][context.currentQuestion.id]);
                                                }
                                                context.setCurrentQuestionId(question);
                                                context.setCurrentQuestionNumber(questionIndex + 1);
                                                context.setShowOffCanvasSideBar(false);
                                            }}
                                            className={'btn question-btn' + ((context.currentQuestionNumber === (questionIndex + 1) && context.currentQuestionId && context.currentQuestionId.id === question.id) ? " border-color-3" : "")}
                                        >{questionIndex + 1}
                                            {(context.cache.current["submittedResponse"][question.id] && context.cache.current["submittedResponse"][question.id] !== '') &&
                                                <div className="answered-badge">
                                                </div>}
                                        </button>
                                    })}
                                </div>

                            </div>
                        })}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
