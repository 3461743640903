export const termsQueries = {
  terms: [
    {
      question: 'A. Use of Our Platform',
      answer: `<p>Our platform is intended for educational purposes only. You may use our platform only for lawful purposes and in accordance with these Terms. You agree not to use our platform:</p><ul><li>In any way that violates any applicable central, state, local, or international law or regulation</li><li>To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail,” “chain letter,” “spam,” or any other similar solicitation</li><li>To impersonate or attempt to impersonate us, our employees, another user, or any other person or entity</li><li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of our platform or interferes with the proper functioning of our platform</li></ul>`
    },
    {
      question: 'B. User Content',
      answer: `<p>Our platform may allow users to post, upload, publish, submit, or transmit content (“User Content”). You are solely responsible for your User Content and the consequences of posting, publishing, or submitting it. By posting, publishing, or submitting User Content, you represent and warrant that:</p><ul><li>You have the right to post, publish, or submit the User Content</li><li>The User Content is accurate and not misleading</li><li>The User Content does not violate any applicable laws or regulations or infringe upon any third-party intellectual property rights</li></ul><p>We reserve the right to remove any User Content that violates these Terms or is otherwise inappropriate or harmful.</p>`
    },
    {
      question: 'C. Intellectual Property Rights',
      answer: '<p>Our platform and all of its contents, features, and functionality, including, but not limited to, text, graphics, logos, images, and software, are owned by us or our licensors and are protected by India and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws</p><p>You may not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our platform, except as follows:</p><ul><li>Your device may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials</li><li>You may store files that are automatically cached by your Web browser for display enhancement purposes</li><li>You may print or download one copy of a reasonable number of pages of our platform for your own personal, non-commercial use and not for further reproduction, publication, or distribution</li></ul>'
    },
    {
      question: 'D. Links to Other Websites',
      answer: `<p>Our platform may contain links to third-party websites or services that are not owned or controlled by us. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services.</p><p>You acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods, or services available on or through any such third-party websites or services.</p>`
    },
    {
      question: 'E. Limitation of Liability',
      answer: `<p>In no event shall we, our affiliates, or our licensors be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of our platform or services, whether based on contract, tort, strict liability, or any other legal theory, even if we have been advised of the possibility of such damages.</p>`
    },
    {
      question: `F. Indemnification`,
      answer: `<p>You agree to defend, indemnify, and hold harmless us, our affiliates, and our licensors from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms</p>`
    }
  ],
  privacy: [
    {
      question: `A. Personal Information We Collect`,
      answer: `<p>We collect personal information from our users when they register on our platform, including their name, email address, and other contact information. We also collect information about their educational background, interests, and other preferences. This information helps us tailor our platform to their needs and provide better educational resources.</p><p>We may also collect usage data such as IP address, browser type, and device information to help us improve the performance and security of our platform.</p>`
    },
    {
      question: `B. How We Use Personal Information`,
      answer: `<p>We use personal information to provide our users with educational resources, connect them with other users, and improve their learning experience. We may also use personal information to communicate with our users about platform updates, new features, and other relevant information.</p><p>We may also use personal information for research and analysis purposes to improve our platform and educational resources.</p>`
    },
    {
      question: `C. How We Protect Personal Information`,
      answer: `<p>We take appropriate security measures to protect personal information from unauthorized access, use, disclosure, or alteration. We use encryption technologies and access controls to ensure the confidentiality and integrity of personal information.</p><p>We also limit access to personal information to only those employees, contractors, and service providers who need access to it to provide our services.</p>`
    },
    {
      question: `D. Disclosure of Personal Information`,
      answer: `<p>We do not sell or rent personal information to third parties. However, we may disclose personal information to our partners, service providers, and contractors who assist us in providing our services.</p><p>We may also disclose personal information if required by law or if we believe in good faith that disclosure is necessary to protect our rights, property, or safety or that of our users.</p>`
    },
    {
      question: `E. User Rights`,
      answer: `<p>Our users have the right to access, update, or delete their personal information at any time. Users may also opt-out of receiving promotional emails from us.</p>`
    },
    {
      question: `F. Contact Us`,
      answer: `<p>If you have any questions or concerns about our Privacy Policy or how we handle personal information, please contact us at [hello@unbind.co.in].</p>`
    },
    {
      question: `G. Changes to Privacy Policy`,
      answer: `<p>We may update this Privacy Policy from time to time. Any changes will be posted on our platform and will take effect immediately.</p>`
    }
  ]
}

export const intro = {
  terms: `<p>Welcome to Unbind! We hope that you have a great experience using our Platform.</p><p>These terms and conditions (“Terms  and Conditions”) govern your use of our platform and services. By accessing or using our platform, you agree to be bound by these Terms.</p>`,
  privacy: `<p>This Privacy Policy outlines how our community-based platform collects, uses, and protects the personal information of our users. The privacy of our users is important to us, and we take the responsibility of safeguarding their personal information seriously.</p>`
}