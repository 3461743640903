import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { API_URL } from '../config'
import Navbar from '../components/Navbar'
import Carousel from '../components/Carousel'
import { setCategories } from '../reducers/contest'
import { setJwtToken, setUser } from '../reducers/user'
import Contest from '../components/Contest'
import ComingSoon from '../components/ComingSoon'
import { useCookies } from 'react-cookie'

export default function Mentorship() {
  const dispatch = useDispatch()
  const [showLoginCanvas, setShowLoginCanvas] = useState(false)
  const [active, setActive] = useState(3);
  
  useEffect(() => {
    getCategories();
  }, [])

  const getCategories = async () => {
    try {
      const res = await axios.get(API_URL + "/contestCategory");
      console.log("API URL===========", res.data);
      dispatch(setCategories(res.data))
    } catch (e) {
      console.log(e.message);
    }
  }


  return (
    <div>
      <Navbar showCategories={true} showButtons={true} active={active} setActive={setActive} showLoginCanvas={showLoginCanvas} setShowLoginCanvas={setShowLoginCanvas} />
      {active === 2 &&
        <>
          <Contest showLoginCanvas={showLoginCanvas} setShowLoginCanvas={setShowLoginCanvas} type="ongoing" />
          <Contest type="past" />
        </>}
      {active !== 2 &&
        <>
          <ComingSoon />
        </>}
    </div>
  )
}
