import React, { useEffect, useState } from 'react';
import './Talk.css'
import axios from 'axios';
import { API_URL } from '../../../config';
import TalkCard from '../../Card_components/TalkCard';
import { TabOptions } from './TalkData';
import Loading from '../../Loading/Loading';
import { header } from '../../HeaderData';
import { useSelector } from 'react-redux';

const Talk = () => {
    const [talksData, setTalksData] = useState(null);
    const [pastTalks, setPastTalks] = useState(null);
    const [activePage, setActivePage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const { jwtToken } = useSelector((state) => state.user);

    const getUserTalks = async () => {
        setLoading(true);
        const res = await axios.get(`${API_URL}/talks/user`, header(jwtToken))
        console.log('user talks data=========================================================>', res);
        setTalksData(res?.data);
        // setTotalPages(res?.data?.totalPages);
        setLoading(false);
    }

    const getPastTalks = async () => {
        const res = await axios.get(`${API_URL}/talks?isEnded=true&isVisible=true`);
        console.log('past talks data================================================================>', res);
        setPastTalks(res?.data?.content);
    }

    useEffect(() => {
        if (jwtToken)
            getUserTalks();
        getPastTalks();

        return () => { }
    }, [jwtToken])
    return (
        <div className='rounded-3 px-3 py-4 d-flex gap-5 flex-column align-items-center font-100 mt-2' style={{ background: 'var(--white)', flexGrow: '1' }}>
            {/* ---------------------------------------------------------- */}
            {/* tab section start */}
            {/* ---------------------------------------------------------- */}
            <div className='d-flex justify-content-center align-items-center'>
                {
                    TabOptions.map((item, index) =>
                        <div className='d-flex justify-content-center align-items-center font-100 px-2 py-1 cursor talk-tab-container' onClick={() => setActiveTab(index)} key={index} style={{ color: 'var(--white)', background: activeTab === index ? 'var(--primary)' : 'var(--gray-400)' }}>
                            {item?.title}
                        </div>
                    )
                }
            </div>
            {/* ---------------------------------------------------------- */}
            {/* tab section end */}
            {/* ---------------------------------------------------------- */}
            <div className='talk-sessions-container'>
                {
                    loading ?
                        [1, 2, 3].map((d) =>
                            <Loading key={d} classes={'col-12'} height={'12rem'} />
                        )
                        :
                        activeTab === 0 ?
                            talksData?.length === 0 ?
                                <div className=''>No talks are available. Stay tuned for further updates</div>
                                :
                                talksData?.map((data, index) =>
                                    <TalkCard data={data?.talk} getUserTalks={getUserTalks} isRegistered={data?.isRegistered} key={index} />
                                )
                            :
                            pastTalks?.length === 0 ?
                                <div className=''>No any past talks are available</div>
                                :
                                pastTalks?.map((data, index) =>
                                    <TalkCard data={data} isRegistered={data?.isRegistered} key={index} />
                                )
                }
            </div>
        </div>
    )
}

export default Talk
