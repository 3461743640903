import status1 from './contestLists_assets/ac.png';
import status2 from './contestLists_assets/pr.png';
import status3 from './contestLists_assets/wr.png';
import accepted from './contestLists_assets/accepted.png';
import rejected from './contestLists_assets/rejected.png';

export const tableStructure = [
    { title: 'Status', type: 'status' },
    { title: 'Title', type: 'title' },
    { title: 'Solution', type: 'solution' },
    { title: 'Acceptance', type: 'acceptance' },
    { title: 'Difficulty', type: 'difficulty' }
];

export const getUpdatedTable = (table, page, pageSize) => {
    const updatedTable = table?.map((data, index) => {
        let status = 'NA';
        let solution = rejected;
        let color = 'var(--green-200)';
        let difficulty = 'Easy';
        let total = data?.noOfPeopleCorrectlyAttempted
            + data?.noOfPeopleIncorrectlyAttempted + data?.noOfPeopleUnAttempted;
        let acceptance = total === 0 ? 0 : (data?.noOfPeopleCorrectlyAttempted * 100 / total)
        if (data?.questionStatus === 'WR')
            status = status3;
        else if (data?.questionStatus === 'PR')
            status = status2;
        else if (data?.questionStatus === 'AC')
            status = status1;

        if (data?.solution?.length > 0)
            solution = accepted;

        if (data?.rating > 2700) {
            difficulty = 'Hard';
            color = 'var(--red-200)';
        } else if (data?.rating > 2000) {
            difficulty = 'Medium';
            color = 'var(--yellow-200)';
        }
        return { status, solution, color, number: pageSize * page + index + 1, title: `${pageSize * page + index + 1}.` + data?.question, difficulty, acceptance: `${acceptance?.toFixed(2)}%`, id: data?.id };
    })
    return updatedTable;
}

export const minMaxDropdowns = [
    {
        title: 'Min Rating',
        options: [
            { title: '0', value: 0 },
            { title: '600', value: 600 },
            { title: '1200', value: 1200 },
            { title: '2000', value: 2000 },
            { title: '2400', value: 2400 },
            { title: '3000', value: 3000 }
        ]
    },
    {
        title: 'Max Rating',
        options: [
            { title: '0', value: 0 },
            { title: '600', value: 600 },
            { title: '1200', value: 1200 },
            { title: '2000', value: 2000 },
            { title: '2400', value: 2400 },
            { title: '3000', value: 3000 }
        ]
    }
];

export const handleMinRating = (maxRating, rating) => {
    const updatedRating = {
        ...rating,
        options: minMaxDropdowns[0].options.filter((option) => option.value < maxRating)
    }
    return updatedRating;
}

export const handleMaxRating = (minRating, rating) => {
    const updatedRating = {
        ...rating,
        options: minMaxDropdowns[1].options.filter((option) => option.value > minRating)
    }
    return updatedRating;
}

export const getSubjectDropDown = (data) => {
    const title = 'Subject';
    const options = data ? data.map((d) => {
        return { title: d?.name, value: d?.id }
    }) : [];
    return { title, options: [{ title: 'Subject', value: '' }, ...options] }
}

export const dropdowns = [
    {
        title: 'Subject',
        options: [
            { title: 'Subject1', value: "sub1" },
            { title: 'Subject2', value: "sub2" }
        ]
    },
    {
        title: 'Types',
        options: [
            { title: 'Types', value: '' },
            { title: 'Integer', value: "INTEGER" },
            { title: 'Single Correct', value: "SINGLE_ANSWER" },
            { title: 'Multiple Correct', value: "MULTIPLE_ANSWER" }
        ]
    },
    {
        title: 'Status',
        options: [
            { title: 'Status', value: '' },
            { title: 'Answered', value: "AC" },
            { title: 'Wrong Answer', value: "WR" },
            { title: 'Partially Correct', value: 'PR' },
            { title: 'Not Attempted', value: 'NA' }
        ]
    }
];

export const filterQuery = (minRating, maxRating, subject, type, status) => {
    let queryString = '';
    if (maxRating !== '')
        queryString += `&maxRating=${maxRating}`;
    if (minRating !== '')
        queryString += `&minRating=${minRating}`;
    if (type !== '')
        queryString += `&questionType=${type}`;
    if (status !== '')
        queryString += `&questionStatus=${status}`;
    return queryString;
}

export const filterOptions = [
    {
        title: 'Single Correct',
        active: false,
        type: 1,
        value:'SINGLE_ANSWER',
    },
    {
        title: 'Multiple Correct',
        active: false,
        type: 1,
        value:'MULTIPLE_ANSWER',
    },
    {
        title: 'Integer',
        active: false,
        type: 1,
        value:'INTEGER',
    },
    {
        title: 'Answered',
        active: false,
        type: 2,
        value:'AC',
    },
    {
        title: 'Not Attempted',
        active: false,
        type: 2,
        value:'NA',
    },
    {
        title: 'Partially Answered',
        active: false,
        type: 2,
        value:'PR'
    },
    {
        title: 'Wrong Answered',
        active: false,
        type: 2,
        value:'WR'
    }
];