import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import dividerIcon from '../assets/divider.svg'
import contestImg from '../assets/contest-img.svg'
import '../styles/contest.css'
import { API_URL } from '../config'
import axios from 'axios'
import Loader from './Loader'
import { setLoading } from '../reducers/app'
import moment from 'moment-timezone'

export default function Contest({ type, showLoginCanvas, setShowLoginCanvas }) {
    const dispatch = useDispatch();
    const selectedCategory = useSelector((state) => state.user.defaultCategory)
    const user = useSelector((state) => state.user.user)
    const { loading } = useSelector((state) => state.app)
    const jwtToken = useSelector((state) => state.user.jwtToken)
    const [loadingRegistrationStatus, setLoadingRegistrationStatus] = useState(true);
    const [contests, setContests] = useState([])
    const [contestToShow, setContestToShow] = useState([])
    const [registeredContest, setRegisteredContest] = useState([])
    const navigate = useNavigate();


    const fetchContests = async () => {
        try {
            let res;
            if (type === "ongoing") {
                res = await axios.get(API_URL + `/contest?chosen_cat=${selectedCategory ? selectedCategory.id : "641ace088b92b3650dc8e5c0"}&page=0&results_declared=false&size=100`);
                // dispatch(setLoading(false))
            }
            else if (type === "past") {
                res = await axios.get(API_URL + `/contest?chosen_cat=${selectedCategory ? selectedCategory.id : "641ace088b92b3650dc8e5c0"}&page=0&results_declared=true&size=15`);
                dispatch(setLoading(false))
            }
            res.data.content.forEach(contest => {
                console.log(contest.name, "  ", contest.start_time)
            })
            setContests(res.data.content)
        } catch (e) {
            dispatch(setLoading(false))
            console.log(e.message);
        }
    }

    const fetchRegisteredContests = async () => {
        try {
            setLoadingRegistrationStatus(true);
            console.log("fetching registered contest====================");
            const res = await axios.get(API_URL + `/registerForContest`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + jwtToken
                }
            });

            setRegisteredContest(res.data)
            console.log("registered contest===========", res.data)
            setLoadingRegistrationStatus(false);
        } catch (e) {
            console.log(e.message);
            setLoadingRegistrationStatus(false);
        }
    }

    const registerUser = async (id) => {
        try {
            if (!jwtToken) {
                setShowLoginCanvas(true);
            }
            else {
                console.log("registering for contest====================", id);
                const res = await axios.post(API_URL + `/registerForContest?isVirtual=false`, { id }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwtToken}`
                    }
                });
                console.log("register user response============", res)
                fetchRegisteredContests();
            }
        } catch (e) {
            console.log(e.message);
        }
    }


    useEffect(() => {
        fetchContests();
    }, [selectedCategory])

    useEffect(() => {
        if (contests && registeredContest) {
            let temp = [...contests];
            temp.forEach(contest => {
                registeredContest.forEach(regContest => {
                    if (contest.id === regContest.contest.id) {
                        contest.registerStatus = regContest.registerStatus;
                    }
                })
            })
            // setContests(temp);
            setContestToShow(temp)
        }
    }, [registeredContest, contests])

    useEffect(() => {
        if (contests) setContestToShow(contests);
    }, [contests])

    useEffect(() => {
        if (type = "ongoing" && jwtToken) {
            fetchRegisteredContests();
        }
        fetchContests();
    }, [jwtToken])


    return (
        <div className='my-4 px-2 container-1' >
            <div className="contest-type-title fw-sb">{type === "ongoing" ? "Ongoing/ Upcoming Contests" : "Past Contests"}</div>
            <div className={"contests-outer-cnt " + (loading ? "bg-gray-1 rounded-1 py-5" : "")}>

                {((loading || (type === "ongoing" && jwtToken && loadingRegistrationStatus))) ?
                    <div className='w-100 bg-gray-1 rounded-1 text-center py-5'>
                    </div>
                    : (contestToShow.length === 0 ?
                        <div className="d-flex justify-content-center py-5 fs-sm text-secondary">{type === "ongoing" ? "No Upcoming Contests" : "No Past Contest"}</div> :
                        contestToShow.map((contest, index) => {
                            // console.log("contestid============", contest.id)
                            return (
                                <div key={contest.id} className={'d-flex position-relative flex-nowrap align-items-center justify-content-between shadow-hover px-2 py-3 rounded-2' + (index % 2 ? " bg-1-light" : "")}>
                                    <div className="contest-item-left-cnt d-flex" onClick={() => navigate('/contest/' + contest.id)}>
                                        <img src={contestImg} className='contest-item-img' alt="" />
                                        <div>
                                            <div className='contest-item-name fw-m hover-pointer pointer fs-6' >
                                                {contest.name}
                                            </div>
                                            <div className='d-flex' >
                                                <div className='fw-m contest-item-details  text-nowrap'>
                                                    {(new Date(contest.start_time)).toLocaleDateString("en-IN", { timeZone: "Asia/Kolkata" }) + " "}
                                                </div>
                                                <img src={dividerIcon} className='contest-item-details-divider' alt="" />
                                                <div className='fw-m contest-item-details text-nowrap'>
                                                    {(new Date(contest.start_time)).toLocaleTimeString('en-IN', { timeZone: "Asia/Kolkata" })}
                                                </div>
                                                <img src={dividerIcon} className='contest-item-details-divider d-mobile-none' alt="" />
                                                <div className='fw-m contest-item-details text-center text-nowrap d-mobile-none' >{contest.duration + " min"}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="contest-item-right-cnt">
                                        <div className='fw-l fs-6 col text-center' >
                                            {
                                                type === "ongoing" &&
                                                <div>
                                                    {checkStatus(contest.start_time, contest.duration) && <>

                                                        {checkStatus(contest.start_time, contest.duration) && !contest.registerStatus &&
                                                            <button disabled={true}
                                                                className='btn register-btn fs-xsm bg-light bg-hover-1 border text-hover-light border-0 fw-m text-1 ' href="">
                                                                Registration closed
                                                            </button>
                                                        }

                                                        {checkStatus(contest.start_time, contest.duration) && contest.registerStatus === "SUBMITTED" &&
                                                            <button disabled={true}
                                                                className='btn register-btn fs-xsm bg-light bg-hover-1 border text-hover-light border-0 fw-m text-1 ' href="">
                                                                Submitted
                                                            </button>
                                                        }
                                                        {checkStatus(contest.start_time, contest.duration) && contest.registerStatus === "REGISTERED" &&
                                                            <button disabled={contest.registerStatus && !checkStatus(contest.start_time, contest.duration)}
                                                                className='btn register-btn fs-xsm bg-light bg-hover-1 border text-hover-light border-0 fw-m text-1 ' href="">
                                                                <Link to={`/contest/${contest.id}`}>
                                                                    Take test
                                                                </Link>
                                                            </button>
                                                        }
                                                    </>
                                                    }

                                                    {!checkStatus(contest.start_time, contest.duration) &&
                                                        <button disabled={contest.registerStatus || ((new Date()) > (new Date(contest.end_time)))}
                                                            onClick={() => {
                                                                // registerUser(contest.id)
                                                                navigate('/contest/' + contest.id)
                                                            }}
                                                            className='btn fs-xsm bg-light bg-hover-1 border text-hover-light border-0 fw-m text-1 ' href="">
                                                            {/* {contest.registerStatus ? checkStatus(contest.start_time, contest.duration) ? "Take test" : ((new Date()) > (new Date(contest.end_time))) ? "Calulating results":"Registered" : "Register"} */}
                                                            {!checkStatus(contest.start_time, contest.duration) && ((new Date()) > (new Date(contest.end_time))) && "Calculating results"}
                                                            {!checkStatus(contest.start_time, contest.duration) && !((new Date()) > (new Date(contest.start_time))) && !contest.registerStatus && "Register"}
                                                            {!checkStatus(contest.start_time, contest.duration) && !((new Date()) > (new Date(contest.start_time))) && contest.registerStatus && "Registered"}
                                                            {/* {checkStatus(contest.start_time, contest.duration) ? contest.registerStatus ? "Take test" : "Registration closed": ((new Date()) > (new Date(contest.end_time))) ?"Calculating results": contest.registerStatus? "Registered":"Register"  } */}
                                                        </button>}
                                                    <br />
                                                </div>
                                            }
                                            {type !== "ongoing" &&
                                                <div onClick={() => {
                                                    if (user) { navigate(`/profile/result/${user.userId}/${contest.id}`) }
                                                }} className='btn fs-xsm bg-light bg-hover-1 border text-hover-light border-0 fw-m text-1 '>
                                                    Result
                                                </div>
                                            }
                                            <div onClick={() => {
                                                navigate(`/contestRegistrants/${contest.id}`)
                                            }} className='text-secondary fw-l fs-sm text-nowrap hover-pointer'>
                                                {"x" + contest.noOfStudentsRegistered}
                                            </div>
                                        </div>
                                    </div>
                                    {/* {type !== "ongoing" &&
                                        <div className='contest-result-btn fw-l fs-xsm hover-pointer text-secondary text-nowrap'>
                                            Check your result
                                        </div>
                                    } */}
                                </div>
                            )
                        })
                    )
                }
            </div>
        </div>
    )
}


const checkStatus = (start_time, duration) => {
    let test_start_time = (new Date(start_time))
    let test_end_time = (new Date(start_time))
    test_end_time.setMinutes(test_start_time.getMinutes() + duration)
    let current_date = new Date();
    var currentOffset = current_date.getTimezoneOffset();
    var ISTOffset = 330;   // IST offset UTC +5:30 
    current_date = new Date(current_date.getTime() + (ISTOffset + currentOffset) * 60000);
    if (current_date > test_start_time && current_date < test_end_time) return true;
    return false;
}

const getISTTime = (time) => {
    let current_date = new Date();
    var currentOffset = current_date.getTimezoneOffset();
    var ISTOffset = 330;   // IST offset UTC +5:30 
    current_date = new Date(current_date.getTime() + (ISTOffset + currentOffset) * 60000);
    return current_date;
}