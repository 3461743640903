import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { API_URL } from '../config'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import practiceIcon from '../assets/practice.svg'
import communityIcon from '../assets/community.svg'
import contestIcon from '../assets/contest.svg'
import mentorshipIcon from '../assets/mentorship.svg'
import practiceActiveIcon from '../assets/practiceactive.svg'
import communityActiveIcon from '../assets/communityactive.svg'
import contestActiveIcon from '../assets/contestactive.svg'
import mentorshipActiveIcon from '../assets/mentorshipactive.svg'

const Item = ({ index, title, active, icon, activeIcon, setActive }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [isHovering, setIsHovering] = useState(false)
    const [carouselIndex, setCarouselIndex] = useState(active ? 1 : 0)
    useEffect(() => {
        setCarouselIndex(location.pathname.split('/')[1] === (title.toLowerCase()) ? 1 : 0)
    }, [])
    const handleOnMouseEnter = () => {
        setIsHovering(true)
        if (carouselIndex !== 1) setCarouselIndex(1)
    }
    const handleOnMouseLeave = () => {
        setIsHovering(false)
        if (!(location.pathname.split('/')[1] === (title.toLowerCase()))) setCarouselIndex(0)
    }

    return (
        <div>
            <div onClick={() => {
                // setActive(index);
                navigate('/' + title.toLowerCase())
            }
            } onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} className={"navbar-btn-cnt d-flex flex-column align-items-center" + ((isHovering || (location.pathname.split('/')[1] === (title.toLowerCase()))) ? " navbar-btn-hover" : "")}>
                <Carousel activeIndex={carouselIndex} controls={false} indicators={false}>
                    <Carousel.Item >
                        <img className={'navbar-icon'} src={icon} alt="" />
                    </Carousel.Item>
                    <Carousel.Item >
                        <img className={'navbar-icon'} src={activeIcon} alt="" />
                    </Carousel.Item>
                </Carousel>
                <div className={'navbar-btn-title' + ((isHovering || (location.pathname.split('/')[1] === (title.toLowerCase()))) ? " text-1" : "")}>
                    {title}
                </div>
            </div>
        </div>
    )
}


export default function NavbarButtons({ setActive, active }) {

    useEffect(() => {
        // console.log(active)
    }, [active])
    const data = [
        {
            title: "Community",
            icon: communityIcon,
            activeIcon: communityActiveIcon
        },
        {
            title: "Practice",
            icon: practiceIcon,
            activeIcon: practiceActiveIcon
        },
        {
            title: "Contest",
            icon: contestIcon,
            activeIcon: contestActiveIcon
        },
        // {
        //     title: "Mentorship",
        //     icon: mentorshipIcon,
        //     activeIcon: mentorshipActiveIcon
        // },
    ]

    return (
        <div id='navbar-btn-outer-cnt' className="d-flex">
            {data.map((item, index) => {
                return <Item key={index} index={index} title={item.title} setActive={setActive} active={active === index} icon={item.icon} activeIcon={item.activeIcon} />
            })}
        </div>
    )
}
