import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { API_URL } from '../config'
import Navbar from '../components/Navbar'
import { useParams } from 'react-router-dom'
import TestRegistration from '../components/TestRegistration'
import { useCookies } from 'react-cookie'
import { setJwtToken, setUser } from '../reducers/user'

export default function ContestInfo() {
  const [showLoginCanvas, setShowLoginCanvas] = useState(false)
  const [contestData, setContestData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [fetchingUser, setFetchingUser] = useState(false)
  const [fetchingRegistrationStatus, setFetchingRegistrationStatus] = useState(false);
  const [status, setStatus] = useState(false)
  const { id } = useParams();
  const dispatch = useDispatch()

  const jwtToken = useSelector((state) => state.user.jwtToken)
  const user = useSelector((state) => state.user.user)
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  useEffect(() => {
    if (cookies && cookies.token && jwtToken === null) {
      dispatch(setJwtToken(cookies["token"]));
    }
    getContestDetails(id)
  }, [])


  useEffect(() => {
    if (jwtToken) {
      fetchCurrentUser(jwtToken);
    }
  }, [jwtToken])

  const fetchCurrentUser = async (jwtToken) => {
    try {

      setFetchingUser(true)

      const res = await axios.get(API_URL + "/currentUser", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        }
      });
      console.log("current user==========", res.data)
      dispatch(setUser(res.data))
      setFetchingUser(false)
    } catch (e) {
      setFetchingUser(false)
      console.log(e)
    }
  }
  const getContestDetails = async (id) => {
    try {
      setLoading(true)
      const res = await axios.get(API_URL + "/contest/" + id);



      console.log("Contest data===========", res.data);
      setContestData(res.data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e.message);
    }
  }
  const getRegisterStatus = async () => {
    try {
      setFetchingRegistrationStatus(true)
      const isRegistered = await axios.get(API_URL + `/registerForContest/check?contestId=${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + jwtToken
        }
      });
      console.log("is user registered============", isRegistered.data);
      setStatus(isRegistered.data);
      setFetchingRegistrationStatus(false)
    } catch (e) {
      setFetchingRegistrationStatus(false)
      console.log(e)
    }

  }

  useEffect(() => {
    if (user){
      getRegisterStatus();
    }
    document.title = 'Contest | Unbind';
  }, [user])

  return (
    <div>
      <Navbar showLoginCanvas={showLoginCanvas} setShowLoginCanvas={setShowLoginCanvas} />
      {contestData && <TestRegistration contest={contestData} setShowLoginCanvas={setShowLoginCanvas} registered={status} loading={loading || fetchingRegistrationStatus || fetchingUser} getRegisterStatus={getRegisterStatus}/>}
    </div>
  )
}
