import React, { useEffect, useRef } from 'react'

const NumberCard = ({ angle, icon, number, title, top, animate }) => {
  const cardRef = useRef(null);

  function animateCardOnIntersection(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        console.log('animate')
        console.log(animate)
        entry.target.classList.add(animate);
      }
      else {
        console.log('not animate')
        entry.target.classList.remove(animate);
      }
    });
  }

  const observer = new IntersectionObserver(animateCardOnIntersection, {
    threshold: 0.50,
  });

  useEffect(() => {
    if (cardRef.current) {
      observer.observe(cardRef.current);
    }
  }, []);

  return (
    <div ref={cardRef} className='d-flex flex-column align-items-center justify-content-center p-5 rounded-4 position-relative numberCardSubContainer' style={{ backgroundColor: 'white', color: 'black', top: `${top}`, border: '5px solid #5865F2' }}>
      <img src={icon} alt={title} className='width80' />
      <blockquote>
        <div className='font3 numberCardFont1 fw-bold'>{number}</div>
        <div className='font2 numberCardFont2 text-center'>{title}</div>
      </blockquote>
    </div>
  )
}

export default NumberCard
