import React from 'react'
import NumberCard from './NumberCard';
import study from '.././home_assets/studyIcon.svg';
import list from '.././home_assets/listIcon.svg';
import people from '.././home_assets/personIcon.svg';
import curve from '.././home_assets/curve.svg';
import './NumberSection.css';

const NumberSection = () => {
  return (
    <div className='col-md-11 col-12 numberSectionContainer mx-auto my-5' style={{backgroundColor: 'var(--primary-dark)', color:'white', paddingBottom: '3rem'}}>
      <div className='py-5 d-flex flex-column justify-content-center align-items-center gap-4'>
        <div className=''>
            <h2 className='fw-bold font3 text-center' >Some numbers that matter</h2>
            <div className=''>
                <img src={curve} className='position-relative' style={{width:'40%', left:'3rem', bottom:'1rem'}} alt='curve' />
            </div>
        </div>
        <div className='numberCard gap5'>
            <NumberCard angle={'-5deg'} top={'0.7rem'} icon={study} number={'1,00,000+'} title={'App Downloads'} animate={'animate1'}  />
            <NumberCard angle={'0deg'} top={'0rem'} icon={people} number={'1,15,000+'} title={'Community Members'} animate={'animate2'} />
            <NumberCard angle={'5deg'} top={'0.7rem'} icon={list} number={'1,15,000+'} title={"Contests Given"} animate={'animate3'} />
        </div>
      </div>
    </div>
  )
}

export default NumberSection
