import React from 'react'
import Loader from '../components/Loader'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import BottomBar from '../components/BottomBar'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import bg from '../components/Home/home_assets/background.jpg';
import Mission from '../components/About/Mission'
import Solution from '../components/About/Solution'
import Team from '../components/About/Team'
import { useEffect } from 'react'

const About = () => {
    const dispatch = useDispatch()
    const [showLoginCanvas, setShowLoginCanvas] = useState(false)
    const [active, setActive] = useState(2);
    const jwtToken = useSelector((state) => state.user.jwtToken)
    const { loading } = useSelector((state) => state.app)
    useEffect(() => {
        document.title = 'About | Unbind';
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='d-flex flex-column justify-content-between' style={{ minHeight: '100vh' }}>
            {loading && <Loader />}
            <div>
                <Navbar showCategories={true} showButtons={true} active={active} setActive={setActive} showLoginCanvas={showLoginCanvas} setShowLoginCanvas={setShowLoginCanvas} />

                <div className='' style={{ background: `linear-gradient(180deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.7)), url(${bg})` }}>
                    <Mission />
                    <Solution />
                    <Team />
                </div>
                <Footer />
            </div>
            <BottomBar active={active} setActive={setActive} />
        </div>
    )
}

export default About
