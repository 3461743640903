import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import PrivateInfo from './PrivateInfo';
import ProfileOverview from './ProfileOverview'
import ProfileRatingCard from '../../Card_components/ProfileRatingCard';
import { useParams } from 'react-router-dom';
import { API_URL } from '../../../config';
import { header } from '../../HeaderData';
import Loading from '../../Loading/Loading';
import { toast } from 'react-toastify';
import { alertMsg } from '../../Alert/AlertData';

const Dashboard = () => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [follower, setFollower] = useState(0);
    const [following, setFollowing] = useState(0);
    const [graphData, setGraphData] = useState(null);
    const selectedCategory = useSelector((state) => state.user.defaultCategory);

    const [editProfile, setEditProfile] = useState(false);

    const userProfileStructure = {
        email: '', username: ''
    }

    const [userProfile, setUserProfile] = useState(userProfileStructure);

    const { id } = useParams();

    const { jwtToken, user } = useSelector((state) => state.user);

    const getContestLists = async () => {
        // console.log(selectedCategory)
        const res = await axios.get(`${API_URL}/registerForContest/query?contestCategoryId=${selectedCategory?.id}&userId=${id}&page=0&registerStatus=DECLARED&size=30`);
        console.log('contest and graph data============================================>', res);
        setGraphData(res?.data);
    }

    const getFollowerData = async () => {
        const res = await axios.get(`${API_URL}/follower/count?userId=${id}&myFollowings=${false}`);
        const res2 = await axios.get(`${API_URL}/follower/count?userId=${id}&myFollowings=${true}`);
        console.log('followers data ==================================================================>', res);
        console.log('followings data ==================================================================>', res2);
        setFollower(res?.data);
        setFollowing(res2?.data);
    }

    const getYourInfo = async () => {
        const res = await axios.get(`${API_URL}/currentUser`, header(jwtToken));
        console.log('your profile info =====================================================================>', res);
        setUserData(res?.data);
    }

    const getUserInfo = async () => {
        const res = await axios.get(`${API_URL}/user/${id}`);
        console.log('user profile info ====================================================================>', res);
        setUserData(res?.data);
    }


    const handleProfile = async () => {
        if (editProfile) {
            if (userProfile?.username?.length > 20)
                return alertMsg('error', `Username can't be greater than 20 characters`);
            const data = { ...userData, name: userProfile?.username, email: userProfile?.email }
            console.log(data)
            const res = await axios.put(`${API_URL}/user`,
                { ...data },
                header(jwtToken)
            );

            console.log(res);
            if (res?.status === 200) {
                alertMsg('success', 'Successfully Updated!')
            }
            if (jwtToken && user?.userId === id) {
                setUserData(null);
                getYourInfo();
            }
            else {
                getUserInfo();
            }
            setUserProfile({ username: '', email: '' });
            setEditProfile(false);
        }
        else {
            setUserProfile({ username: userData?.name, email: userData?.email ? userData?.email : '' })
            setEditProfile(true);
        }
    }

    const handleChange = (e) => {
        setUserProfile({ ...userProfile, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        if (jwtToken && user?.userId === id) {
            setUserData(null);
            getYourInfo();
            // getGraphData();
        }
        else {
            getUserInfo();
            // getGraphData();
        }
        getFollowerData();
        getContestLists();
        return () => {

        }
    }, [jwtToken, user, id])

    return (
        <>
            {
                userData === null && graphData === null ?
                    [1, 2, 3].map((d) =>
                        <div className='p-3' key={d} style={{ background: 'var(--white)' }}>
                            <Loading classes={'col-12'} height={'15rem'} key={d} />
                        </div>
                    )
                    :
                    <div className='d-flex flex-column gap-4 profile-overview-container mt-2'>
                        <ProfileOverview
                            data={{ ...userData, follower, following, total_contest: graphData?.totalElements }}
                            setEditProfile={setEditProfile}
                            editProfile={editProfile}
                            userProfile={userProfile}
                            handleChange={handleChange}
                            handleProfile={handleProfile}
                            viewEdit={user?.userId === id ? true : false}
                        />
                        {
                            !editProfile && <ProfileRatingCard data={graphData?.content} />
                        }
                        {
                            user?.userId === id &&
                            <PrivateInfo
                                data={userData}
                                editProfile={editProfile}
                                userProfile={userProfile}
                                handleChange={handleChange}
                            />
                        }
                    </div>
            }
        </>
    )
}

export default Dashboard
