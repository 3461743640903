import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { API_URL } from '../config'
import Navbar from '../components/Navbar'
import Carousel from '../components/Carousel'
import { setCategories } from '../reducers/contest'
import { setJwtToken, setUser } from '../reducers/user'
import Contest from '../components/Contest'
import ComingSoon from '../components/ComingSoon'
import { useCookies } from 'react-cookie'
import Loader from '../components/Loader'
import BottomBar from '../components/BottomBar'
import { setLoading } from '../reducers/app'
import '../index.css'
import Main from '../components_App/Contest_App/Main'
import Footer from '../components/Footer/Footer'
import { useParams } from 'react-router-dom'


export default function Contests_App() {
  const dispatch = useDispatch()
  const [showLoginCanvas, setShowLoginCanvas] = useState(false)
  const [active, setActive] = useState(2);
  const { loading } = useSelector((state) => state.app);
  const { jwtToken } = useSelector((state) => state.user);

  const { type } = useParams();

  useEffect(() => {
    console.log(jwtToken);
    if (!jwtToken && type === 'mycontests')
      setShowLoginCanvas(true)
    else
      setShowLoginCanvas(false);

    getCategories();
  }, [jwtToken]);

  const getCategories = async () => {
    try {
      dispatch(setLoading(true))
      const res = await axios.get(API_URL + "/contestCategory");
      console.log("API URL===========", res.data);
      dispatch(setCategories(res.data))
      dispatch(setLoading(false))
    } catch (e) {
      console.log(e.message);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Contest | Unbind';
  }, []);

  return (
    <div className='d-flex flex-column justify-content-between app_container' style={{ minHeight: '100vh' }}>
      {loading && <Loader />}
      <div>
        {/* <Navbar showCategories={true} showButtons={true} active={active} setActive={setActive} showLoginCanvas={showLoginCanvas} setShowLoginCanvas={setShowLoginCanvas} /> */}
        {active === 2 &&
          <>
            {/* <Contest showLoginCanvas={showLoginCanvas} setShowLoginCanvas={setShowLoginCanvas} type="ongoing" />
          <Contest type="past" /> */}
            <Main />
          </>}
        {/* <Footer /> */}
      </div>
      {/* <BottomBar /> */}
    </div>
  )
}
