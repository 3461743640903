import React from 'react'
import TopSection from './TopSection';
import './Refer.css';
import TopReferrers from './TopReferrers';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import Loading from '../../Loading/Loading';
import { API_URL } from '../../../config';

const Refer = () => {
    const [referralLists, setReferralLists] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const { user, jwtToken } = useSelector((state) => state.user);
    const pageSize = 10;

    const getReferrals = async () => {
        setLoading(true);
        try {
            console.log(jwtToken, user?.userId)
            const res = await axios.get(`${API_URL}/getReferralList?userId=${user?.userId}&size=${pageSize}&page=${activePage}`, { headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + jwtToken } });
            console.log('referral list===========================================>', res);
            setReferralLists(res?.data?.content);
            setTotalPages(res?.data?.totalPages)
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    useEffect(() => {
        if(jwtToken && user)
        getReferrals();

        return () => {
            window.scrollTo(0, 0);
        }
    }, [jwtToken, user, activePage]);

    return (
        <div className='py-5 rounded-2' style={{ background: 'var(--yellow-50)' }}>
            <div className='col-md-11 col-11 mx-auto font-100'>
                <TopSection code={user?.userId} />
                {
                    (referralLists ===null || loading )?
                    <Loading height={'15rem'} classes={'col-12'} />
                    :
                    <TopReferrers tableData={referralLists} activePage={activePage} setActivePage={setActivePage} pageSize={pageSize} totalPages={totalPages} />
                }
            </div>
        </div>
    )
}

export default Refer
