import { toast } from "react-toastify";

export const handleCopyClick = (code) => {
    navigator.clipboard.writeText(`https://www.unbind.co.in/refer/${code}`)
        .then(() => {
            toast.success('Code Copied!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        })
        .catch((err) => {
            toast.error('Error copying code!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        });
}

export const referralTableStructure = [
    {
        title: 'Rank',
        value: 'rank'
    },
    {
        title: 'Name',
        value: 'name'
    },
    {
        title: 'Date',
        value: 'creationDate'
    }
];

export const restructureTable = (data) => {
    const updatedTable = data?.map((fields) => {
        return {
            ...fields, creationDate: (new Date(fields?.creationDate)).toLocaleDateString("en-IN", { timeZone: "Asia/Kolkata" }) + " "
        }
    })
    return updatedTable;
}