import React from 'react'
import { BsChevronRight } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

const ListCardView = ({ text, icon, link }) => {
    const navigate = useNavigate();
    return (
        <div
            className='shadow-sm shadow-card p-2 p-md-3 rounded-2 cursor d-flex gap-2 align-items-center'
            style={{ background: 'var(--gray-900)', color:'var(--gray-400)' }}
            onClick={() => navigate(link)}
        >
            <div className=''>
                <img style={{ width: '20px' }} src={icon} alt='icon' />
            </div>
            <div className='pe-2 pe-md-4'>
                {text}
            </div>
            <div className=''>
                <BsChevronRight />
            </div>
        </div>
    )
}

export default ListCardView
