import React from 'react';
import img from './About_assets/mission.png';
import { useEffect } from 'react';
import Typewriter  from 'typewriter-effect/dist/core';

const Mission = () => {
    const cardContents = [
        { number: '115K+', type: 'COMMUNITY', color: '#23B082' },
        { number: '1000+', type: 'GROUPS', color: '#FFC804' },
        { number: '100K+', type: "APP DOWNLOADS", color: '#3082F2' },
        { number: '115K+', type: 'CONTEST ATTEMPTS', color: '#E36963' }
    ]

    useEffect(() => {
        new Typewriter('#typewriter2', {
            strings: ['Future', 'Aspirants', 'Students'],
            autoStart: true,
            loop: true,
            pauseFor: 800
        });
    }, [])
    return (
        <div className='mb-5 col-md-10 col-11 mx-auto d-flex flex-column gap-3 align-items-start pt-5'>
            <div className='font-type-2 text-center text-lg-start color-primary col-12'>
                OUR MISSION & IMPACT
            </div>
            <div className='fw-bold font-type-1 text-center text-lg-start col-12' style={{ color: 'var(--gray-600)' }}>
                Building largest Community <br />
                of India's{" "}
                <span className='color-primary' id='typewriter2'>
                </span>
            </div>
            <div className='d-flex justify-content-between flex-wrap-reverse align-items-center col-12' style={{ width: '100%' }}>
                <div className='d-grid mb-5 gap-4 col-lg-5 col-12' style={{ gridTemplateColumns: '1fr 1fr' }}>
                    {
                        cardContents.map((content, index) =>
                            <div className='about-card-shadow rounded-sm d-flex flex-column justify-content-center align-items-center h-fit py-4 p5' style={{ background: 'white' }} key={index}>
                                <div className={`font3 fw-medium text-center`} style={{ color: content.color }}>{content.number}</div>
                                <div className='text-[var(--theme-secondary-color)] text-center'>{content.type}</div>
                            </div>
                        )
                    }
                </div>
                <div className='text-center mb-5 col-lg-5 col-12'>
                    <img src={img} className='mx-auto col-lg-12 col-11' alt='' />
                </div>
            </div>
        </div>
    )
}

export default Mission
