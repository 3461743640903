import React from 'react'
import BriefInfo from './components/BriefInfo'
import img1 from './contestLists_assets/download1.png';
import img2 from './contestLists_assets/download2.png';
import { FaGooglePlay } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { RxDotFilled } from 'react-icons/rx';

const Home = ({ subjectLists, sessionData }) => {
    const details = ['Created by Experts', 'Detailed solutions & analytics', '10000+ Questions'];

    const navigate = useNavigate();
    return (
        <div className='font-type-5'>
            {/* --------------------------------------------------------------- */}
            {/* Brief Info Section Start */}
            {/* --------------------------------------------------------------- */}
            <BriefInfo subjectLists={subjectLists} sessionData={sessionData} />
            {/* --------------------------------------------------------------- */}
            {/* Brief Info Section End */}
            {/* --------------------------------------------------------------- */}

            {/* --------------------------------------------------------------- */}
            {/* App Download Section Start */}
            {/* --------------------------------------------------------------- */}
            <div className='d-flex flex-column gap-5 col-md-10 col-11 mx-auto my-5'>
                {/* <div
                    className='p-4 d-flex justify-content-between align-items-center col-12'
                    style={{
                        background: 'linear-gradient(91.06deg, #1FB35B 1.08%, rgba(255, 255, 255, 0) 83.51%)'
                    }}
                >
                    <div className='d-flex flex-column gap-4 justify-content-between col-md-8 col-12'>
                        <div className='font-type-2 fw-6'>
                            Unbind app is available on Playstore
                        </div>
                        <div className='d-flex align-items-start'>
                            <RxDotFilled className='font-type-2 position-relative' style={{ color: '#00AC00', bottom: '2px' }} />
                            <span>
                                Join <strong>anonymous</strong> learning Revolution to compete and express freely!
                            </span>
                        </div>
                        <div className='text-center d-block d-md-none mb-3'>
                            <img src={img1} style={{ width: '60%', minWidth: '200px', maxWidth: '250px' }} alt='' />
                        </div>
                        <div className=''>
                            <Link
                                to={`https://link.unbind.co.in/HcFVd8`}
                                className='btn d-flex align-items-center gap-2 px-3 px-md-4 font-type-9 fw-5 py-2'
                                style={{
                                    background: '#3D944B',
                                    color: 'white',
                                    width: 'fit-content'
                                }}
                            >
                                <FaGooglePlay />
                                Download
                            </Link>
                        </div>
                    </div>
                    <div className='col-md-4 col-0'>
                        <img src={img1} style={{ maxWidth: '100%' }} alt='' />
                    </div>
                </div> */}

                {/* <div
                    className='p-4 d-flex justify-content-between align-items-center col-12'
                    style={{
                        background: 'linear-gradient(91.06deg, #FFD592 1.08%, rgba(255, 255, 255, 0) 83.51%)'
                    }}
                >
                    <div className='d-flex flex-column gap-4 justify-content-between col-md-8 col-12'>
                        <div className='font-type-2 fw-6'>
                            Unbind app is available on Playstore
                        </div>
                        <div className='d-flex gap-3 align-items-center'>
                            Enter our Ultimate Adventure Contest and Win the Journey of a Lifetime!
                        </div>
                        <div className='text-center d-block d-md-none mb-3'>
                            <img src={img2} style={{ width: '60%', minWidth: '200px', maxWidth: '250px' }} alt='' />
                        </div>
                        <div className=''>
                            <button
                                className='btn px-3 px-md-4 font-type-9 fw-5 py-2'
                                style={{
                                    background: '#F3A233',
                                    color: 'white'
                                }}
                                onClick={() => navigate('/app/contest')}
                            >
                                Go To Contest
                            </button>
                        </div>
                    </div>
                    <div className='col-md-4 col-0'>
                        <img src={img2} style={{ maxWidth: '100%' }} alt='' />
                    </div>
                </div> */}
            </div>
            {/* --------------------------------------------------------------- */}
            {/* App Download Section Start */}
            {/* --------------------------------------------------------------- */}
        </div>
    )
}

export default Home
