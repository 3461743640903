import React from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'

const ListCardView2 = ({ data }) => {
    const { subjectId } = useParams();
    const navigate = useNavigate();
    return (
        <div
            className='font-type-5 p-3 cursor shadow-card shadow-sm d-flex flex-column justify-content-between'
            style={{ background: 'rgba(255,255,255,0.12)' }}
            onClick={() => navigate(`/app/practice/${subjectId}/${data.id}`)}
        >
            <div className='d-flex justify-content-between gap-3 mb-3'>
                <div className='font-type-4'>
                    {data.name}
                </div>
                <div className=''>
                    <FaAngleRight />
                </div>
            </div>
            <div className='font-type-6' style={{color:'var(--gray-500)'}}>
                {data.totalQuestions} Questions
            </div>
        </div>
    )
}

export default ListCardView2
