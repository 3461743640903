import CryptoJS from "crypto-js";

export const encryptData = (data, secretKey) => {
    // Convert the secretKey to a WordArray
    const key = CryptoJS.enc.Utf8.parse(secretKey);
  
    // Convert the data to be encrypted to a WordArray
    const dataToEncrypt = CryptoJS.enc.Utf8.parse(data);
  
    // Perform AES encryption
    const encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, {
      mode: CryptoJS.mode.ECB, // Electronic Codebook (ECB) mode
      padding: CryptoJS.pad.Pkcs7, // PKCS #7 padding
    });
  
    // The result will be a CipherParams object
    // Convert it to a string for transmission (e.g., over the network)
    return encrypted.toString();
  };