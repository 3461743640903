import React, { useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { BiChevronsDown, BiChevronsUp } from 'react-icons/bi';
import './Card.css'
import MarksCategory from '../Results/MarksCategory';

const ScoreCard = ({ data, avgData }) => {
    const [expand, setExpand] = useState(false);
    console.log(avgData)

    const categoryMarksData = [
        { title: 'Positive marks', type: 'positiveSubjectMarks', avgType: 'avgPositiveMarks', color: '#79C285', avgColor: '#487B66' },
        { title: 'Unattempt marks', type: 'unAttemptedSubjectMarks', avgType: 'avgUnAttemptedMarks', color: '#FCE488', avgColor: '#FFCA00' },
        { title: 'Negative marks', type: 'negativeSubjectMarks', avgType: 'avgNegativeMarks', color: '#E36963', avgColor: '#D60C02' }
    ];

    return (
        <div className={`shadow-sm rounded-2 position-relative col-12 col-lg-${expand ? '12' : '6'} scorecardCont`} style={{ border: '1px solid var(--gray-600)' }}>
            <div className='d-flex flex-lg-row flex-column'>
                <div className={`p-md-5 p-3 pb-5 d-flex flex-column gap-3 col-12 col-lg-${expand ? '6' : '12'}`}>
                    <div className='font-120 fw-medium rounded-5 px-3' style={{ color: 'var(--gray-700)', background: 'var(--gray-200)', width: 'fit-content' }}>{data?.questionSubjectName}</div>
                    <div className='font-120'>Scored <span className='font-150 fw-medium' style={{ color: '#79C285' }}>{data?.studentSubjectMarks}</span>/{data?.subjectFullMarks}</div>
                    <div className=''>
                        <ProgressBar>
                            <ProgressBar style={{ background: '#79C285' }} now={getMarksPercent(data?.positiveSubjectMarks, data)} key={1} />
                            <ProgressBar style={{ background: '#FCE488' }} now={getMarksPercent(data?.unAttemptedSubjectMarks, data)} key={2} />
                            <ProgressBar style={{ background: '#E36963' }} now={getMarksPercent(data?.negativeSubjectMarks, data)} key={3} />
                        </ProgressBar>
                    </div>
                    {
                        expand ?
                            <div className=''>
                                <ProgressBar>
                                    <ProgressBar style={{ background: '#487B66' }} now={getAvgMarksPercent(avgData?.avgPositiveMarks, avgData)} key={1} />
                                    <ProgressBar style={{ background: '#FFCA00' }} now={getAvgMarksPercent(avgData?.avgUnAttemptedMarks, avgData)} key={2} />
                                    <ProgressBar style={{ background: '#D60C02' }} now={getAvgMarksPercent(avgData?.avgNegativeMarks, avgData)} key={3} />
                                </ProgressBar>
                            </div>
                            :
                            <div className='d-flex gap-2 justify-content-between font-80'>
                                {
                                    categoryMarksData.map((d, index) =>
                                        <MarksCategory
                                            key={index}
                                            title={d.title}
                                            marks={data[d.type]}
                                            color={d.color}
                                        />
                                    )
                                }
                            </div>
                    }
                </div>
                {
                    expand &&
                    <div className={`p-md-5 d-flex p-3 pb-5 gap-3 col-12 col-lg-${expand ? '6' : '12'}`}>
                        <div className='d-flex flex-column gap-3 col-6'>
                            <div className=''>Your Score <span>{data?.studentSubjectMarks}</span>/100</div>
                            <div className='d-flex flex-column gap-2'>
                                {
                                    categoryMarksData.map((d, index) =>
                                        <MarksCategory
                                            key={index}
                                            title={d.title}
                                            marks={data[d.type]}
                                            color={d.color}
                                        />
                                    )
                                }
                            </div>
                        </div>
                        <div className='d-flex flex-column gap-3 col-6'>
                            <div className=''>Average Score <span>{(avgData?.avgTotalMarks).toFixed(2)}</span>/100</div>
                            <div className='d-flex flex-column gap-2'>
                                {
                                    categoryMarksData.map((data, index) =>
                                        <MarksCategory
                                            key={index}
                                            title={data.title}
                                            marks={avgData[data.avgType].toFixed(2)}
                                            color={data.avgColor}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
            {
                expand ?
                    <div className='position-absolute px-2 shrinkCard rounded-top-2' style={{ background: 'var(--gray-400)', color: 'var(--gray-700)', cursor: 'pointer' }} onClick={() => setExpand(false)}>
                        Shrink <BiChevronsUp />
                    </div>
                    :
                    <div className='position-absolute px-2 expandCard rounded-top-2' style={{ background: 'var(--gray-400)', color: 'var(--gray-700)', cursor: 'pointer' }} onClick={() => setExpand(true)}>
                        Expand <BiChevronsDown />
                    </div>
            }
        </div>
    )
}

export default ScoreCard;

const getMarksPercent = (marks, data) => {
    return ((marks / (data?.positiveSubjectMarks + data?.negativeSubjectMarks + data?.unAttemptedSubjectMarks)) * 100)
}

const getAvgMarksPercent = (marks, data) => {
    return ((marks / (data?.avgPositiveMarks + data?.avgNegativeMarks + data?.avgUnAttemptedMarks)) * 100)
}
