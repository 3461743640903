
import { signInWithPhoneNumber } from "firebase/auth";
import firebase from "../firebaseConfig";



export const sendOtp = (phoneNumber, recaptchaVerifier) => async (dispatch) => {
    try {
        // const res = signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
        const res = await signInWithPhoneNumber(firebase.auth, '+91' + phoneNumber, recaptchaVerifier)
        return res;
    } catch (e) {
        console.log(e)
    }
}


