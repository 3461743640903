import React from 'react';
import underline from './About_assets/curve.png';
import team from './About_assets/team.png';
import team1 from './About_assets/pic1.png';
import team2 from './About_assets/pic2.png';
import team3 from './About_assets/pic3.png';
import team4 from './About_assets/pic4.png';
import team5 from './About_assets/pic5.png';
import team6 from './About_assets/pic6.png';
import team7 from './About_assets/pic7.png';
import team8 from './About_assets/pic8.png';
import team9 from './About_assets/pic9.png';
import team10 from './About_assets/pic10.png';
import team11 from './About_assets/pic11.png';
import team12 from './About_assets/pic12.png';
import team13 from './About_assets/pic13.png';
import './About.css';

const Team = () => {
    const images = [
        { img: team1, name: 'Umesh Kumar', pos: 'Co-Founder' },
        { img: team2, name: 'Praveen Garg', pos: 'Co-Founder' },
        { img: team3, name: 'Sudhir Yadav', pos: 'Co-Founder' },
        { img: team4, name: 'Sahil Jindal', pos: 'Co-Founder' },
        { img: team5, name: 'Siddharth Garg', pos: 'Engineering' },
        { img: team6, name: 'Aman Priyadarshi', pos: 'Design' },
        { img: team7, name: 'Ankit Kumar', pos: 'Engineering' },
        { img: team8, name: 'Bhupendra Malakar', pos: 'Engineering' },
        { img: team10, name: 'Vijay Yadav', pos: 'Marketing' },
        { img: team11, name: 'Vaishnavi', pos: 'Design' },
        { img: team12, name: 'Niharika Mahalwal', pos: 'Marketing' },
        { img: team13, name: 'Tarmika', pos: 'Marketing' },
    ];
    return (
        <div className='my-3 position-relative col-md-10 col-11 mx-auto'>
            <div className='d-flex flex-column align-items-center gap-3 position-relative'>
                <div className='d-flex fw-7 font-type-3 color-primary col-12 justify-content-center pb-5 position-relative'>
                    <div className='text-center'>
                        The hardworking group behind the scenes
                    </div>
                    <div className='position-absolute about-line'>
                        <img src={underline} className='col-md-12 col-6' alt='' />
                    </div>
                </div>
            </div>
            <div className='d-flex gap-5 align-items-center'>
                <div className='col-lg-3 col-md-2 col-0 d-none d-md-flex justify-content-start py-5 mb-3'>
                    <img src={team} style={{ width: '100%' }} alt='' />
                </div>
                <div className='team-profiles'>
                    {
                        images.map((item, index) =>
                            <div className='position-relative' key={index}>
                                <img src={item.img} alt='' />
                                <div className='show-names'>
                                    <div className='fw-6' style={{fontSize:'15px'}}>{item.name}</div>
                                    <div className='' style={{fontSize:'12px'}}>{item.pos}</div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Team
