import React from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { Link } from 'react-router-dom'

const ResultTop = ({ title, date, name }) => {
    return (
        <div className='d-flex justify-content-between mt-3'>
            <div className='d-flex flex-column gap-3'>
                <div className='d-flex gap-3 align-items-center' style={{ color: 'white' }}>
                    <Link to={'/app/contest'}>
                        <BiArrowBack style={{ color: 'white' }} />
                    </Link>
                    <div className='font-150 fw-medium'>
                        {title}
                    </div>
                    <div className='px-3 py-1 rounded-5 font-80' style={{ background: 'var(--gray-500)', color: 'var(--black)' }}>
                        {date}
                    </div>
                </div>
                <div className='font-200 fw-bold' style={{ color: 'white' }}>
                    {name}
                </div>
            </div>
        </div>
    )
}

export default ResultTop
