import React from 'react'
import ResultTop from '../Top_components/ResultTop'
import DropDown from '../DropDowns/DropDown'
import Question from './Question';
import './Main.css';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../config';
import { useState } from 'react';
import Pagination from '../Paginations/Pagination';
import { filterOptions, getOptions } from './SolutionData';
import Loading from '../Loading/Loading';
import { useSelector } from 'react-redux';
import { header } from '../HeaderData';

const Main = () => {
    const { userId, id, virtualId } = useParams();
    const [solutionData, setSolutionData] = useState(null);
    const pageSize = 10;
    const [activePage, setActivePage] = useState(0);
    const [contestData, setContestData] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [questionStatus, setQuestionStatus] = useState('');
    const [subjectFilter, setSubjectFilter] = useState('');
    const [typeFilter, setTypeFilter] = useState('');
    const [loading, setLoading] = useState(false);
    const { jwtToken } = useSelector((state) => state.user);

    const getSolutions = async () => {
        setLoading(true);
        // console.log(questionStatus);
        // console.log(subjectFilter);
        // console.log(typeFilter);
        // console.log('get solutions');
        // console.log(userId, id);
        const virtual = virtualId ? `&registerResponseId=${virtualId}` : "";
        // console.log(`${API_URL}/solutions?userId=${userId}&contestId=${id}&page=${activePage}&size=${pageSize}&questionStatus=${questionStatus}&questionSubjectId=${subjectFilter}&questionType=${typeFilter}`)
        const data = await axios.get(`${API_URL}/solutions?userId=${userId}&contestId=${id}&page=${activePage}&size=${pageSize}&questionStatus=${questionStatus}&questionSubjectId=${subjectFilter}&questionType=${typeFilter}${virtual}`);
        // console.log(data)
        const contestQuery = virtualId ? `registerForContest/${virtualId}` : `contest/${id}`;
        const contestDataRes = await axios.get(`${API_URL}/${contestQuery}`, header(jwtToken));
        setContestData(contestDataRes?.data);
        setTotalPages(data?.data?.pagesAvailable)
        setSolutionData(data?.data);
        setLoading(false);
    }

    useEffect(() => {
        if (jwtToken)
            getSolutions();

        return () => {
            window.scrollTo(0, 0);
        }
    }, [activePage, jwtToken]);

    useEffect(() => {
        if (activePage === 0)
            getSolutions()
        setActivePage(0);

        return () => {
            window.scrollTo(0, 0);
        }

    }, [questionStatus, subjectFilter, typeFilter])

    return (
        <div className=''>
            {
                (solutionData === null) ?
                    <div className='d-flex flex-column gap-5 col-md-10 col-11 mx-auto my-5 font-100'>
                        <Loading height={'2rem'} classes={'col-8'} />
                        <div className='d-flex gap-3 col-md-8 col-12'>
                            {
                                [1, 2, 3].map((title) =>
                                    <Loading classes={'col-4'} height={'2rem'} key={title} />
                                )
                            }
                        </div>
                        {
                            [1, 2, 3, 4].map((title) =>
                                <Loading classes={'col-md-8 col-12'} height={'10rem'} key={title} />
                            )
                        }
                    </div>
                    :
                    <div className='d-flex flex-column gap-5 col-md-10 col-11 mx-auto my-5 font-100'>
                        <div className='d-flex flex-column gap-4 col-md-10'>
                            {/* Top Section Start */}
                            <ResultTop
                                title={'Test Result'}
                                date={(new Date(virtualId ? contestData?.creationDate : contestData?.start_time)).toDateString("en-IN", { timeZone: "Asia/Kolkata" }) + " "}
                                name={virtualId ? contestData?.contest?.name : contestData?.name}
                            />
                            {/* Top Section End */}

                            {/* Filter Section Start  */}
                            <div className='d-flex gap-md-3 gap-1 col-md-11 col-12'>
                                {
                                    filterOptions.map((options, index) =>
                                        <div className='col-4 font-80' key={index}>
                                            <DropDown
                                                title={options.title}
                                                options={index === 1 ? getOptions(solutionData?.questionSubjectList) : options.options}
                                                setFilter={index === 0 ? setQuestionStatus : index === 1 ? setSubjectFilter : setTypeFilter}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                            {/* Filter Section End  */}

                            {/* Question Section Start */}
                            <div className='d-flex gap-5 flex-column'>
                                {
                                    loading ?
                                        [1, 2, 3, 4, 5].map((title) =>
                                            <Loading key={title} classes={'col-md-8 col-12'} height={'10rem'} />
                                        )
                                        :
                                        solutionData?.questionPracticesList?.map((data, index) =>
                                            <Question data={data} index={10 * activePage + index} key={index} />
                                        )
                                }
                            </div>
                            {/* Question Section End  */}

                        </div>
                        <div className=''>
                            <Pagination activePage={activePage} setActivePage={setActivePage} totalPages={totalPages} />
                        </div>
                    </div>
            }
        </div>
    )
}

export default Main
