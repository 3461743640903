import React, { useEffect } from 'react'
import TestContext from '../context/testContext'
import { useContext } from 'react'

export default function Sidebar() {

    let context = useContext(TestContext);


    return (
        <div id='sidebar-outer-cnt' className='shadow rounded-4 ms-4 my-3' style={{ background: 'var(--gray-400)' }}>
            <div className="sidebar-title fw-m">
                Questions
            </div>
            <div className="text-secondary fw-l fs-xsm mt-3" style={{ marginBottom: "45px" }}>
                Attemp all
            </div>
            {context.questionIds && Object.keys(context.questionIds).map((category, index) => {
                return <div
                    key={index} className="category-cnt">
                    <div className="category-title fs-xsm fw-sb">
                        {category}
                    </div>
                    <div className="questions-cnt">
                        {context.questionIds[category].map((question, questionIndex) => {
                            return <button
                                disabled={context.fetchingQuestionData}
                                key={questionIndex}
                                onClick={() => {
                                    context.updateTimeTaken();
                                    if (context.currentQuestion) {
                                        context.sendResponse(context.cache.current["submittedResponse"][context.currentQuestion.id]);
                                    }
                                    context.setCurrentQuestionId(question);
                                    context.setCurrentQuestionNumber(questionIndex + 1);
                                }}
                                className={'btn question-btn' + ((context.currentQuestionNumber === (questionIndex + 1) && context.currentQuestionId && context.currentQuestionId.id === question.id) ? " border-color-3" : "")}
                            >{questionIndex + 1}
                                {(context.cache.current["submittedResponse"][question.id] && context.cache.current["submittedResponse"][question.id] !== '') &&
                                    <div className="answered-badge">
                                    </div>}
                            </button>
                        })}
                    </div>

                </div>
            })}
        </div>
    )
}

const stringify = (temp) => {
    let t = "";
    temp.forEach((option, index) => {
        if (index !== 0) {
            t += `,${option}`
        }
        else t += option;
    });
    return t;
}