import React from 'react'

const TabSection = ({viewTab, setViewTab}) => {
    const tabList = ['Questions', 'Solutions', 'Previous Attempt'];
    return (
        <div className='QuestionViewTabOption col-12'>
            <div className='d-md-none text-center py-2 px-2' style={{ background: viewTab === 4 ? 'var(--primary)' : 'var(--gray-200)', color: viewTab === 4 ? 'white' : 'var(--gray-700)', width: '100%', minWidth: '100px', cursor: 'pointer' }} onClick={() => setViewTab(4)}>
                List
            </div>
            {
                tabList.map((option, index) =>
                    <div className='text-center py-2 px-2' style={{ background: viewTab === index + 1 ? 'var(--primary)' : 'var(--gray-200)', color: viewTab === index + 1 ? 'white' : 'var(--gray-700)', width: '100%', minWidth: '120px', whiteSpace: 'nowrap', cursor: 'pointer' }} key={index} onClick={() => setViewTab(index + 1)}>
                        {option}
                    </div>
                )
            }
        </div>
    )
}

export default TabSection
