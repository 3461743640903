import React from 'react';
import { useEffect } from 'react';
import Typewriter from 'typewriter-effect/dist/core';

const MainText = () => {
    useEffect(() => {
        new Typewriter('#typewriter', {
            strings: ['Future', 'Aspirants', 'Students'],
            autoStart: true,
            loop: true,
            pauseFor: 800
        });
    }, [])
    return (
        <div className='mb-4 mt-5 d-flex flex-column gap-3 justify-content-center align-items-center text-center col-md-10 col-11 mx-auto'>
            <h1 className='fw-bold font4 color-primary'>
                Building largest Community<br />
                <span style={{ color: 'black', display: 'inline-flex' }}>
                    <span>of India's{" "}
                        <span id='typewriter' className='color-primary'></span>
                    </span>
                </span>
            </h1>
            <p className='font1half' style={{ color: '#5E5E5E', maxWidth: '800px' }}>
                With Unbind, students can engage in friendly competition, find mentors and friends, and share their ideas with the world.
            </p>
        </div>
    )
}

export default MainText
