import { createSlice } from '@reduxjs/toolkit'

export const userReducer = createSlice({
  name: 'user',
  initialState: {
    user: null,
    jwtToken: null,
    defaultCategory: {
      id: "641feeaea2b6848a8616c4c6",
      name: "NEET"
    },
    fetchingUser:false,
  },
  reducers: {
    setDefaultCategory: (state, action) => {
      state.defaultCategory = action.payload;
    },
    setJwtToken: (state, action) => {
      state.jwtToken = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setFetchingUser: (state, action) => {
      state.fetchingUser = action.payload;
    },

  },
})

// Action creators are generated for each case reducer function
export const { setDefaultCategory, setJwtToken, setUser,setFetchingUser } = userReducer.actions

export default userReducer.reducer