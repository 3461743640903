import React from 'react'
import NavbarButtons from './NavbarButtons'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Carousel } from 'react-bootstrap'

import practiceIcon from '../assets/practice.svg'
import communityIcon from '../assets/community.svg'
import contestIcon from '../assets/contest.svg'
import practiceActiveIcon from '../assets/practiceactive.svg'
import communityActiveIcon from '../assets/communityactive.svg'
import contestActiveIcon from '../assets/contestactive.svg'
import homeActive from '../assets/homeactive.svg'
import home from '../assets/home.svg'

const Item = ({ index, title, active, icon, activeIcon, setActive }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [isHovering, setIsHovering] = useState(false)
    const [carouselIndex, setCarouselIndex] = useState(active ? 1 : 0)
    useEffect(() => {
        setCarouselIndex(active ? 1 : 0)
    }, [active])
    const handleOnMouseEnter = () => {
        setIsHovering(true)
        if (carouselIndex !== 1) setCarouselIndex(1)
    }
    const handleOnMouseLeave = () => {
        setIsHovering(false)
        if (!active) setCarouselIndex(0)
    }

    return (
        <div>
            <div onClick={() => {
                // setActive(index);
                navigate('/' + title.toLowerCase())
            }
            } onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} className={"bottom-btn-cnt d-flex flex-column align-items-center" + ((isHovering || active) ? " navbar-btn-hover" : "")}>
                <img className='navbar-icon' src={(location.pathname.split('/')[1] === (title.toLowerCase())) ? activeIcon : icon} alt="" />
                <div className={'navbar-btn-title' + ((isHovering || (location.pathname.split('/')[1] === title.toLowerCase())) ? " text-1" : "")}>
                    {title}
                </div>
            </div>
        </div>
    )
}

export default function BottomBar({ active, setActive }) {
    const navigate = useNavigate();
    const location = useLocation()
    const data = [
        {
            title: "Community",
            icon: communityIcon,
            activeIcon: communityActiveIcon
        },
        {
            title: "Practice",
            icon: practiceIcon,
            activeIcon: practiceActiveIcon
        },
        {
            title: "Contest",
            icon: contestIcon,
            activeIcon: contestActiveIcon
        },
    ]
    return (
        <div className='d-block border-top pt-2 d-sm-none bg-white' style={{ position: "fixed", bottom: 0, zIndex: '13', width:'100%' }}>
            <div className="d-flex align-items-center justify-content-around">
                <div className='bottom-btn-cnt d-flex flex-column align-items-center' onClick={() => {
                    navigate('/')
                }}>
                    <img style={{
                        height: '20px',
                        objectFit: "contain",
                        alignSelf: "center",
                        marginBottom: "6px",

                    }} src={location.pathname === '/' ? homeActive : home} alt="" />
                    <div className={'navbar-btn-title' + (location.pathname === '/' ? " text-1" : "")}>
                        Home
                    </div>
                </div>
                {data.map((item, index) => {
                    return <Item key={index} index={index} title={item.title} setActive={setActive} active={active === index} icon={item.icon} activeIcon={item.activeIcon} />
                })}
            </div>
        </div>
    )
}
