import React from 'react';
import icon1 from './About_assets/icon1.png';
import icon2 from './About_assets/icon2.png';
import icon3 from './About_assets/icon3.png';
import icon4 from './About_assets/icon4.png';

const Solution = () => {
    const cardContent = [
        { icon: icon3, title: 'Community', color: true },
        { icon: icon4, title: 'Review/Rate', color: false },
        { icon: icon1, title: 'Practice', color: false },
        { icon: icon2, title: 'Compete', color: true },
    ]
    return (
        <div className='my-5 d-flex flex-column gap-3 align-items-start col-md-10 col-11 mx-auto'>
            <div className='font-type-2 color-primary text-center text-lg-start col-12'>
                OUR SOLUTIONS
            </div>
            <div className='fw-bold font-type-1 text-center text-lg-start col-12' style={{ color: 'var(--gray-600)' }}>
                Solution that
                <span className='color-primary'>
                    {" "}works
                </span>
            </div>
            <div className='d-flex flex-wrap justify-content-center col-12 my-5'>
                {
                    cardContent.map((content, index) =>
                        <div className={`col-lg-6 col-12 ${index % 2 === 0 ? 'pe-lg-4' : 'ps-lg-4'} ${index < 2 ? 'pb-lg-4' : 'pt-lg-4'} ${index !== 4 && 'pb-4 pb-md-5'} px-0`}>
                            <div className={`about-card-shadow rounded-3 p-5 text-left d-flex flex-column gap-2`} style={{ background: `${content.color ? 'var(--primary)' : 'white'}`, color: `${content.color ? 'white' : 'var(--primary)'}` }} key={index}>
                                <img src={content.icon} style={{ width: '100px' }} alt='' />
                                <div className='font2 fw-bold'>
                                    {content.title}
                                </div>
                                <div className=''>
                                    Practice thousands of free tests with detailed solutions & performance analysis.
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Solution
