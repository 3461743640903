import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux'
import store from './store'
import App from './App';
import "./index.css"
import './styles/loader.css'


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <Provider store={store}>
        <App />
        {/* <RouterProvider router={router}/> */}
    </Provider>
)
