import React from 'react'
import { Link } from 'react-router-dom'

const SingleBanner = ({ bg, color, img, title, detail, overflow }) => {
    
    return (
        <div className='p-5 position-relative rounded-4 paddingC' aria-label={title} style={{ background: bg, color, border: '8px solid rgb(228 228 228 / 20%)', overflow: `${overflow ? 'hidden' : 'visible'}` }}>
            <div className='my-5 d-flex flex-column gap-3 position-relative' style={{ zIndex: '2' }}>
                <h2 className='font4 fw-medium'>{title}</h2>
                <p className='font2' style={{ maxWidth: '700px' }}>{detail}</p>
                <div className='my-4'>
                    <Link aria-label={title} to={`/${title === 'Compete' ? 'contest' : title.toLowerCase()}`} className='rounded-2 p-2 px-3 fw-bolder font1half linkFoot' style={{ background: color, color: 'white', outline: 'none', border: '0', cursor: 'pointer' }}>{title}</Link>
                </div>
            </div>
            <div className='position-absolute' style={{ bottom: `${overflow ? '0rem' : '-1.5rem'}`, right: `${overflow ? '0rem' : '-1.5rem'}`, zIndex: '1' }}>
                <img src={img} className='' style={{ width: '25vw', minWidth: `200px` }} alt={title} />
            </div>
        </div>
    )
}

export default SingleBanner
