import React, { useEffect, useState } from 'react'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
// import './custom.css';

const SessionCard2 = ({ data }) => {
    console.log(data);
    const [value, setValue] = useState(0);
    const levels = [
        {
            title: 'Easy',
            color: '#3D944B',
            marks: data?.userEasyQuestions,
            total: data?.noOfEasyQuestions
        },
        {
            title: 'Medium',
            color: '#FFC107',
            marks: data?.userMediumQuestions,
            total: data?.noOfMediumQuestions
        },
        {
            title: 'Hard',
            color: '#E36963',
            marks: data?.userHardQuestions,
            total: data?.noOfHardQuestions
        }
    ]

    useEffect(() => {
        if (data) {
            const total = data?.noOfEasyQuestions + data?.noOfMediumQuestions + data?.noOfHardQuestions;
            const marks = data?.userEasyQuestions + data?.userHardQuestions + data?.userMediumQuestions;
            setValue(() => (marks * 100) / total);
        }
    }, [data]);

    return (
        <div
            className='rounded-4 px-4 py-3'
            style={{
                background: "#5865F2"
            }}
        >
            <div className='fw-5 font-type-8'>
                Sessions
            </div>
            <div className='d-flex justify-content-between align-items-center gap-5 mt-1 mt-md-3'>
                <div className='d-flex flex-column justify-content-between gap-2 font-type-9'>
                    {
                        levels.map((item, index) =>
                            <div className='d-flex justify-content-between gap-3' key={index}>
                                <div className='' style={{ color: item?.color }}>
                                    {item?.title}
                                </div>
                                <div className=''>
                                    <strong>{item?.marks}</strong>/{item?.total}
                                </div>
                            </div>
                        )
                    }
                </div>
                <div style={{
                    maxWidth: '100px',
                    minWidth: '85px'
                }}>
                    <CircularProgressbarWithChildren value={value} styles={buildStyles({
                        rotation: 0.25,
                        textSize: '16px',
                        pathTransitionDuration: 0.5,
                        pathColor: `#5764ED`,
                        textColor: '#5764ED',
                        trailColor: '#CDD2FF',
                    })}>
                        <div style={{ fontSize: 12 }}>
                            All
                        </div>
                        <div className=''>
                            <strong>
                                {data?.userEasyQuestions + data?.userHardQuestions + data?.userMediumQuestions}
                            </strong>
                        </div>
                        <div style={{ border: '1px solid #E6E6E6', width: `70%` }}></div>
                        <div className=''>
                            {data?.noOfEasyQuestions + data?.noOfMediumQuestions + data?.noOfHardQuestions}
                        </div>
                    </CircularProgressbarWithChildren>
                </div>
            </div>
        </div>
    )
}

export default SessionCard2
