import React from 'react'

const ProfileRankCard = ({ data, viewEdit }) => {

    return (
        <div className='rounded-3 d-flex flex-column gap-1 p-2 py-3 justify-content-center' style={{ border: '3px solid var(--gray-200)', width: '100%', color: 'var(--gray-700)' }}>
            <div className='font-100'>
                {
                    (data?.title === 'Your Rating' && !viewEdit) ?
                        'Rating'
                        :
                        data.title
                }
            </div>
            <div className='font-200 fw-6'>
                {data?.value}
            </div>
        </div>
    )
}

export default ProfileRankCard