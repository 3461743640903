import React from 'react'
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap'

const DropDown = ({ title, options, filter, setFilter }) => {
    const [selectedValue, setSelectedValue] = useState(null);

    return (
        <Dropdown onSelect={(eventKey) => { console.log((JSON.parse(eventKey))?.value); setSelectedValue(eventKey); setFilter((JSON.parse(eventKey))?.value) }}>
            <Dropdown.Toggle className='font-100' style={{ width: '100%', background: 'var(--black)', color: 'white', border: '1px solid var(--gray-200)', display: 'flex', justifyContent: 'space-between', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis' }} id="dropdown-basic">
                {
                    selectedValue !== null ?
                        JSON.parse(selectedValue)?.title
                        :
                        title
                }
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ background: 'rgba(0,0,0,0.7)', backdropFilter: 'blur(5px)', color: 'white', border: '1px solid var(--gray-700)' }}>
                {
                    options?.map((option, index) =>
                        <Dropdown.Item className='font-100' style={{ color: 'white' }} key={index} eventKey={JSON.stringify(option)}>{option?.title}</Dropdown.Item>
                    )
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default DropDown
