import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { API_URL } from '../../config'
import { Carousel, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
import { Link } from 'react-router-dom';
import { setLoading } from '../../reducers/app';
import { useDispatch } from 'react-redux';
import CarouselHome from './CarouselHome';

const BannerCarousel = () => {

    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    const getBanners = async () => {
        try {
            const res = await axios.get(API_URL + "/banner?ASCbyCreationDate=true&isVisible=true");
            console.log(res);
            console.log("API URL===========", res.data);
            setData(res?.data)
            dispatch(setLoading(false))
        } catch (e) {
            dispatch(setLoading(false))
            console.log(e.message);
        }
    }

    useEffect(() => {
        getBanners();
    }, [])

    return (
        <div className="mb-3 pt-3 banner-carousel-container bannerC mx-auto">
            <Carousel indicators prevIcon={null} nextIcon={null} className='bannerCarouselIndicators'>
                {
                    data?.length > 0 ?
                        data?.map((slide, index) => (
                            <Carousel.Item key={index}>
                                <CarouselHome slide={slide} />
                            </Carousel.Item>
                        ))
                        :
                        <div className='w-100 bg-gray-1 rounded-5 borderCH py-5 bannerCH'>
                        </div>
                }
            </Carousel>
            <Row className="carousel-indicators-container">
                <Col xs={12}>
                    <ol className="carousel-indicators">
                        {data?.map((slide, index) => (
                            <li
                                key={index}
                                data-target="#banner-carousel"
                                data-slide-to={index}
                                className={index === 0 ? 'active' : ''}
                            ></li>
                        ))}
                    </ol>
                </Col>
            </Row>
        </div>
    )
}


export default BannerCarousel