import React from 'react';
import { Line, Chart } from 'react-chartjs-2';

const RatingGraph = ({ ratings, contests }) => {
    // Sample data and background colors
    const student = 'Student 1';
    const contestData = [1600, 3500, 2600, 2200, 2500];
    const contestLabels = ['Contest 1', 'Contest 2', 'Contest 3', 'Contest 4'];
    const backgrounds = [
        { color: '#8319F5', from: 0, to: 600 },
        { color: '#04B8C1', from: 600, to: 1200 },
        { color: '#0CD4AD', from: 1200, to: 1800 },
        { color: '#FD7513', from: 1800, to: 2400 },
        { color: '#FDD913', from: 2400, to: 3000 },
        { color: '#FF0558', from: 3000, to: 3600 },
    ];

    const canvaBg = 'linear-gradient(to bottom, #FF0558 0% 17.36%, #FDD913 17.36% 33.7%, #FD7513 33.7% 50%, #0CD4AD 50% 66.6%, #04B8C1 66.6% 82.6%, #8319F5 82.6% 100%)';

    // Create datasets for line plot
    const datasets = [
        {
            label: 'Rating',
            data: ratings,
            borderColor: 'white',
            backgroundColor: 'transparent',
            pointBackgroundColor: 'white',
            pointRadius: 4,
            fill: false,
        },
    ];

    // Create chart data
    const data = {
        labels: contests,
        datasets: datasets,
    };

    // Customize chart options
    const options = {
        scales: {
            // scaleOverride : true,
            x: {
                display: false,
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Contests',
                    color: 'white',
                },
                ticks: {
                    color: 'white',
                },
                borderRadius: 16
            },
            y: {
                display: false,
                position: 'center',
                grid: {
                    display: false,
                },
                title: {
                    display: false,
                    text: 'Rating',
                    color: 'white',
                },
                ticks: {
                    display: false,
                    color: 'white',
                    stepSize: 600,
                    callback: (value) => value.toFixed(0),
                },
                min: 0,
                max: 3600,
            },
        },
        plugins: {
            legend: {
                display: false,
                position: 'top',
                labels: {
                    color: 'white',
                },
            },
            roundedBorders: {
                borderRadius: 16,
            },
        },
        elements: {
            line: {
                tension: 0,
            },
        },
        layout: {
            padding: 0,
        },
    };

    // Add background color plugin
    const backgroundPlugin = {
        id: 'backgroundPlugin',
        beforeDraw: (chart) => {
            const ctx = chart.ctx;
            const chartArea = chart.chartArea;

            backgrounds.forEach((bg, index) => {
                const from = chart.scales.y.getPixelForValue(bg.from);
                const to = chart.scales.y.getPixelForValue(bg.to);

                ctx.fillStyle = bg.color;
                ctx.fillRect(chartArea.left, to, chartArea.right - chartArea.left, from - to);

                // Write the rating labels inside the graph
                const rating = bg.to;
                ctx.font = '16px Arial';
                ctx.fillStyle = 'white';
                ctx.textAlign = 'center';
                ctx.fillText(rating.toString(), chartArea.right - 30, (from + to + 16) / 2);
            });
        },
    };

    return (
        <div style={{ margin: 0, padding: 0 }}>
            <Line data={data} options={options} plugins={[backgroundPlugin]} style={{ maxWidth: '900px', maxHeight: '500px', background: canvaBg, borderRadius: '1rem' }} />
        </div>
    );
};

export default RatingGraph;
