import React from 'react'

const Card1 = ({ title, type, type2, content, color }) => {
  return (
    <div className='shadow-sm p-4 d-flex flex-column gap-2 rounded-1' style={{ color: 'var(--gray-500)', border:'1px solid var(--gray-600)' }}>
      <div className='font-120 text-uppercase'>{title}</div>
      <div className='font-120'><span className='font-200 fw-bolder' style={{ color }}>{type}</span>{type2 && '/' + type2} {content}</div>
    </div>
  )
}

export default Card1
