import React from 'react';
import ac from '../ContestLists/contestLists_assets/ac.png';
import wr from '../ContestLists/contestLists_assets/wr.png';
import pr from '../ContestLists/contestLists_assets/pr.png';
import soln1 from '../ContestLists/contestLists_assets/accepted.png';
import soln2 from '../ContestLists/contestLists_assets/rejected.png';
import { useNavigate, useParams } from 'react-router-dom';
import { filterQuery } from '../ContestLists/ContestListsData';

const QuestionListCard2 = ({ data, no, activePage, questionStatus, questionType }) => {

    const { subjectId, topicId } = useParams();
    const navigate = useNavigate();

    const handleNavigate = () => {
        const query = filterQuery('', '', '', questionType, questionStatus);
        navigate(`/app/practice/${subjectId}/${topicId}/${data?.id}?question=${no}&page=${activePage + 1}${query}`)
    }

    return (
        <div className='cursor' onClick={handleNavigate} style={{color:'var(--gray-800)'}}>
            <div className='px-2 px-md-4 py-2 py-md-4' style={{ background: data?.bg1 }}>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <div className='font-type-6 rounded-pill px-3 py-1' style={{ background: data?.bg2 }}>
                        Question No {no}
                    </div>
                    <div className=''>
                        {
                            data?.questionStatus !== 'NA' &&
                            <img src={getIcon(data?.questionStatus)} alt='status' />
                        }
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: data?.question }}>
                </div>
            </div>
            <div className='d-flex justify-content-between px-1 px-md-3 py-2 font-type-5' style={{ background: data?.bg2 }}>
                <div className=''>
                    Acceptance: {data?.acceptance}
                </div>
                <div>
                    Difficulty: <span className='fw-5' style={{color: data?.color}}>{data?.difficulty}</span>
                </div>
                <div className=''>
                    Solution: <img className='d-inline ms-1' src={getSolutionIcon(data?.solution)} alt='solution' />
                </div>
            </div>
        </div>
    )
}

export default QuestionListCard2;

const getIcon = (type) => {
    if (type === "AC") {
        return ac;
    } else if (type === 'PR') {
        return pr;
    } else if (type === 'WR') {
        return wr;
    }
}

const getSolutionIcon = (solution) => {
    if (solution) {
        return soln1;
    } else {
        return soln2;
    }
}