export function formatMilliseconds(milliseconds) {
    const seconds = Math.floor(milliseconds / 1000);
    return `${seconds}s`;
}

export const checkQuestionStatus = (question) => {
    if (question === 'AC')
        return 'var(--green-100)';
    else if (question === 'NA')
        return 'var(--gray-300)';
    else if (question === 'WR')
        return 'var(--red-100)';
    return 'var(--yellow-100)';
}

export const filterOptions = [
    {
        type: 'questionStatusList',
        title: 'Status',
        options: [
            { title: 'Status', value: '' },
            { title: 'Answered', value: 'AC' },
            { title: 'Unattempted', value: 'NA' },
            { title: 'Wrong Answer', value: 'WR' },
            { title: 'Partially Correct', value: 'PR' }
        ]
    },
    {
        type: 'questionSubjectList',
        title: 'Subject',
    },
    {
        type: 'type',
        title: 'Types',
        options: [
            { title: 'Types', value: '' },
            { title: 'Single Correct', value: 'SINGLE_ANSWER' },
            { title: 'Multiple Correct', value: 'MULTIPLE_ANSWER' },
            { title: 'Integer', value: 'INTEGER' }
        ]
    }
];

export const getOptions = (data) => {
    const optionData = data?.map((sub) => {
        return { title: sub?.name, value: sub?.id }
    })
    return [{ title: 'Subject', value: '' }, ...optionData];
}