import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import TestContext from '../context/testContext';
import axios from 'axios';
import { API_URL } from '../config';

export default function VirtualTestPopUp({ text, type, handleStart, handleCancel }) {
    const navigate = useNavigate();
    const context = useContext(TestContext);
    const jwtToken = useSelector((state) => state.user.jwtToken)

    const onClickHandler = async () => {
        try {

            if (type === "endContest" || type === "confirmEndContest") {
                context.endContest();
            } else {
                handleStart();
            }

        } catch (e) {
            console.log(e)
        }

    }

    return (
        <div id='contest-ended-outer-cnt' style={{ background: 'var(--gray-800)' }}>
            <div id="contest-ended-inner-cnt" className='px-5 py-3 d-flex flex-column align-items-center' style={{ background: 'var(--gray-900)', color: 'white' }}>
                <div id="contest-ended-text" className='fw-sb text-center mb-3'>
                    {text}
                </div>
                <div className='d-flex gap-3 justify-content-center' style={{ width: '100%' }}>
                    {type === "confirmEndContest" && <button onClick={() => context.setShowContestEndedPopUp(false)} className='btn text-1 fs-xsm fw-sb py-2 text-hover-1 border-color-1 px-4 my-2' style={{ width: '50%', minWidth: '120px' }}>Resume</button>}
                    {type === "startContest" && <button onClick={handleCancel} className='btn text-1 fs-xsm fw-sb py-2 text-hover-1 border-color-1 px-4 my-2' style={{ width: '50%', minWidth: '120px' }}>Cancel</button>}
                    {
                        type === 'startContest' ?
                            <button onClick={onClickHandler} className='btn bg-1 text-white fs-xsm fw-sb py-2 text-hover-1 border-hover-1 px-4 my-2' style={{ width: '50%', minWidth: '120px' }}>Start Test</button>
                            :
                            <button onClick={onClickHandler} className='btn bg-1 text-white fs-xsm fw-sb py-2 text-hover-1 border-hover-1 px-4 my-2' style={{ width: '50%', minWidth: '120px' }}>End Test</button>
                    }
                </div>
            </div>
        </div>
    )
}
