import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const TimeVsSubjectGraph = ({data}) => {
    const formatTime = (timeInMs) => {
        if (timeInMs < 1000) {
            return `${timeInMs}ms`;
        } else if (timeInMs < 60000) {
            const seconds = Math.floor(timeInMs / 1000);
            return `${seconds}s`;
        } else if (timeInMs < 3600000) {
            const minutes = Math.floor(timeInMs / 60000);
            const seconds = Math.floor((timeInMs % 60000) / 1000);
            return `${minutes}m ${seconds}s`;
        } else {
            const hours = Math.floor(timeInMs / 3600000);
            const minutes = Math.floor((timeInMs % 3600000) / 60000);
            const seconds = Math.floor((timeInMs % 60000) / 1000);
            return `${hours}h ${minutes}m ${seconds}s`;
        }
    };
    // const data = {
    //     labels: ['Maths', 'Science', 'English'],
    //     yourTime: [79000, 8000, 23000],
    //     avgTime: [83000, 20000, 20000]
    // }
    const chartData = {
        labels: data.labels,
        datasets: [
            {
                label: "Your Time",
                data: data.yourTime,
                fill: false,
                borderColor: "#FF6384",
            },
            {
                label: "Average Time",
                data: data.avgTime,
                fill: false,
                borderColor: "#36A2EB",
            },
        ],
    };

    const options = {
        scales: {
            xAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: "Subject",
                    },
                },
            ],
            yAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: "Time",
                    },
                    ticks: {
                        beginAtZero: true,
                        callback: (value) => formatTime(value),
                    },
                },
            ],
        },
        tooltips: {
            callbacks: {
                label: (tooltipItem, data) => {
                    const value = tooltipItem.yLabel;
                    return formatTime(value);
                },
            },
        },
        legend: {
            display: true,
            position: "bottom",
        },
    };

    return <Line data={chartData} options={options} />;
};

export default TimeVsSubjectGraph
