import React from 'react'
import IconValue from '../Icons/IconValue'
import { MdShareLocation } from 'react-icons/md';
import { FcCalendar } from 'react-icons/fc';
import { WiTime4 } from 'react-icons/wi'
import { FaRegCalendarAlt } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const VirtualList = ({ background, data, number }) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',

    };

    const timeOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    }

    const formattedDate = new Date(data?.creationDate).toLocaleString('en-US', options);
    const formattedTime = new Date(data?.creationDate).toLocaleString('en-US', timeOptions);

    const { id } = useParams();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.user);

    const handleClick = () => {
        if (data?.registerStatus === 'REGISTERED') {
            navigate(`/contest/virtual/start/${id}/${data?.id}`)
        }
        else {
            navigate(`/contest/result/${user?.userId}/${id}/${data?.id}`)
        }
    }

    return (
        <div className='d-flex flex-column gap-2 p-3 font-100' style={{ background }}>
            <div className='font-90' style={{ color: 'var(--gray-600)' }}>
                Rank: {data?.contestRanking}
            </div>
            <div className='d-flex flex-column gap-1'>
                <div className='font-150 fw-6'>
                    Virtual Contest Attempt {number}
                </div>
                <div className='d-flex align-items-center gap-5'>
                    <IconValue icon={<FaRegCalendarAlt />} value={formattedDate} />
                    <IconValue icon={<WiTime4 />} value={formattedTime} />
                </div>
            </div>
            <div className='d-flex justify-content-end'>
                <div onClick={handleClick} className='rounded-2 px-3 font-100 fw-5 py-2 cursor' style={{ background: 'var(--primary)', color: 'white' }}>
                    {
                        data?.registerStatus === 'REGISTERED' ?
                            'Resume'
                            :
                            'Result'
                    }
                </div>
            </div>
        </div>
    )
}

export default VirtualList
