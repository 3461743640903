import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { API_URL } from '../config'
import Navbar from '../components/Navbar'
import Carousel from '../components/Carousel'
import { setCategories } from '../reducers/contest'
import { setJwtToken, setUser } from '../reducers/user'
import Contest from '../components/Contest'
import ComingSoon from '../components/ComingSoon'
import { useCookies } from 'react-cookie'
import BottomBar from '../components/BottomBar'
import Loader from '../components/Loader'
import { setLoading } from '../reducers/app'
import BannerCarousel from '../components/Home/BannerCarousel'
import BannerNormal from '../components/Home/BannerNormal'
import JoinCommunitySection from '../components/Home/JoinCommunity/JoinCommunitySection';
import '../components/Home/Home.css';
import Footer from '../components/Footer/Footer'
import MainText from '../components/Home/MainText'
import NumberSection from '../components/Home/NumberSection/NumberSection'
import QuestionSection from '../components/Home/QuestionSection/QuestionSection';
import bg from '../components/Home/home_assets/background.jpg';

export default function Home() {
  const dispatch = useDispatch()
  const [showLoginCanvas, setShowLoginCanvas] = useState(false)
  const [active, setActive] = useState(-1);
  const jwtToken = useSelector((state) => state.user.jwtToken)
  const { loading } = useSelector((state) => state.app)
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Unbind: India's Largest Student Community`;
    document.querySelector('meta[name="description"]').setAttribute('content', `Empower Your Learning Journey with Unbind: Practice, Compete, Connect, and Express! 🚀 Join the Community, Make Friends, and Find Mentors. Unleash Your Potential at Unbind!`);

  }, []);

  return (
    <div className='d-flex flex-column justify-content-between' style={{ minHeight: '100vh' }}>
      {loading && <Loader />}
      <div>
        <Navbar showButtons={true} active={active} setActive={setActive} showLoginCanvas={showLoginCanvas} setShowLoginCanvas={setShowLoginCanvas} />

        <div className='' style={{ background: `linear-gradient(180deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.7)), url(${bg})` }}>
          {/* <Contest showLoginCanvas={showLoginCanvas} setShowLoginCanvas={setShowLoginCanvas} type="ongoing" />
          <Contest type="past" /> */}
          <BannerCarousel />
          <MainText />
          <BannerNormal />
          <NumberSection />
          <QuestionSection />
          <JoinCommunitySection />
        </div>
        <Footer />
      </div>
      <BottomBar active={active} setActive={setActive} />
    </div>
  )
}
