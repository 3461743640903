import React from 'react';
import { FaAngleDoubleDown, FaAngleDoubleUp } from 'react-icons/fa'
import VirtualList from './VirtualList';
import { useState } from 'react';
import Pagination from '../Paginations/Pagination';

const VirtualLists = ({ data, activePage, setActivePage, totalPages, number, totalElements }) => {
    const bgColors = ['#E9FBE9', '#FFF7E3', '#E1E5FE'];
    // const [viewLists, setViewLists] = useState(false);
    return (
        <div className='d-flex flex-column rounded-3' style={{ overflow: 'hidden' }}>
            <div
                className='d-flex gap-2 justify-content-center p-2 font-150 fw-5'
                style={{ background: 'var(--primary)', color: 'white' }}
            >
                <div className=''>
                    List of Virtual Contest
                </div>
            </div>
            {/* ------------------------------------------------------------------- */}
            {/* virtual lists start */}
            {/* ------------------------------------------------------------------- */}
            <div className='d-flex flex-column p-3 py-4 gap-4' style={{ background: 'var(--gray-900)' }}>
                {
                    data?.length > 0 ?
                        data?.map((d, index) =>
                            <VirtualList background={bgColors[index]} number={totalElements - (number + index)} data={d} key={index} />
                        )
                        :
                        'No more data are available'
                }
                {/* <VirtualList background={bgColors[0]} />
                    <VirtualList background={bgColors[1]} />
                    <VirtualList background={bgColors[2]} /> */}
                {
                    totalPages > activePage &&
                    <Pagination totalPages={totalPages} activePage={activePage} setActivePage={setActivePage} />
                }
            </div>
            {/* ------------------------------------------------------------------- */}
            {/* virtual lists end */}
            {/* ------------------------------------------------------------------- */}
        </div>
    )
}

export default VirtualLists
