
export const checkStatus = (start_time, duration) => {
    let test_start_time = (new Date(start_time))
    let test_end_time = (new Date(start_time))
    test_end_time.setMinutes(test_start_time.getMinutes() + duration)
    let current_date = new Date();
    var currentOffset = current_date.getTimezoneOffset();
    var ISTOffset = 330;   // IST offset UTC +5:30 
    current_date = new Date(current_date.getTime() + (ISTOffset + currentOffset) * 60000);
    if (current_date > test_start_time && current_date < test_end_time) return true;
    return false;
};

export const userTableStructure = [
    {
        title: 'Rank',
        value: 'rank'
    },
    {
        title: 'Name',
        value: 'name'
    },
    {
        title: 'Rating',
        value: 'rating'
    }
];