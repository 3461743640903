import React from 'react'
import { useEffect } from 'react'
import Main from '../components/Community/Main'

const CommunityV2 = () => {

    useEffect(() => {
        document.title = `Boost your NEET preparation with AI powered practice and planner`;
        document.querySelector('meta[name="description"]').setAttribute('content', `Boost your NEET preparation with AI powered practice and planner with Unprep`);
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='d-flex flex-column justify-content-between' style={{ minHeight: '100vh' }}>
            <div style={{ flexGrow: '1' }}>
                <Main />
            </div>
        </div>
    )
}

export default CommunityV2