import React from 'react'
import ResultTop from '../Top_components/ResultTop'
import Card1 from '../Card_components/Card1';
import './Result.css';
import ScoreCard from '../Card_components/ScoreCard';
import Chart from './Chart';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { cardData, convertMillisecondsToHMS, getChartData, getChartData2, getSubjectAvgMarks } from './ResultsData';
import { API_URL } from '../../config';
import Loading from '../Loading/Loading';
import TimeVsSubjectGraph from './Chart2';
import { header } from '../HeaderData';
import { useSelector } from 'react-redux';

const Main = () => {
    const [avgData, setAvgData] = useState(null);
    const [resultData, setResultData] = useState(null);
    const [contestData, setContestData] = useState(null);
    const { id, userId, virtualId } = useParams();
    const { jwtToken } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(false);

    // console.log(id, userId, virtualId)

    const getData = async () => {
        setLoading(true);
        const avgDataRes = await axios.get(`${API_URL}/subjectWiseAverageResult?contestId=${id}`);
        const virtual = virtualId ? virtualId : '';
        const resultDataRes = await axios.get(`${API_URL}/studentContestResult/${userId + id + virtual}`);
        const contestUrl = !virtualId ? `contest/${id}` : `registerForContest/${virtualId}`;
        const contestDataRes = await axios.get(`${API_URL}/${contestUrl}`, header(jwtToken));
        setAvgData(avgDataRes?.data);
        setResultData(resultDataRes?.data);
        // console.log('result data================================================>', resultDataRes);
        console.log('result data================================================>', contestDataRes);
        setContestData(contestDataRes?.data);
        setLoading(false);
    }
    // console.log('avg data====================================================>', avgData);
    // console.log('result data================================================>', resultData);
    // console.log('contest data================================================>', contestData);

    useEffect(() => {
        if (userId && id && jwtToken)
            getData();

        return () => {
            window.scrollTo(0, 0)
        }
    }, [jwtToken]);

    return (
        <>
            {
                (loading || !avgData || !resultData) ?
                    <div className='col-md-10 col-11 mx-auto my-5 d-flex flex-column gap-5 font-100'>
                        <Loading height={'2rem'} classes={'col-5'} />
                        <Loading height={'2rem'} classes={'col-9'} />
                        <Loading height={'2rem'} classes={'col-8'} />
                        <Loading height={'2rem'} classes={'col-4'} />
                        <div className='overview_cards col-lg-10 col-12'>
                            {
                                [1, 2, 3, 4, 5, 6].map((title) =>
                                    <Loading classes={'col-12'} height={'6rem'} key={title} />
                                )
                            }
                        </div>
                    </div>
                    :
                    <div className='col-md-10 col-11 mx-auto my-5 d-flex flex-column gap-3 font-100'>

                        {/* Top Section Start */}
                        <ResultTop
                            title={'Test Result'}
                            date={(new Date(virtualId ? contestData?.creationDate : contestData?.start_time)).toDateString("en-IN", { timeZone: "Asia/Kolkata" }) + " "}
                            name={virtualId ? contestData?.contest?.name : contestData?.name}
                        />
                        {/* Top Section End */}

                        <div className='font-120'>
                            Summary of the marks scored in the test attempted on {(new Date(virtualId ? contestData?.creationDate : contestData?.start_time)).toDateString("en-IN", { timeZone: "Asia/Kolkata" }) + " "}.
                        </div>

                        <div className=''>
                            <Link to={`/contest/solutions/${userId}/${id}${virtualId ? `/${virtualId}` : ''}`} className='px-3 py-2 rounded-1 font-120' style={{ background: '#24B082', outline: 'none', border: '0', color: 'white' }}>View Solution</Link>
                        </div>

                        {/* Overview section Start  */}
                        <div className='d-flex flex-column gap-3 my-4'>
                            <div className='font-120' style={{ color: 'var(--gray-700)', fontWeight: '600' }}>Overview</div>
                            <div className='overview_cards col-lg-10 col-12'>
                                {
                                    cardData.map((data, index) =>
                                        <Card1
                                            key={index}
                                            title={data.title}
                                            type={data.type === 'totalTimeTaken' ? convertMillisecondsToHMS(resultData[data.type]) : resultData[data.type]}
                                            content={data.content}
                                            type2={resultData[data.type2]}
                                            color={data.color}
                                        />
                                    )
                                }
                            </div>
                        </div>
                        {/* Overview Section End  */}

                        <hr />

                        {/* Subject Section Start  */}
                        <div className='d-flex flex-column gap-3 my-4'>
                            <div className='font-120' style={{ fontWeight: '600', color: 'var(--gray-700)' }}>Subject Wise</div>
                            <div className='font-120'>
                                Complete analysis of your paper section wise
                            </div>
                            <div className='d-flex flex-column gap-5 my-4'>
                                {
                                    resultData?.studentSubjectContestResults?.map((sub, index) =>
                                        <ScoreCard
                                            data={sub}
                                            key={index}
                                            avgData={getSubjectAvgMarks(sub, avgData)}
                                        />
                                    )
                                }
                            </div>
                        </div>
                        {/* Subject Section End  */}

                        {/* Chart Section Start  */}
                        <div className='d-flex flex-column gap-3 my-4 shadow-sm col-lg-6 col-12 p-lg-5 px-3 py-4 rounded-2'>
                            <div className='font-100 rounded-5 px-3 py-1' style={{ fontWeight: '500', background: 'var(--gray-200)', color: 'var(--gray-700)', width: 'fit-content' }}>Time(In Min)</div>
                            <div className='' style={{ width: '100%', overflowX: 'auto' }}>
                                {/* <Chart data={getChartData(resultData, avgData)} /> */}
                                <TimeVsSubjectGraph data={getChartData2(resultData, avgData)} />
                            </div>
                        </div>
                        {/* Chart Section End  */}

                    </div>
            }
        </>
    )
}

export default Main;
