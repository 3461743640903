import React, { useEffect, useState } from 'react'
import QuestionList from './QuestionList'
import QuestionView from './QuestionView';
import './PracticeQuestion.css';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { API_URL } from '../../config';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Pagination from '../Paginations/Pagination';
import { filterQuery, findNextQuesId, findPrevQuesId } from './PracticeQuestionData';
import Loading from '../Loading/Loading';
import TabSection from './TabSection';
import { ToastContainer } from 'react-toastify';
import { header } from '../HeaderData';

const Main = () => {
    const location = useLocation();

    const [viewTab, setViewTab] = useState(1);
    const [loading, setLoading] = useState(false);
    const [loadingList, setLoadingList] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [questionList, setQuestionList] = useState(null);
    const [data, setData] = useState(null);
    const [solution, setSolution] = useState(null);

    const { jwtToken } = useSelector((state) => state.user);
    const { user } = useSelector((state) => state.user);

    const selectedCategory = useSelector((state) => state.user.defaultCategory);

    const { subjectId, topicId, questionId } = useParams();

    const navigate = useNavigate();

    // from url 
    const [activePage, setActivePage] = useState(Number(new URLSearchParams(location.search).get('page')) - 1);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [bookmarked, setBookmarked] = useState(null);
    const [questionNumber, setQuestionNumber] = useState(Number(new URLSearchParams(location.search).get('question')));
    const pageSize = 10;

    const getData = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${API_URL}/studentPracticeResponse/${questionId}?userId=${user?.userId}`, header(jwtToken));
            const res2 = await axios.get(`${API_URL}/question/${questionId}`, header(jwtToken));
            console.log('solution data ====================>', res);
            console.log('solution data2==========================>', res2);
            setSolution({ ...res2?.data, previousAnswer: res?.data?.studentAnswer, questionStatus: res?.data?.questionStatus });
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    const getQuestionLists = async (no) => {
        setLoadingList(true);
        try {
            console.log(location.search)
            const query = filterQuery(location);

            const url = API_URL + `/questionPractice?contestCategoryId=${selectedCategory?.id}&page=${activePage}&size=${pageSize}&questionSubjectId=${subjectId}&questionTags=${topicId}${query}`;
            const res = await axios.get(url, header(jwtToken));
            // const res = await axios.get(`${API_URL}/questionPractice?page=${activePage}&contestCategoryId=${selectedCategory?.id}&size=${pageSize}${query}`, header(jwtToken));
            console.log('question lists ==========================>', res);
            setQuestionList(res?.data?.content);
            setTotalPages(res?.data?.totalPages);
            setTotalQuestions(res?.data?.totalElements);
            setData(res?.data?.content?.filter((ques, index) => index === (no) % 10)[0]);
            navigate(`/app/practice/${subjectId}/${topicId}/${res?.data?.content?.filter((ques, index) => index === (no) % 10)[0]?.id}?question=${no + 1}&page=${activePage + 1}${query}`)
            setLoadingList(false);
        } catch (e) {
            console.log(e);
        }
    }

    const getBookMarkedData = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${API_URL}/bookmarks/check/${questionId}`, { headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + jwtToken } });
            console.log('bookmarked data ==========================>', res);
            setBookmarked(res?.data !== "" ? res?.data : null);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        setQuestionNumber(Number(new URLSearchParams(location.search).get('question')))
        setViewTab(1);
        getBookMarkedData();

        return () => {
        }
    }, [jwtToken, questionId]);

    useEffect(() => {
        if (viewTab === 2 || viewTab === 3)
            getData();
        else
            setSolution(null);

    }, [viewTab])

    useEffect(() => {
        // change question number to first question of the active page if clicked on next or prev page 
        if (questionNumber <= activePage * pageSize || questionNumber > (activePage + 1) * pageSize) {
            setQuestionNumber(activePage * pageSize + 1);
            getQuestionLists(activePage * pageSize)
        }
        else
            getQuestionLists(questionNumber - 1);

        return () => {

        }
    }, [activePage, selectedCategory]);

    return (
        <div className='mt-5 mb-2 col-md-10 col-11 mx-auto font-100 d-flex flex-column justify-content-between gap-3' style={{ flexGrow: '1' }}>

            <div className='PracticeQuestionMain align-items-center mb-2 gap-3'>
                {/* -------------------------------------------------*/}
                {/* Back Section Start */}
                {/* -------------------------------------------------*/}
                <div className='d-block'>
                    <Link
                        to={`/practice/${subjectId}/${topicId}`}
                        className='p-2 rounded-1 cursor'
                        style={{ cursor: 'pointer', color: 'white', background: 'var(--green-100)', cursor: 'pointer', whiteSpace: 'nowrap' }}
                    >Go To Practice Page</Link>
                </div>
                {/* -------------------------------------------------*/}
                {/* Back Section End */}
                {/* -------------------------------------------------*/}

                {/* -------------------------------------------------*/}
                {/* Tab Section Start */}
                {/* -------------------------------------------------*/}
                <div className='d-block' style={{ width: '100%', overflowX: 'auto' }}>
                    <TabSection viewTab={viewTab} setViewTab={setViewTab} />
                </div>
                {/* -------------------------------------------------*/}
                {/* Tab Section End */}
                {/* -------------------------------------------------*/}
            </div>

            <div className='PracticeQuestionMain' style={{ flexGrow: '1' }}>
                {/* -------------------------------------------------*/}
                {/* Questions List Section Start */}
                {/* -------------------------------------------------*/}
                {
                    (loadingList || questionList === null) ?
                        <div className='d-flex gap-3 flex-column'>
                            {
                                [1, 2, 3, 4, 5].map((title) =>
                                    <Loading key={title} height={'8rem'} classes={'col-12'} />
                                )
                            }
                        </div>
                        :
                        <div className={`d-${viewTab === 4 ? 'flex' : 'none'} d-md-flex flex-column justify-content-between gap-3`} style={{ width: '100%' }}>
                            <QuestionList
                                data={questionList}
                                activePage={activePage}
                                pageSize={pageSize}
                                setData={setData}
                                query={filterQuery(location)}
                            />
                            <div className='mt-3'>
                                <Pagination
                                    activePage={activePage}
                                    setActivePage={setActivePage}
                                    totalPages={totalPages}
                                />
                            </div>
                        </div>
                }
                {/* -------------------------------------------------*/}
                {/* Questions List Section End */}
                {/* -------------------------------------------------*/}

                {/* -------------------------------------------------*/}
                {/* Question View Section Start */}
                {/* -------------------------------------------------*/}
                {
                    (loading || data === null || questionList === null || ((viewTab === 2 || viewTab === 3) && solution === null)) ?
                        <div className='d-flex flex-column gap-3'>
                            <Loading height={'60vh'} classes={'col-12'} />
                        </div>
                        :
                        <div className={`d-${viewTab !== 4 ? 'block' : 'none'} d-md-block`} style={{ width: '100%' }}>
                            <QuestionView
                                viewTab={viewTab}
                                data={
                                    (viewTab === 2 || viewTab === 3) ?
                                        { ...solution, number: questionNumber }
                                        :
                                        { ...data, number: questionNumber }
                                }
                                query={filterQuery(location)}
                                activePage={activePage}
                                totalQuestions={totalQuestions}
                                questionList={questionList}
                                setData={setData}
                                setActivePage={setActivePage}
                                questionNumber={questionNumber}
                                setQuestionNumber={setQuestionNumber}
                                setQuestionList={setQuestionList}
                                bookmarked={bookmarked}
                                setBookmarked={setBookmarked}
                            />
                        </div>
                }
                {/* -------------------------------------------------*/}
                {/* Question View Section End */}
                {/* -------------------------------------------------*/}
            </div>

            {/* ------------------------------------------------------------- */}
            {/* popup section */}
            {/* ------------------------------------------------------------- */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default Main
