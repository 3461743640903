import React from 'react'
import { BsBookmark, BsBookmarkFill } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { checkForSave, handleOptionBg } from './PracticeQuestionData';
import { API_URL } from '../../config';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { alertMsg } from '../Alert/AlertData';
import { header } from '../HeaderData';

const QuestionView = ({ viewTab, data, query, activePage, questionList, setQuestionList, totalQuestions, setData, setActivePage, setQuestionNumber, questionNumber, bookmarked, setBookmarked }) => {
    const { jwtToken, user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const { subjectId, topicId } = useParams();

    // user input storage
    const [selected, setSelected] = useState(-1);
    const [answer, setAnswer] = useState('');
    const [multipleSelect, setMultipleSelect] = useState([]);

    // option selection 
    const handleSelectOption = (index) => {
        if (data?.questionType === 'SINGLE_ANSWER') {
            setSelected(index);
        }
        else {
            if (multipleSelect.includes(index)) {
                setMultipleSelect(multipleSelect.filter((value) => value !== index));
            }
            else {
                setMultipleSelect([...multipleSelect, index]);
            }
        }
    }

    const handlePrev = () => {
        if (data?.number > 1) {
            const no = data?.number - 1;
            if ((data?.number) % 10 !== 1) {
                const prevQues = questionList.filter((ques, index) => index === (data?.number - 1) % 10 - 1)[0];
                setData(prevQues);
                const id = prevQues?.id;
                navigate(`/practice/${subjectId}/${topicId}/${id}?page=${activePage + 1}${query}&question=${no}`)
            } else {
                setQuestionNumber(questionNumber - 1);
                setActivePage(activePage - 1);
            }
        }
        setSelected(-1);
        setAnswer('');
        setMultipleSelect([]);
    }

    const handleNext = async () => {
        if ((selected !== -1) || (answer !== null && answer !== '') || (multipleSelect?.length > 0)) {
            if (!jwtToken) {
                alertMsg('error', 'Please sign to get started!');
            }
            else {
                const ans = data?.questionType === 'SINGLE_ANSWER' ? selected : data?.questionType === 'INTEGER' ? answer : multipleSelect?.toString();
                console.log(ans);
                const res = await axios.put(`${API_URL}/studentPracticeResponse`,
                    { questionId: data?.id, studentAnswer: `${ans}`, studentId: user?.userId },
                    header(jwtToken)
                );
                if (res?.data?.questionStatus === 'AC') {
                    alertMsg('success', 'Congratulations! You got right');
                }
                else if (res?.data?.questionStatus === 'WR') {
                    alertMsg('error', "Oops, that's not quite right. Keep trying!");
                }
                else if (res?.data?.questionStatus === 'PR') {
                    alertMsg('warn', "Close, but not quite. Keep trying!");
                }
                setQuestionList(questionList?.map((ques) => ques?.id === res?.data?.questionId ? { ...ques, questionStatus: res?.data?.questionStatus } : ques));
            }
        }
        if (data?.number < totalQuestions) {
            const no = data?.number + 1;
            if ((data?.number) % 10 !== 0) {
                const nextQues = questionList.filter((ques, index) => index === (data?.number - 1) % 10 + 1)[0];
                setData(nextQues);
                const id = nextQues?.id;
                navigate(`/practice/${subjectId}/${topicId}/${id}?page=${activePage + 1}${query}&question=${no}`)
            } else {
                setQuestionNumber(questionNumber + 1);
                setActivePage(activePage + 1);
            }
        }
        setSelected(-1);
        setAnswer('');
        setMultipleSelect([])
    }

    const handleBookmark = async () => {
        if (!bookmarked) {
            const res = await axios.post(`${API_URL}/bookmarks?questionId=${data?.id}`,
                { questionId: data?.id },
                header(jwtToken)
            );
            setBookmarked(res?.data?.id);
        }
        else {
            const res = await axios.delete(`${API_URL}/bookmarks/${bookmarked}`,
                header(jwtToken)
            );
            setQuestionList(questionList?.map((ques) => ques?.id === data?.id ? { ...ques, bookmarked: false, bookmarkedId: null } : ques));
            setData({ ...data, bookmarked: false, bookmarkedId: null });
            setBookmarked(null);
        }
    }

    return (
        <div className='d-flex flex-column gap-2 justify-content-between' style={{ height: '100%' }}>
            {/* --------------------------------------------------------- */}
            {/* Tab Section Start */}
            {/* --------------------------------------------------------- */}
            {/* <div className='d-none d-md-block'>
                <TabSection viewTab={viewTab} setViewTab={setViewTab} />
            </div> */}
            {/* --------------------------------------------------------- */}
            {/* Tab Section End */}
            {/* --------------------------------------------------------- */}

            {/* --------------------------------------------------------- */}
            {/* Question Section Start */}
            {/* --------------------------------------------------------- */}
            <div className='p-sm-4 p-3 rounded-2 d-flex flex-column justify-content-between' style={{ background: 'var(--gray-200)', color: 'var(--gray-700)', height: '60vh', flexGrow: '1' }}>
                <div className='' style={{ flexGrow: '1', overflowY: 'auto' }}>
                    {/* --------------------------------------------------------- */}
                    {/* Question Container Start */}
                    {/* --------------------------------------------------------- */}
                    <div className='d-flex flex-column gap-2 p-3' style={{ background: 'var(--gray-100)', overflowX: 'auto' }}>
                        <div className='d-flex justify-content-between gap-2 align-items-center' style={{ fontWeight: '600' }}>
                            <div className=''>
                                Question {data?.number}
                            </div>
                            <div className='cursor' onClick={() => handleBookmark()}>
                                {
                                    bookmarked === null ?
                                        <BsBookmark />
                                        :
                                        <BsBookmarkFill style={{ color: 'var(--primary)' }} />
                                }
                            </div>
                        </div>

                        <div className='font-120 mb-4' dangerouslySetInnerHTML={{ __html: data?.question }} />
                        <div className='d-flex gap-3 flex-wrap'>
                            <div className='px-2 py-1 rounded-1' style={{ background: 'var(--green-100)', color: 'white' }}>
                                Rating: {data?.rating}
                            </div>
                            <div className='px-2 py-1 rounded-1' style={{ background: 'var(--gray-200)' }}>
                                {data?.questionSubjectId?.name}
                            </div>
                            {
                                data?.questionsTags?.map((tag, index) =>
                                    <div className='px-2 py-1 rounded-1' style={{ background: 'var(--gray-200)' }} key={index}>
                                        {tag?.name}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {/* --------------------------------------------------------- */}
                    {/* Question Container End */}
                    {/* --------------------------------------------------------- */}

                    {/* --------------------------------------------------------- */}
                    {/* Option Section Start */}
                    {/* --------------------------------------------------------- */}
                    <div className=''>
                        {
                            data?.questionType !== 'INTEGER' ?
                                <div className='QuestionViewOptionContainer my-5'>
                                    {
                                        ['A', 'B', 'C', 'D'].map((option, index) =>
                                            <div className='p-3 rounded-1 d-flex flex-column gap-3' style={{ background: 'var(--gray-100)', width: '100%', cursor: 'pointer', overflowX: 'auto' }} key={index} onClick={() => (viewTab !== 2 && viewTab !== 3) && handleSelectOption(index + 1)}>
                                                <div className='d-flex gap-2 align-items-start'>
                                                    <div className='border rounded-5 d-flex justify-content-center align-items-center' style={{ minWidth: '1.5rem', height: '1.5rem', background: handleOptionBg(viewTab, index + 1, selected, data?.previousAnswer, data?.answer, multipleSelect) }}>
                                                        {option}
                                                    </div>
                                                    <div dangerouslySetInnerHTML={{ __html: data ? data[`option${index + 1}`] : '' }} />
                                                </div>
                                                {
                                                    (viewTab === 2 || viewTab === 3) && data?.answer?.includes(index + 1) &&
                                                    <div className='ps-3' style={{ color: 'var(--green-100)', fontWeight: '500' }}>
                                                        Correct Answer
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                                :
                                <div className='p-3 rounded-1 d-flex align-items-center gap-2 pt-5' style={{ background: 'var(--gray-100)', width: '100%' }}>
                                    {
                                        (viewTab !== 2) && <div className=''>Your Answer:</div>
                                    }
                                    {
                                        (viewTab === 3) ?
                                            <div className='fw-bold'>{data?.previousAnswer ? data?.previousAnswer : 'Not Attempted'}</div>
                                            :
                                            (viewTab !== 2) &&
                                            <input type='number' value={answer} onChange={(e) => setAnswer(e.target.value)} />
                                    }
                                    {
                                        (viewTab === 2 || viewTab === 3) && <div className=''>Correct Answer: <span className='fw-bold'>{data?.answer}</span></div>
                                    }
                                </div>
                        }
                    </div>
                    {/* --------------------------------------------------------- */}
                    {/* Option Section End */}
                    {/* --------------------------------------------------------- */}

                    {/* --------------------------------------------------------- */}
                    {/* Solution Section Start */}
                    {/* --------------------------------------------------------- */}
                    {
                        (viewTab === 2 || viewTab === 3) &&
                        <div className='rounded p-3 d-flex flex-column gap-3 my-4' style={{ background: 'var(--gray-100)' }}>
                            <div className='fw-6'>
                                Solution
                            </div>
                            {
                                data?.solution === null || data?.solution === '' ?
                                    <div className=''>
                                        No Solution available for this question
                                    </div>
                                    :
                                    <div
                                        style={{ overflow: 'auto' }}
                                        dangerouslySetInnerHTML={{ __html: data?.solution }}
                                    />
                            }
                        </div>
                    }
                    {/* --------------------------------------------------------- */}
                    {/* Solution Section End */}
                    {/* --------------------------------------------------------- */}
                </div>
            </div>
            {/* --------------------------------------------------------- */}
            {/* Question Section End */}
            {/* --------------------------------------------------------- */}

            {/* --------------------------------------------------------- */}
            {/* Pagination Section Start */}
            {/* --------------------------------------------------------- */}
            <div className='d-flex justify-content-between gap-2 pt-3 font-150'>
                <div onClick={() => handlePrev()} className='px-3 py-2 rounded-1 d-flex justify-content-center align-items-center width-7' style={{ cursor: data?.number !== 1 ? 'pointer' : 'default', background: data?.number === 1 ? 'var(--gray-400)' : 'var(--primary)', color: data?.number === 1 ? 'inherit' : 'white', fontWeight: '600' }}>
                    Previous
                </div>
                <div onClick={() => handleNext()} className='px-3 py-2 rounded-1 d-flex justify-content-center align-items-center width-7' style={{ cursor: data?.number !== totalQuestions ? 'pointer' : 'default', background: (checkForSave(selected, answer, multipleSelect?.length) || data?.number < totalQuestions) ? 'var(--primary)' : 'var(--gray-400)', color: (checkForSave(selected, answer, multipleSelect?.length) || data?.number < totalQuestions) ? 'white' : 'inherit', fontWeight: '600' }}>
                    {
                        checkForSave(selected, answer, multipleSelect?.length) ?
                            data?.number < totalQuestions ?
                                'Save & Next'
                                :
                                'Save'
                            :
                            'Next'
                    }
                </div>
            </div>
            {/* --------------------------------------------------------- */}
            {/* Pagination Section End */}
            {/* --------------------------------------------------------- */}
        </div>
    )
}

export default QuestionView
