import React from 'react'
import Loader from '../components/Loader'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import BottomBar from '../components/BottomBar'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { useEffect } from 'react'
import Main from '../components_App/PracticeQuestion/Main'
import { setLoading } from '../reducers/app'
import axios from 'axios'
import { setCategories } from '../reducers/contest'
import { API_URL } from '../config'

const PracticeQuestion_App = () => {
    const jwtToken = useSelector((state) => state.user.jwtToken)
    const dispatch = useDispatch()
    const [showLoginCanvas, setShowLoginCanvas] = useState(false)
    const [active, setActive] = useState(2);
    const { loading } = useSelector((state) => state.app)

    useEffect(() => {
        getCategories();
    }, [])

    const getCategories = async () => {
        try {
            dispatch(setLoading(true))
            const res = await axios.get(API_URL + "/contestCategory");
            console.log("API URL===========", res.data);
            dispatch(setCategories(res.data))
            dispatch(setLoading(false))
        } catch (e) {
            dispatch(setLoading(false))
            console.log(e.message);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Practice | Unbind';
    }, []);
    return (
        <div className='d-flex flex-column app_container' style={{ height: '100vh'}}>
            {loading && <Loader />}
            {/* <Navbar showCategories={true} showButtons={true} active={active} setActive={setActive} showLoginCanvas={showLoginCanvas} setShowLoginCanvas={setShowLoginCanvas} /> */}
            <Main />
        </div>
    )
}

export default PracticeQuestion_App
