import React, { useContext, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import TestContext from '../context/testContext';
import axios from 'axios'
import { API_URL } from '../config';

import dividerIcon from '../assets/divider.svg'
import singleIcon from '../assets/single-option.svg'
import unselectedIcon from '../assets/unselected-option.svg'
import multipleIcon from '../assets/multiple-option.svg'


export default function Question() {

  let context = useContext(TestContext);

  const [inputText, setInputText] = useState('')

  const fetchQuestionData = async (id) => {
    try {
      context.setFetchingQuestionData(true)
      const res = await axios.get(API_URL + '/question/' + id);
      // const studentRes = await axios.get(API_URL + '/question/' + id);
      console.log('question data============', res.data)
      context.setCurrentQuestion(res.data)
      let temp = { ...context.cache.current["questionData"] }
      temp[res.data.id] = res.data;
      // context.setContestCache(temp);
      context.cache.current["questionData"] = temp;
      context.setFetchingQuestionData(false)
    } catch (e) {
      context.setFetchingQuestionData(false)
      console.log(e)
    }
  }

  const resetResponse = () => {
    console.log("resetting values==============", context.currentQuestion)
    if (context.currentQuestion.questionType === "INTEGER") {
      context.setIntegerResponse(null)
    }
    else if (context.currentQuestion.questionType === "SINGLE_ANSWER") {
      context.setSingleTypeResponse(null)

    }
    else {
      context.setMultipleTypeResponse([])
    }
  }

  useEffect(() => {
    if (context.currentQuestionId) {
      if (context.cache.current["questionData"][context.currentQuestionId.id]) {
        context.setCurrentQuestion(context.cache.current["questionData"][context.currentQuestionId.id]);
      }
      else {
        fetchQuestionData(context.currentQuestionId.id);
      }
    }
  }, [context.currentQuestionId])

  useEffect(() => {
    if (context.currentQuestion) {
      if (context.cache.current["submittedResponse"][context.currentQuestionId.id]) {
        if (context.currentQuestion.questionType === "INTEGER") {
          context.setIntegerResponse(context.cache.current["submittedResponse"][context.currentQuestionId.id])
        }
        else if (context.currentQuestion.questionType === "SINGLE_ANSWER") {
          context.setSingleTypeResponse(context.cache.current["submittedResponse"][context.currentQuestionId.id])

        }
        else {
          context.setMultipleTypeResponse(deStringify(context.cache.current["submittedResponse"][context.currentQuestionId.id]))
        }
      }

      else {
        resetResponse();
      }
    }
  }, [context.currentQuestion])


  return (
    <div className='mt-3 mb-5' style={{ flex: 1 }}>
      {(context && context.currentQuestion && !context.fetchingQuestionData) ?
        <div className='pb-5'>
          <div className='px-3 py-4 rounded-4 shadow-sm bg-white'>
            <div className="d-flex justify-content-between">
              <div id='question-number' className='fw-m' style={{ fontSize: '0.9rem' }}>
                Question {context.currentQuestionNumber}
              </div>

              <div className='d-flex align-items-center' >
                <div className='fw-m contest-item-details  text-nowrap'>
                  {context.currentQuestion.questionSubjectId.name}
                </div>
                <img src={dividerIcon} className='contest-item-details-divider mx-1' alt="" />
                <div className='fw-m contest-item-details text-nowrap'>
                  {context.currentQuestion.questionType === "INTEGER" ? "Integer" : context.currentQuestion.questionType === "SINGLE_ANSWER" ? "Single correct" : "MCQs"}
                </div>
              </div>

            </div>
            <div id='question-marks' className='fw-m fs-xsm text-secondary mt-1'>
              +{context.currentQuestion.positiveMarks} marks, -{context.currentQuestion.negativeMarks} mark
            </div>
            <div id='question-statement' className='fw-r fs-6 mt-4' dangerouslySetInnerHTML={{ __html: context.currentQuestion.question }}>
            </div>
          </div>
          <OptionType
            type={context.currentQuestion.questionType}
            data={context.currentQuestion}
            integerResponse={context.integerResponse}
            setIntegerResponse={context.setIntegerResponse}
            inputText={inputText}
            setInputText={setInputText}
            singleTypeResponse={context.singleTypeResponse}
            setSingleTypeResponse={context.setSingleTypeResponse}
            multipleSelectedOptions={context.multipleTypeResponse}
            setmultipleSelectedOptions={context.setMultipleTypeResponse}
            cache={context.cache}
            updateTimeTaken={context.updateTimeTaken}
            sendResponse={context.sendResponse} />
          {context.currentQuestion.questionType === "INTEGER" && <div className='px-3 py-4 rounded-4'>
            <div className='fw-m fs-xsm text-secondary mt-1 justify-content-between d-flex'>
              <div className="show-interger-response">
                Submitted response : {context.integerResponse ? context.integerResponse : "none"}
              </div>
              <button onClick={() => {
                context.cache.current["submittedResponse"][context.currentQuestion.id] = "";
                context.setIntegerResponse('')
              }} style={{ backgroundColor: "transparent" }} className='border-0 py-0 my-0 fw-sb text-secondary'>Clear</button>
            </div>
          </div>}
        </div>
        : <div className="d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
          <Spinner />
        </div>
      }
    </div>
  )
}



const OptionType = ({ type, data, integerResponse, setIntegerResponse, inputText, setInputText, singleTypeResponse, setSingleTypeResponse, multipleSelectedOptions, setmultipleSelectedOptions, cache, updateTimeTaken, sendResponse }) => {
  if (type === 'INTEGER') {
    return (
      <div className='d-flex align-items-center justify-content-between bg-white mt-4 rounded-4 py-3 px-3'>
        <input
          value={inputText}
          type="number"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // updateTimeTaken();
              setIntegerResponse(inputText);
              cache.current["submittedResponse"][data.id] = inputText;
              // sendResponse(inputText)
              setInputText('')
            }
          }}
          onChange={(e) => setInputText(e.target.value)} placeholder='Enter your response'
          className='border-0 no-focus'
          style={{ width: "100%" }}
        />
        <button
          disabled={inputText === '' || inputText.length === 0}
          onClick={() => {
            // updateTimeTaken();
            setIntegerResponse(inputText);
            cache.current["submittedResponse"][data.id] = inputText;
            // sendResponse(inputText)
            setInputText('')
          }}
          id="integer-que-submit-btn"
          className='btn bg-white text-1'>
          Submit
        </button>
      </div>
    )
  }
  else if (type === 'SINGLE_ANSWER') {
    let { option1, option2, option3, option4 } = data;
    const handleClick = (option) => {
      // updateTimeTaken();
      if (singleTypeResponse !== `${option}`) {
        cache.current["submittedResponse"][data.id] = `${option}`;
        // sendResponse(`${option}`)
        setSingleTypeResponse(`${option}`);
      }
      else {
        // sendResponse("")
        cache.current["submittedResponse"][data.id] = "";
        setSingleTypeResponse('');
      }
    }
    return (
      <div id='options-outer-cnt' className='mt-4'>
        <div onClick={() => handleClick(1)} className='bg-white rounded-4 py-3 px-3 shadow-sm d-flex align-items-center'>
          <img className='me-2 hover-pointer' src={singleTypeResponse === '1' ? singleIcon : unselectedIcon} alt="" />
          <div dangerouslySetInnerHTML={{ __html: option1 }} ></div>
        </div>
        <div onClick={() => handleClick(2)} className='bg-white rounded-4 py-3 px-3 shadow-sm d-flex align-items-center'>
          <img className='me-2 hover-pointer' src={singleTypeResponse === '2' ? singleIcon : unselectedIcon} alt="" />
          <div dangerouslySetInnerHTML={{ __html: option2 }} ></div>
        </div>
        <div onClick={() => handleClick(3)} className='bg-white rounded-4 py-3 px-3 shadow-sm d-flex align-items-center'>
          <img className='me-2 hover-pointer' src={singleTypeResponse === '3' ? singleIcon : unselectedIcon} alt="" />
          <div dangerouslySetInnerHTML={{ __html: option3 }} ></div>
        </div>
        <div onClick={() => handleClick(4)} className='bg-white rounded-4 py-3 px-3 shadow-sm d-flex align-items-center'>
          <img className='me-2 hover-pointer' src={singleTypeResponse === '4' ? singleIcon : unselectedIcon} alt="" />
          <div dangerouslySetInnerHTML={{ __html: option4 }} ></div>
        </div>
      </div>
    )
  }
  let { option1, option2, option3, option4 } = data;

  const handleMultipleOption = (option) => {
    // updateTimeTaken();
    let temp = [...multipleSelectedOptions];
    if (multipleSelectedOptions.includes(option)) {
      temp = temp.filter(item => item !== option);
    } else {
      temp.push(option)
    }
    cache.current["submittedResponse"][data.id] = stringify(temp);
    setmultipleSelectedOptions(temp);
    // sendResponse(stringify(temp));

  }
  return (
    <div id='options-outer-cnt' className='mt-4'>
      <div onClick={() => handleMultipleOption(1)} className='bg-white rounded-4 py-3 px-3 shadow-sm d-flex align-items-center'>
        <img className='me-2 hover-pointer' src={multipleSelectedOptions.includes(1) ? multipleIcon : unselectedIcon} alt="" />
        <div dangerouslySetInnerHTML={{ __html: option1 }} ></div>
      </div>
      <div onClick={() => handleMultipleOption(2)} className='bg-white rounded-4 py-3 px-3 shadow-sm d-flex align-items-center'>
        <img className='me-2 hover-pointer' src={multipleSelectedOptions.includes(2) ? multipleIcon : unselectedIcon} alt="" />
        <div dangerouslySetInnerHTML={{ __html: option2 }} ></div>
      </div>
      <div onClick={() => handleMultipleOption(3)} className='bg-white rounded-4 py-3 px-3 shadow-sm d-flex align-items-center'>
        <img className='me-2 hover-pointer' src={multipleSelectedOptions.includes(3) ? multipleIcon : unselectedIcon} alt="" />
        <div dangerouslySetInnerHTML={{ __html: option3 }} ></div>
      </div>
      <div onClick={() => handleMultipleOption(4)} className='bg-white rounded-4 py-3 px-3 shadow-sm d-flex align-items-center'>
        <img className='me-2 hover-pointer' src={multipleSelectedOptions.includes(4) ? multipleIcon : unselectedIcon} alt="" />
        <div dangerouslySetInnerHTML={{ __html: option4 }} ></div>
      </div>
    </div>

  )

}


const stringify = (temp) => {
  let t = "";
  temp.forEach((option, index) => {
    if (index !== 0) {
      t += `,${option}`
    }
    else t += option;
  });
  return t;
}

const deStringify = (temp) => {
  let p = temp.split(',');
  p.forEach((item, ind) => {
    p[ind] = parseInt(item);
  })
  return p;
}