import React, { useState } from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

const Question = ({ data }) => {
    const [show, setShow] = useState(false)
    return (
        <div className='rounded-4 ' style={{ border: '2px solid black', maxWidth: '650px' }}>
            <h4 className='px-4 py-2 d-flex justify-content-between gap-4 align-items-center rounded-top-4' style={{ cursor: 'pointer', height:'fit-content', color: `${show ? 'white' : 'black'}`, background: `${show ? 'var(--primary)' : 'transparent'}`, fontWeight:'400', margin:0 }} onClick={() => setShow(!show)}>
                {data?.question} {show ? <BsChevronUp className='chevron' /> : <BsChevronDown className='chevronIcon' />}
            </h4>
            <div className={`questionDropdown ${show ? 'questionOpen' : ''} rounded-bottom-4`} style={{ background: `${show ? '#FCD671' : 'transparent'}` }}>
                <hr style={{ margin: '0px' }} />
                <div className='px-4 py-1'>
                    {data?.answer}
                </div>
            </div>
        </div>
    )
}

export default Question
