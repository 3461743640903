import React from 'react';
import RatingCard from './RatingCard';
import cup from '../Contest_assets/cup2.png';
import UserCard from './UserCard';
import { useState } from 'react';
import { useEffect } from 'react';
import { API_URL } from '../../../config';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../Paginations/Pagination';
import { setLoading } from '../../../reducers/app';
import { userTableStructure } from '../Contests/ContestsData';
import Table2 from '../../Table_component_App/Table2';

const Rankings = ({ view }) => {
    const [rankingData, setRankingData] = useState([]);
    const user = useSelector((state) => state.user.user)
    const selectedCategory = useSelector((state) => state.user.defaultCategory)
    const { loading } = useSelector((state) => state.app)
    const pageSize = 10;
    const [activePage, setActivePage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const dispatch = useDispatch();

    const getRankings = async () => {
        try {
            dispatch(setLoading(true));
            const res = await axios.get(API_URL + `/user?chosen_cat=${selectedCategory.id}&ASCbyRating=false&page=${activePage}&size=${pageSize}`);
            setRankingData(res?.data?.content);
            setTotalPages(res?.data?.totalPages);
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            console.log(e.message);
        }
    }

    useEffect(() => {
        getRankings();

    }, [selectedCategory, activePage]);

    return (
        <div className={`d-lg-flex ${view === 1 ? 'd-flex' : 'd-none'} flex-column gap-4 col-lg-4 col-12`}>
            {
                user && user !== null && user !== '' &&
                <RatingCard />
            }
            <div className='d-flex flex-column gap-2 rounded-4 p-3 app_contest_ranking_table'>
                <div className='d-flex gap-2 align-items-center'>
                    <img src={cup} alt='' />
                    <div className='font-120 font-usercard-120'>Global Rankings</div>
                </div>
                <div className='d-flex flex-column gap-2' style={{ overflowX: 'auto' }}>
                    <Table2 tableStructure={userTableStructure} data={rankingData} startRank={activePage * pageSize} fontStyle={'font-usercard'} />
                </div>
                {/* <div className='d-flex flex-column gap-2'>
                    <div className='d-flex justify-content-between font-80 font-usercard-80' style={{ color: 'var(--gray-600)' }}>
                        <div className='col-2'>Rank</div>
                        <div className='col-8 text-center'>Name</div>
                        <div className='col-2'>Rating</div>
                    </div>
                    <div className='d-flex flex-column'>
                        {
                            loading ?
                                <div className='d-flex flex-column gap-2'>
                                    {
                                        [1, 2, 3].map((data) =>
                                            <div className='rounded-3' key={data} style={{ backgroundColor: `var(--gray-300)`, height: '50px', width: '100%' }}>
                                            </div>
                                        )
                                    }
                                </div>
                                :
                                rankingData &&
                                rankingData?.map((user, index) =>
                                    <UserCard
                                        key={index}
                                        data={user}
                                        rank={index + 1 + activePage * pageSize}
                                        pageSize={pageSize}
                                    />
                                )
                        }
                    </div>
                </div> */}
                <div className=''>
                    <Pagination activePage={activePage} setActivePage={setActivePage} totalPages={totalPages} />
                </div>
            </div>
        </div>
    )
}

export default Rankings
