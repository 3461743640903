import React from 'react';
import { termsQueries } from './Data';
import DropDown from './DropDown';
import { useState } from 'react';

const Right = ({ type }) => {
  const [open, setOpen]=useState(0);
  return (
    <div className='col-md-7 col-12 d-flex flex-column gap-3'>
      {
        termsQueries[type].map((query, index) =>
          <DropDown key={index} data={query} index={index} open={open} setOpen={setOpen} />
        )
      }
    </div>
  )
}

export default Right
