import React, { useEffect, useState } from 'react';
import icon from './standings_assets/compare.png';
import Search from './Search';
import Filter from './Filter';
import Table from './Table';
import { setLoading } from '../../reducers/app';
import { API_URL } from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Pagination from '../Paginations/Pagination';
import ResultTop from '../Top_components/ResultTop';

const Main = () => {
    const [standingData, setStandingData] = useState([]);
    const pageSize = 15;
    const [activePage, setActivePage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const dispatch = useDispatch();
    const { id } = useParams();
    const sortByData = ['ASCbyRank', 'ASCbyName'];
    const [asc, setAsc] = useState(true);
    const [ascData, setAscData] = useState(0);
    const [contestData, setContestData] = useState(null);
    const [searchData, setSearchData] = useState('');

    const getStandings = async () => {
        try {
            dispatch(setLoading(true));
            const res = await axios.get(API_URL + `/studentContestResult/standings?${sortByData[ascData]}=${asc}&contestId=${id}&page=${activePage}&size=${pageSize}&searchString=${searchData}`);
            setStandingData(res?.data?.content);
            setTotalPages(res?.data?.totalPages);
            dispatch(setLoading(false));
            console.log(res?.data)
        } catch (e) {
            dispatch(setLoading(false));
            console.log(e.message);
        }
    }

    const getContestDetail = async () => {
        try {
            dispatch(setLoading(true));
            const res = await axios.get(API_URL + `/contest/${id}`);
            setContestData(res?.data);
            dispatch(setLoading(false));
        }
        catch (e) {
            dispatch(setLoading(false));
            console.log(e.message)
        }
    }

    useEffect(() => {
        getStandings();

    }, [activePage, asc, ascData, searchData]);

    useEffect(() => {
        getContestDetail();
    }, []);

    return (
        <div className='col-md-10 col-11 mx-auto my-4 d-flex flex-column gap-2' style={{ flexGrow: "1" }}>

            {/* Top Section Start  */}
            <ResultTop title={'Ranking of Result'} date={(new Date(contestData?.start_time)).toLocaleDateString("en-IN", { timeZone: "Asia/Kolkata" }) + " "} name={contestData?.name} />
            {/* Top Section End  */}

            {/* Filter and Search Section Start  */}
            <div className='d-flex my-3 flex-column flex-md-row gap-3 align-items-md-center'>
                <div className=''>
                    <Search searchData={searchData} setSearchData={setSearchData} />
                </div>
                {/* <div className=''>
                    <Filter />
                </div> */}
            </div>
            {/* Filter and Search Section End  */}

            <div className='' style={{ flexGrow: '1' }}>
                <Table data={standingData} setAsc={setAsc} asc={asc} ascData={ascData} setAscData={setAscData} />
            </div>
            <div className=''>
                <Pagination activePage={activePage} totalPages={totalPages} setActivePage={setActivePage} />
            </div>
        </div>
    )
}

export default Main
