import React from 'react';
import img1 from './home_assets/pik1.svg'
import img2 from './home_assets/pik2.png'
import img3 from './home_assets/pik3.svg'
import img4 from './home_assets/pik4.svg'
import SingleBanner from './SingleBanner';

const BannerNormal = () => {
  return (
    <div className='bannerC d-flex flex-column gap-5 position-relative' style={{ margin: '5rem auto' }}>
      <SingleBanner title={'Community'} detail={`Community helps you through exchange of idea's between you and your peers that promotes inclusive growth.`} bg={'#CBE4F9'} color={'#3B4CA4'} img={img3} overflow={false} />
      <SingleBanner title={'Compete'} detail={`Compete with the sharpest minds to assess your position in the vast competition.`} bg={'#EFF9DA'} color={'#547C00'} img={img2} overflow={false} />
      <SingleBanner title={'Practice'} detail={`Practice thousands of free tests with detailed solutions & performance analysis.`} bg={'#F9EBDF'} color={'#EC7C1C'} img={img1} overflow={true} />
      {/* <SingleBanner title={'Mentorship'} detail={`Practice thousands of free tests with detailed solutions & performance analysis.`} bg={'#CDF5F6'} color={'#00A4A8'} img={img4} overflow={true} /> */}
    </div>
  )
}

export default BannerNormal
