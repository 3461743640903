import React from 'react'
import IconValue from '../../Icons/IconValue';
import { MdVerified } from 'react-icons/md';
import ListCardView from '../../Card_components/ListCardView';
import { FaDraftingCompass } from 'react-icons/fa';
import { DiAtom } from 'react-icons/di';
import { SlChemistry } from 'react-icons/sl';
import SessionCard2 from '../../Card_components/SessionCard2';
import Loading from '../../Loading/Loading';
import { useSelector } from 'react-redux';

const BriefInfo = ({ subjectLists, sessionData }) => {
    const { jwtToken } = useSelector((state) => state.user);
    const details = ['Created by Experts', 'Detailed solutions & analytics', '10000+ Questions'];
    return (
        <div className='practice-briefInfo-wrapper py-5'>
            <div className='col-md-10 col-11 mx-auto py-3'>
                <div className='d-flex gap-5 gap-md-3 justify-content-between align-items-md-start align-items-center flex-column flex-md-row'>
                    {/* ---------------------------------------------------- */}
                    {/* Title Section Start */}
                    {/* ---------------------------------------------------- */}
                    <div>
                        <div className='fw-6 font-type-7 text-center text-md-start' style={{ color: 'var(--gray-600)' }}>
                            Practice Questions
                        </div>
                        <div className='practice-icons-grid'>
                            {
                                details.map((item, index) =>
                                    <IconValue
                                        icon={<MdVerified color='#00AC00' />}
                                        value={item}
                                        key={index}
                                    />
                                )
                            }
                        </div>
                    </div>
                    {/* ---------------------------------------------------- */}
                    {/* Title Section End */}
                    {/* ---------------------------------------------------- */}
                    {
                        jwtToken &&
                        (sessionData ?
                            <SessionCard2 data={sessionData} />
                            :
                            <Loading classes={'col-4'} height={'8rem'} />)
                    }
                </div>

                {/* ---------------------------------------------------- */}
                {/* Subject Section Start */}
                {/* ---------------------------------------------------- */}
                <div className='mt-4 mt-md-0'>
                    <div className='font-type-2 text-center text-md-start' style={{color:'var(--gray-500)'}}>
                        Select Subject
                    </div>
                    <div className='d-flex flex-wrap justify-content-center justify-content-md-start gap-3 my-2 mt-3'>
                        {
                            subjectLists?.map((item, index) =>
                                <ListCardView
                                    key={index}
                                    icon={item?.iconUrl}
                                    text={item?.name}
                                    link={`/app/practice/${item.id}`}
                                />
                            )
                        }
                    </div>
                </div>
                {/* ---------------------------------------------------- */}
                {/* Subject Section End */}
                {/* ---------------------------------------------------- */}
            </div>
        </div>
    )
}

export default BriefInfo
