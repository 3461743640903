import React, { useState } from 'react';
import { MdVerified } from 'react-icons/md';
import icon from '../Profile_assets/private.png';
import call from '../Profile_assets/call.png';
import msg from '../Profile_assets/message.png';
import flag from '../Profile_assets/flag.png';

const info = {
    birthday: '',
    email: '',
}

const PrivateInfo = ({ data, editProfile, userProfile, handleChange }) => {
    
    return (
        <div className='p-3 pb-5 d-flex flex-column gap-3 rounded-2' style={{ background: 'var(--white)' }}>
            <div className='d-flex align-items-start justify-content-between fw-5'>
                <div className=''>
                    Private to you
                </div>
                <div className='px-2'>
                    <img src={icon} style={{ height: '4rem' }} alt='' />
                </div>
            </div>
            <div className='d-flex flex-column gap-3'>
                {/* ---------------------------------------------------------------- */}
                {/* phone section start */}
                {/* ---------------------------------------------------------------- */}
                <div className='d-flex flex-column gap-2'>
                    <div className='d-flex align-items-center gap-1'>
                        <div className='d-flex gap-1'>
                            <img src={call} style={{ width: '1.2rem' }} alt='' />
                        </div>
                        <div className=''>
                            PHONE
                        </div>
                        <div className='ps-2' style={{ color: '#00BA00' }}>
                            <MdVerified />
                        </div>
                        <div className='' style={{ color: '#00BA00' }}>
                            verified
                        </div>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                        <img
                            src={flag}
                            style={{ width: '1.4rem' }}
                            alt="Country Flag"
                        />
                        <div className=''>
                            +91 {data?.mobileNumber}
                        </div>
                    </div>
                </div>
                {/* ---------------------------------------------------------------- */}
                {/* phone section end */}
                {/* ---------------------------------------------------------------- */}

                {/* ---------------------------------------------------------------- */}
                {/* email section start */}
                {/* ---------------------------------------------------------------- */}
                <div className='d-flex flex-column gap-2'>
                    <div className='d-flex align-items-center gap-1'>
                        <div className='d-flex gap-1'>
                            <img src={msg} style={{ width: '1.2rem' }} alt='' />
                        </div>
                        <div className=''>
                            EMAIL ADDRESS
                        </div>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                        {/* unbind@gmail.com */}
                        {
                            !editProfile ?
                                data?.email ?
                                    data?.email
                                    :
                                    'No Email'
                                :
                                <div className='d-flex gap-3 align-items-center' style={{ width: '100%', maxWidth: '300px' }}>
                                    <input type='email' value={userProfile?.email} name='email' onChange={handleChange} style={{ width: '100%', maxWidth: '250px' }} />
                                    {/* <div className='rounded-5 py-1 px-3' style={{ background: 'var(--primary)', color: 'var(--white)' }} onClick={()=>handleSubmit('Email', email)}>
                                        Add
                                    </div> */}
                                </div>
                        }
                    </div>
                </div>
                {/* ---------------------------------------------------------------- */}
                {/* email section end */}
                {/* ---------------------------------------------------------------- */}
            </div>
        </div>
    )
}

export default PrivateInfo
