import React from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

const Pagination = ({ activePage, setActivePage, totalPages }) => {
  return (
    <div className='d-flex justify-content-center gap-3' style={{ color: 'var(--gray-800)', fontSize: '0.9rem' }}>

      <div
        className=''
        style={{ cursor: `${activePage !== 0 ? 'pointer' : 'default'}`, color: `${activePage === 0 ? 'var(--gray-500)' : 'inherit'}` }}
        onClick={() => { activePage > 0 && setActivePage(activePage - 1) }}
      >
        <BsChevronLeft />
      </div>

      <div className=''>{activePage + 1}/{totalPages}</div>

      <div
        className=''
        style={{ cursor: `${activePage !== (totalPages - 1) ? 'pointer' : 'default'}`, color: `${activePage === (totalPages - 1) ? 'var(--gray-500)' : 'inherit'}` }}
        onClick={() => { activePage < (totalPages - 1) && setActivePage(activePage + 1) }}
      >
        <BsChevronRight />
      </div>

    </div>
  )
}

export default Pagination
