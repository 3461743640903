import React from 'react'
import { useState } from 'react';
import { BsBookmarkFill } from 'react-icons/bs';
import { handleOptionBg } from '../PracticeQuestion/PracticeQuestionData';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { header } from '../HeaderData';
import { API_URL } from '../../config';
import { alertMsg } from '../Alert/AlertData';
import { ToastContainer } from 'react-toastify';

const BookMarkQuestionCard = ({ data, bookmarkedId }) => {
    const [showSolution, setShowSolution] = useState(false);
    const { jwtToken, user } = useSelector((state) => state?.user);
    const [bookmarked, setBookmarked] = useState(true);

    // user input storage
    const [selected, setSelected] = useState(-1);
    const [answer, setAnswer] = useState('');
    const [multipleSelect, setMultipleSelect] = useState([]);

    // option selection 
    const handleSelectOption = (index) => {
        if (data?.questionType === 'SINGLE_ANSWER') {
            setSelected(index);
        }
        else {
            if (multipleSelect.includes(index)) {
                setMultipleSelect(multipleSelect.filter((value) => value !== index));
            }
            else {
                setMultipleSelect([...multipleSelect, index]);
            }
        }
    }

    const handleBookmark = async () => {
        if (!bookmarked) {
            const res = await axios.post(`${API_URL}/bookmarks?questionId=${bookmarkedId}`,
                { questionId: data?.id },
                header(jwtToken)
            );
            setBookmarked(true);
            alertMsg('success', 'Added to bookmarks')
        }
        else {
            const res = await axios.delete(`${API_URL}/bookmarks/${bookmarkedId}`,
                header(jwtToken)
            );
            setBookmarked(false);
            alertMsg('success', 'Removed from Bookmarks');
        }
    }

    const handleSubmit = async () => {
        if ((selected !== -1) || (answer !== null && answer !== '') || (multipleSelect?.length > 0)) {
            if (!jwtToken) {
                alertMsg('error', 'Please sign to get started!');
            }
            else {
                const ans = data?.questionType === 'SINGLE_ANSWER' ? selected : data?.questionType === 'INTEGER' ? answer : multipleSelect?.toString();
                console.log(ans);
                const res = await axios.put(`${API_URL}/studentPracticeResponse`,
                    { questionId: data?.id, studentAnswer: `${ans}`, studentId: user?.userId },
                    header(jwtToken)
                );
                if (res?.data?.questionStatus === 'AC') {
                    alertMsg('success', 'Congratulations! You got right');
                }
                else if (res?.data?.questionStatus === 'WR') {
                    alertMsg('error', "Oops, that's not quite right. Keep trying!");
                }
                else if (res?.data?.questionStatus === 'PR') {
                    alertMsg('warn', "Close, but not quite. Keep trying!");
                }
            }
        }
        setSelected(-1);
        setAnswer('');
        setMultipleSelect([])
    }

    return (
        <div className='d-flex flex-column gap-2 p-3 rounded-2' style={{ border: '2px solid var(--gray-300)', height: '100%' }}>
            <div className='d-flex flex-column gap-3' style={{ flexGrow: '1', overflowY: 'auto' }}>
                {/* ------------------------------------------------------------------------ */}
                {/* Question Title Section Start */}
                {/* ------------------------------------------------------------------------ */}
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='px-2 py-1 rounded-5' style={{ background: 'var(--gray-200)', fontWeight: '600', color: 'var(--gray-700)' }}>
                        Question {data?.number}
                    </div>
                    {/* <div className='cursor' style={{ color: 'var(--primary)' }} onClick={() => handleBookmark()}>
                        {
                            <BsBookmarkFill />
                        }
                    </div> */}
                </div>
                {/* ------------------------------------------------------------------------ */}
                {/* Question Title Section End */}
                {/* ------------------------------------------------------------------------ */}

                {/* ------------------------------------------------------------------------ */}
                {/* Question Start */}
                {/* ------------------------------------------------------------------------ */}
                <div dangerouslySetInnerHTML={{ __html: data?.question }} />
                {/* ------------------------------------------------------------------------ */}
                {/* Question End */}
                {/* ------------------------------------------------------------------------ */}

                {/* ------------------------------------------------------------------------ */}
                {/* Question Tag Start */}
                {/* ------------------------------------------------------------------------ */}
                <div className='d-flex flex-wrap gap-2'>
                    <div className='px-2 py-1 rounded-1' style={{ background: 'var(--gray-200)' }}>
                        {data?.questionSubjectId?.name}
                    </div>
                    {
                        data?.questionsTags?.map((d, index) =>
                            <div className='p-1 px-2 rounded-2' style={{ background: 'var(--gray-200)' }} key={index}>{d?.name}</div>
                        )
                    }
                </div>
                {/* ------------------------------------------------------------------------ */}
                {/* Question Tag End */}
                {/* ------------------------------------------------------------------------ */}

                {/* --------------------------------------------------------- */}
                {/* Option Section Start */}
                {/* --------------------------------------------------------- */}
                <div className=''>
                    {
                        data?.questionType !== 'INTEGER' ?
                            <div className='d-flex flex-column gap-3 my-5'>
                                {
                                    ['A', 'B', 'C', 'D'].map((option, index) =>
                                        <div className='p-3 rounded-1 d-flex flex-column gap-3' style={{ border: '2px solid var(--gray-300)', width: '100%', cursor: 'pointer', overflowX: 'auto' }} key={index} onClick={() => !showSolution && handleSelectOption(index + 1)}>
                                            <div className='d-flex gap-2 align-items-start'>
                                                <div className='border rounded-5 d-flex justify-content-center align-items-center' style={{ minWidth: '1.5rem', height: '1.5rem', background: handleOptionBg(showSolution ? 3 : 1, index + 1, selected, data?.previousAnswer, data?.answer, multipleSelect) }}>
                                                    {option}
                                                </div>
                                                <div dangerouslySetInnerHTML={{ __html: data ? data[`option${index + 1}`] : '' }} />
                                            </div>
                                            {
                                                data?.answer?.includes(index + 1) && showSolution &&
                                                <div className='ps-3' style={{ color: 'var(--green-100)', fontWeight: '500' }}>
                                                    Correct Answer
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            :
                            <div className='p-3 rounded-1 d-flex flex-column gap-2 pt-5' style={{ width: '100%' }}>
                                <div className='d-flex gap-2'>
                                    {
                                        !showSolution &&
                                        <div className=''>Your Answer:</div>
                                    }
                                    {
                                        !showSolution &&
                                        // <div className='fw-bold'>{data?.previousAnswer ? data?.previousAnswer : 'Not Attempted'}</div>
                                        // :
                                        <input type='number' value={answer} onChange={(e) => setAnswer(e.target.value)} />
                                    }
                                </div>
                                {
                                    showSolution && <div className=''>Correct Answer: <span className='fw-bold'>{data?.answer}</span></div>
                                }
                            </div>
                    }
                </div>
                {/* --------------------------------------------------------- */}
                {/* Option Section End */}
                {/* --------------------------------------------------------- */}

                {/* --------------------------------------------------------- */}
                {/* Solution Section Start */}
                {/* --------------------------------------------------------- */}
                {
                    showSolution &&
                    <div className='rounded p-3 d-flex flex-column gap-3 my-4' style={{ background: 'var(--gray-100)' }}>
                        <div className='fw-6'>
                            Solution
                        </div>
                        {
                            data?.solution === null || data?.solution === '' ?
                                <div className=''>
                                    No Solution available for this question
                                </div>
                                :
                                <div
                                    style={{ overflow: 'auto' }}
                                    dangerouslySetInnerHTML={{ __html: data?.solution }}
                                />
                        }
                    </div>
                }
                {/* --------------------------------------------------------- */}
                {/* Solution Section End */}
                {/* --------------------------------------------------------- */}
            </div>

            {/* --------------------------------------------------------- */}
            {/* Pagination Section Start */}
            {/* --------------------------------------------------------- */}
            <div className='d-flex justify-content-between gap-2 pt-3 font-120'>
                <div className='px-3 py-2 rounded-1 d-flex justify-content-center align-items-center cursor' style={{ background: 'var(--primary)', color: 'white', fontWeight: '600', width: '28%' }} onClick={() => setShowSolution(!showSolution)}>
                    {
                        showSolution ? 'Back' : 'Solution'
                    }
                </div>
                <div className='px-3 py-2 rounded-1 d-flex justify-content-center align-items-center cursor' style={{ background: 'var(--primary)', color: 'white', fontWeight: '600', width: '28%' }} onClick={() => handleSubmit()}>
                    Save
                </div>
            </div>
            {/* --------------------------------------------------------- */}
            {/* Pagination Section End */}
            {/* --------------------------------------------------------- */}


        </div>
    )
}

export default BookMarkQuestionCard
