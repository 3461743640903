import React, { useState } from 'react'
import backBtn from '../assets/back.svg'
import '../styles/testInstructions.css'
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import telegramIcon from '../assets/telegram.png'
import axios from 'axios';
import { API_URL } from '../config';
import VirtualLists from './Card_components/VirtualLists';
import Table2 from './Table_component/Table2';
import { useEffect } from 'react';
import TopFives from './Card_components/TopFives';
import VirtualTestPopUp from './VirtualTestPopUp';
import clock from '../assets/clockIcon.png';
import calendar from '../assets/calendarIcon.png';

export default function TestRegistration({ contest, setShowLoginCanvas, registered, loading, getRegisterStatus }) {
    const navigate = useNavigate()
    const [showInstructions, setShowInstructions] = useState(false)
    const user = useSelector((state) => state.user.user)
    const jwtToken = useSelector((state) => state.user.jwtToken);
    const [standingData, setStandingData] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [virtualLists, setVirtualLists] = useState(null);
    const pageSize = 3;
    const [contestPopup, setContestPopup] = useState(false);

    const getStandings = async () => {
        try {
            const res = await axios.get(API_URL + `/studentContestResult/standings?contestId=${contest.id}&page=${0}&size=${5}&ASCbyRank=true`);
            setStandingData(res?.data?.content);
            console.log('standing data for top 5==================================>', res?.data)
        } catch (e) {
            console.log(e.message);
        }
    }

    const getVirtualAttempts = async () => {
        const res = await axios.get(`${API_URL}/registerForContest/query?contestId=${contest.id}&isVirtual=true&page=${activePage}&size=${pageSize}&userId=${user?.userId}`);
        console.log('all virtual attempts=======================================>', res);
        setTotalPages(res?.data?.totalPages);
        setVirtualLists(res?.data?.content);
        setTotalElements(res?.data?.totalElements);
    }

    console.log("registered====", registered);


    const handleRegisterForContest = async () => {
        try {
            if (!user) {
                setShowLoginCanvas(true);
            }
            else {
                console.log("registering for contest====================", contest.id);
                const res = await axios.post(API_URL + `/registerForContest?isVirtual=false`, { id: contest.id }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwtToken}`
                    }
                });
                console.log("register user response============", res)
                getRegisterStatus();
            }
        } catch (e) {
            console.log(e)
        }
    }
    const handleStartContest = async () => {
        try {
            navigate("/contest/start/" + contest.id + "/" + registered.id);
        } catch (e) {
            console.log(e)
        }
    }

    const handleStart = async () => {
        try {
            const res = await axios.post(API_URL + `/registerForContest?isVirtual=true`, { id: contest.id }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            console.log("register user response============", res)

            navigate("/contest/virtual/start/" + contest.id + "/" + res?.data?.id)
        } catch (e) {
            console.log(e)
        }
    }

    const handleCancel = () => {
        setContestPopup(false);
    }

    const handleStartVirtualContest = () => {
        if (!user) {
            setShowLoginCanvas(true);
        } else {
            setContestPopup(true);
        }
    }

    const setReminder = () => {
        const message = "https://t.me/neetiitjeequiz";
        window.location.href = message;
    }

    useEffect(() => {
        if (contest.results_declared)
            getStandings();

    }, [contest]);

    useEffect(() => {
        if (user)
            getVirtualAttempts();
    }, [user, activePage])

    return (<>
        {contestPopup && <VirtualTestPopUp type="startContest" text="Start Virtual Contest" handleCancel={handleCancel} handleStart={handleStart} />}
        {!loading && <div className='bg-light py-5 px-1 d-flex flex-column align-items-center'>
            <Offcanvas placement='end' className="px-4 pt-4" show={showInstructions} onHide={() => { setShowInstructions(false) }}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="fs-3 text-gray-5 fw-sb">Instructions</div>
                    <div className='mt-4 fs-sm text-gray-3' dangerouslySetInnerHTML={{ __html: contest.instructions }}>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            <div className='d-flex col-md-11 col-11 mx-auto flex-xl-row flex-column gap-3 justify-content-center'>
                <div className='col-xl-7 col-xxl-8 col-12'>
                    <div id='test-info-cnt' className='container-1 px-res-1 d-flex flex-column py-4 rounded-1' style={{ background: 'linear-gradient(89.67deg, #F8F8F8 -34%, rgba(242, 243, 255, 0.25) 25.04%, rgba(246, 254, 255, 0.86) 100%)' }}>
                        <Link to={'/contest'}>
                            <img src={backBtn} className='icon-1 mb-4' alt="" />
                        </Link>
                        <div className="back fw-b fs-4 mb-4 text-gray-5">{contest.name}</div>
                        <div className="d-flex flex-md-row flex-column gap-2 mb-5">
                            {/* <div className="d-flex align-items-center mb-4">
                        <div className="icon-cnt me-4">
                            <img className='icon-1' src="https://static.uacdn.net/production/_next/static/images/quiz/sheet.svg?q=75&auto=format%2Ccompress&w=32" alt="" />
                        </div>
                        {categories.map((i, index) => (
                            <div className="me-4" key={index}>
                                <div className="fs-xsm fw-sb text-secondary">
                                    {i}
                                </div>
                                <div className="fs-sm fw-m">
                                    {noOfQues[index]} Q's
                                </div>
                            </div>
                        ))}
                    </div> */}
                            <div className='d-flex align-items-center mb-4 ms-2'>
                                <div className=" me-3">
                                    <img className='icon-1' src={calendar} alt="" />
                                </div>
                                <div className="me-4">
                                    <div className="fs-xsm fw-sb text-primary">
                                        Start time
                                    </div>
                                    <div className='fw-m fs-sm  text-nowrap'>
                                        {(new Date(contest.start_time)).toLocaleDateString("en-IN", { timeZone: "Asia/Kolkata" }) + " "}
                                        {(new Date(contest.start_time)).toLocaleTimeString('en-IN', { timeZone: "Asia/Kolkata" })}

                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mb-4'>
                                <div className=" me-3">
                                    <img className='icon-1' src={clock} alt="" />
                                </div>
                                <div className="me-4">
                                    <div className="fs-xsm fw-sb text-primary">
                                        Duration
                                    </div>
                                    <div className='fw-m fs-sm  text-nowrap'>
                                        {contest.duration + ' min'}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id='test-info-btns' className='d-flex align-self-end'>
                            {/* {<button onClick={() => setShowInstructions(true)} id='instruction-btn' className={`btn rounded-1 me-2 align-self-end  py-2 px-3 bg-white text-1 fw-m text-nowrap`} type="button">Instructions</button>} */}

                            {checkStatus(contest.start_time, contest.duration) && registered && (registered.registerStatus === 'REGISTERED' || registered.registerStatus === "STARTED") && <button onClick={handleStartContest} disabled={loading} id='take-test-btn' className={`btn rounded-1 border-0 py-2 px-3  fw-sb text-light bg-1 text-nowrap`} type="submit">
                                {
                                    loading ?
                                        <Spinner bsPrefix='mx-3 spinner' size='sm' animation="border" variant="light" />
                                        : "Take test"
                                }
                            </button>}
                            {checkStatus(contest.start_time, contest.duration) && registered && registered.registerStatus === "SUBMITTED" && <button onClick={() => navigate(`/contest/result/${user?.userId}/${contest.id}`)} id='take-test-btn' className={`btn rounded-1 border-0 py-2 px-3  fw-sb text-light text-hover-1 bg-1 bg-hover-light text-nowrap`} type="submit">
                                {
                                    loading ?
                                        <Spinner bsPrefix='mx-3 spinner' size='sm' animation="border" variant="light" />
                                        : "See Result"
                                }
                            </button>}
                            {checkStatus(contest.start_time, contest.duration) && registered && registered.registerStatus === "SUBMITTED" && <button disabled={true} id='take-test-btn' className={`btn rounded-1 border-0 py-2 px-3  fw-sb text-light text-hover-1 bg-1 bg-hover-light text-nowrap`} type="submit">
                                {
                                    loading ?
                                        <Spinner bsPrefix='mx-3 spinner' size='sm' animation="border" variant="light" />
                                        : "Submitted"
                                }
                            </button>}

                            {
                                checkStatus(contest.start_time, contest.duration) && !registered &&
                                <button onClick={handleRegisterForContest} id='take-test-btn' className={`btn rounded-1 border-0 py-2 px-3  fw-sb text-light text-hover-1 bg-1 bg-hover-light text-nowrap`} type="submit">
                                    Register
                                </button>
                            }

                            {
                                !checkStatus(contest.start_time, contest.duration) && ((new Date()) > (new Date(contest.end_time))) && contest.results_declared && registered && registered.registerStatus === 'DECLARED' &&
                                <button onClick={() => navigate(`/contest/result/${user?.userId}/${contest.id}`)} id='take-test-btn' className={`btn rounded-1 border-0 py-2 px-3  fw-sb text-light text-hover-1 bg-1 bg-hover-light text-nowrap`} type="submit">
                                    See Result
                                </button>
                            }
                            {
                                !checkStatus(contest.start_time, contest.duration) && ((new Date()) > (new Date(contest.end_time))) && contest.results_declared &&
                                <button id='take-test-btn' className={`btn rounded-1 border-0 py-2 px-3 mx-2  fw-sb text-light text-nowrap`} style={{ background: 'var(--green-200)' }} type="submit" onClick={handleStartVirtualContest}>
                                    Virtual Contest
                                </button>
                            }
                            {
                                !checkStatus(contest.start_time, contest.duration) && ((new Date()) > (new Date(contest.end_time))) && !contest.results_declared &&
                                <button disabled={true} id='take-test-btn' className={`btn rounded-1 border-0 py-2 px-3  fw-sb text-light text-hover-1 bg-1 bg-hover-light text-nowrap`} type="submit">
                                    Calculating results
                                </button>
                            }
                            {
                                !checkStatus(contest.start_time, contest.duration) && !((new Date()) > (new Date(contest.end_time))) && registered && registered.registerStatus === 'REGISTERED' &&
                                <div className="d-flex">
                                    <button disabled={true} id='take-test-btn' className={`btn rounded-1 border-0 py-2 px-3  fw-sb text-light text-hover-1 bg-1 bg-hover-light text-nowrap me-2`} type="submit">
                                        Registered
                                    </button>
                                    {/* <button onClick={setReminder} className={`btn rounded-1 border-0 py-2 px-3  fw-sb text-gray-3  bg-hover-light text-nowrap d-flex align-items-center bg-gray-1 justify-content-center`} type="submit">
                                        <img height={"28"} className='me-2' src={telegramIcon} />
                                        Get reminder
                                    </button> */}
                                </div>
                            }
                            {
                                !checkStatus(contest.start_time, contest.duration) && !((getISTTime()) > (new Date(contest.end_time))) && !registered &&
                                <div className='d-flex'>
                                    <button onClick={handleRegisterForContest} disabled={false} id='take-test-btn' className={`btn rounded-1 border-0 py-2 px-3  fw-sb text-light bg-hover-1-light text-hover-1 bg-1 bg-hover-light text-nowrap me-2`} type="submit">
                                        Register
                                    </button>
                                    {/* <button onClick={setReminder} className={`btn rounded-1 border-0 py-2 px-3  fw-sb text-gray-3  bg-hover-light text-nowrap d-flex align-items-center bg-gray-1 justify-content-center`} type="submit">
                                        <img height={"28"} className='me-2' src={telegramIcon} />
                                        Get reminder
                                    </button> */}
                                </div>
                            }
                        </div>
                    </div>
                    {contest.description && <div className='container-1 px-res-1 d-flex bg-white flex-column py-4 rounded-1'>
                        <div className="back fw-b fs-md text-gray-5 mb-4">Please read the instructions carefully</div>
                        <div className="back text-decoration-underline fs-sm text-gray-5 mb-4">General Instructions:</div>
                        <div className="d-flex align-items-center mb-5 fs-sm fw-r text-gray-2" dangerouslySetInnerHTML={{ __html: contest.description }}>
                        </div>
                    </div>}
                </div>
                {
                    !checkStatus(contest.start_time, contest.duration) && ((new Date()) > (new Date(contest.end_time))) && contest.results_declared &&
                    <div className='d-flex flex-column gap-3 mx-auto mx-xl-0 col-xl-4 col-xxl-3 col-12' style={{ flexGrow: '1' }}>
                        <TopFives data={restructureTable(standingData)} tableStructure={topRankings} link={`/contest/standings/${contest?.id}`} />
                        <VirtualLists data={virtualLists} activePage={activePage} totalElements={totalElements} totalPages={totalPages} setActivePage={setActivePage} number={activePage * pageSize} />
                    </div>
                }
            </div>
        </div>}
    </>

    )

}


const checkStatus = (start_time, duration) => {
    let test_start_time = (new Date(start_time))
    // let test_start_time = moment(start_time).tz('Asia/Kolkata')
    let test_end_time = (new Date(start_time))
    // let test_end_time = moment(start_time).tz('Asia/Kolkata')
    // test_end_time.add(duration, 'minutes')
    // console.log(test_start_time, test_end_time)
    test_end_time.setMinutes(test_start_time.getMinutes() + duration)
    // const current_date = moment().tz('Asia/Kolkata');
    let current_date = new Date();
    var currentOffset = current_date.getTimezoneOffset();
    var ISTOffset = 330;   // IST offset UTC +5:30 
    current_date = new Date(current_date.getTime() + (ISTOffset + currentOffset) * 60000);
    if (current_date > test_start_time && current_date < test_end_time) return true;
    return false;
}

const getISTTime = () => {
    let current_date = new Date();
    var currentOffset = current_date.getTimezoneOffset();
    var ISTOffset = 330;   // IST offset UTC +5:30 
    current_date = new Date(current_date.getTime() + (ISTOffset + currentOffset) * 60000);
    return current_date;
}

const topRankings = [
    {
        title: 'Rank',
        value: 'rank'
    },
    {
        title: 'Name',
        value: 'name'
    },
    {
        title: 'Score',
        value: 'studentTotalMarks'
    }
];

const restructureTable = (data) => {
    const updatedTable = data?.map((fields) => {
        return {
            ...fields, name: fields?.userId?.name, userId: fields?.userId?.userId
        }
    })
    return updatedTable;
}
