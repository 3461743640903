import React from 'react'
import Loader from '../components_App/Loader'
import Navbar from '../components_App/Navbar'
import Footer from '../components_App/Footer/Footer'
import BottomBar from '../components_App/BottomBar'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import bg from '../components_App/Home/home_assets/background.jpg';
import { useEffect } from 'react'
import Main from '../components_App/ContestLists/Main'
import { setCategories } from '../reducers/contest'
import { setLoading } from '../reducers/app'
import axios from 'axios'
import { API_URL } from '../config'
import Home from '../components_App/ContestLists/Home'
import { useNavigate, useParams } from 'react-router-dom'
import Topics from '../components_App/ContestLists/Topics'
import Questions from '../components_App/ContestLists/Questions'
import { header } from '../components_App/HeaderData'
import { filterQuery } from '../components_App/ContestLists/ContestListsData'

const ContestLists = () => {
    const jwtToken = useSelector((state) => state.user.jwtToken);
    const { subjectId, topicId } = useParams();
    console.log(subjectId, topicId)
    const dispatch = useDispatch()
    const [showLoginCanvas, setShowLoginCanvas] = useState(false);

    const [sessionData, setSessionData] = useState(null);

    //data
    const [subjects, setSubjects] = useState(null);
    const [topics, setTopics] = useState(null);
    const [questions, setQuestions] = useState(null);

    //pages
    const [activeTopicPage, setActiveTopicPage] = useState(0);
    const [totalTopicPages, setTotalTopicPages] = useState(0);
    const [activeQuestionPage, setActiveQuestionPage] = useState(0);
    const [totalQuestionPages, setTotalQuestionPages] = useState(0);

    //page sizes
    const topicSize = 12;
    const questionSize = 8;

    const [active, setActive] = useState(2);
    const { loading } = useSelector((state) => state.app);
    const selectedCategory = useSelector((state) => state.user.defaultCategory.id);

    //queries
    const [questionStatus, setQuestionStatus] = useState('');
    const [questionType, setQuestionType] = useState('');

    const navigate = useNavigate();

    //getting categories
    const getCategories = async () => {
        try {
            dispatch(setLoading(true))
            const res = await axios.get(API_URL + "/contestCategory");
            console.log("API URL===========", res.data);
            dispatch(setCategories(res.data))
            dispatch(setLoading(false))
        } catch (e) {
            dispatch(setLoading(false))
            console.log(e.message);
        }
    }

    //getting userdata session
    const getSessionData = async () => {
        // setSessionLoading(true);
        try {
            // console.log(jwtToken)
            const res = await axios.get(`${API_URL}/questionPractice/userData`, header(jwtToken));
            // console.log(jwtToken);
            console.log('session card data==============>', res)
            setSessionData(res?.data)
            // setSessionLoading(false);
        } catch (e) {
            console.log(e);
            // setSessionLoading(false);
        }
    }

    //getting list of subjects of a category
    const getListOfSubjects = async () => {
        try {
            if (!selectedCategory) return;

            const url = API_URL + `/questionSubject?contestCategoryId=${selectedCategory}`;

            const res = await axios.get(url);
            setSubjects(res?.data);
            // console.log(res);

        } catch (error) {
            console.log(error)
        }
    }

    //getting list of topics of a subject
    const getListOfTopics = async (page) => {
        try {
            if (!subjectId) return;

            const url = API_URL + `/questionTags/query?contestCategoryId=${selectedCategory}&page=${page}&size=${topicSize}&tagType=CHAPTER&subjectId=${subjectId}`;

            const res = await axios.get(url);

            setTopics(res?.data?.content);
            setTotalTopicPages(res?.data?.totalPages);

        } catch (error) {
            console.log(error);
        }
    }

    //getting list of Questions of a topic
    const getListOfQuestions = async (page) => {
        try {
            if (!topicId || !subjectId) return;
            const query = filterQuery('', '', '', questionType, questionStatus);

            const url = API_URL + `/questionPractice?contestCategoryId=${selectedCategory}&page=${page}&size=${questionSize}&questionSubjectId=${subjectId}&questionTags=${topicId}${query}`;

            const res = await axios.get(url, header(jwtToken));

            setQuestions(res?.data?.content);
            setTotalQuestionPages(res?.data?.totalPages);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setActiveQuestionPage(0);
        setTotalQuestionPages(0);
        setQuestionStatus('');
        setQuestionType('');
        setQuestions(null);
        getListOfQuestions(0);

        return () => { }
    }, [topicId]);

    useEffect(() => {
        const query = filterQuery('', '', '', questionType, questionStatus);
        if (subjectId && topicId)
            navigate(`/app/practice/${subjectId}/${topicId}?page=${activeQuestionPage + 1}${query}`);
        setQuestions(null);
        getListOfQuestions(activeQuestionPage);

        return () => { }
    }, [activeQuestionPage, questionStatus, questionType])

    useEffect(() => {
        setActiveTopicPage(0);
        setTotalTopicPages(0);
        setTopics(null)
        getListOfTopics(0);

        return () => { }
    }, [subjectId]);

    useEffect(() => {
        setTopics(null)
        getListOfTopics(activeTopicPage);

        return () => { }
    }, [activeTopicPage]);

    useEffect(() => {
        setSubjects(null)
        getListOfSubjects();

        return () => { }
    }, [selectedCategory]);

    useEffect(() => {
        if (jwtToken) {
            getSessionData();
        }
    }, [jwtToken])

    useEffect(() => {
        // navigate('/practice')
        getCategories();

        document.title = 'Contest | Unbind';
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);

    }, [jwtToken, selectedCategory, activeTopicPage, activeQuestionPage, topicId, subjectId])

    return (
        <div className='d-flex flex-column justify-content-between app_container' style={{ minHeight: '100vh' }}>
            {loading && <Loader />}
            <div>
                {/* <Navbar showCategories={true} showButtons={true} active={active} setActive={setActive} showLoginCanvas={showLoginCanvas} setShowLoginCanvas={setShowLoginCanvas} /> */}
                {/* <Main /> */}
                {
                    topicId ?
                        <Questions
                            activeSubject={getActiveSubject(subjectId, subjects)}
                            topics={topics}
                            activeTopic={getActiveTopic(topicId, topics)}
                            questionLists={questions}
                            activePage={activeQuestionPage}
                            setActivePage={setActiveQuestionPage}
                            totalPages={totalQuestionPages}
                            pageSize={questionSize}
                            questionStatus={questionStatus}
                            questionType={questionType}
                            setQuestionStatus={setQuestionStatus}
                            setQuestionType={setQuestionType}
                        />
                        :
                        subjectId ?
                            <Topics
                                activeSubject={getActiveSubject(subjectId, subjects)}
                                subjectLists={subjects}
                                topics={topics}
                                activePage={activeTopicPage}
                                totalPages={totalTopicPages}
                                setActivePage={setActiveTopicPage}
                            />
                            :
                            <Home
                                subjectLists={subjects}
                                sessionData={sessionData}
                            />
                }
            </div>
            <div className=''>
                {/* <Footer /> */}
                {/* <BottomBar active={active} setActive={setActive} /> */}
            </div>
        </div>
    )
}

export default ContestLists;

const getActiveSubject = (subjectId, subjects) => {
    const activeSubject = subjects?.filter((subject) => subject.id === subjectId)[0];

    return activeSubject;
}

const getActiveTopic = (topicId, topics) => {
    console.log(topicId)
    const activetopic = topics?.filter((topic) => topic.id === topicId)[0];
    console.log(activetopic)

    return activetopic;
}
