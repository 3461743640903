import React, { useState } from 'react';
import { HiChevronDoubleDown, HiChevronDoubleUp } from 'react-icons/hi';
import './TermsAndPrivacy.css';

const DropDown = ({ data, index, open, setOpen }) => {
  // const [view, setView] = useState(index === 0 ? true : false);
  return (
    <div className={`p-3 rounded-1`} style={{ background: 'var(--primary)', color: 'white' }}>
      <div className='px-3 fs-5 d-flex justify-content-between gap-2 cursor' onClick={() => { open === index ? setOpen(-1) : setOpen(index) }}>
        <div className=''>{data.question}</div>
        <div style={{ cursor: 'pointer', width: '30px' }} >
          {
            open === index ?
              <HiChevronDoubleUp />
              :
              <HiChevronDoubleDown />
          }
        </div>
      </div>
      <div className={`termDropdown ${open === index ? 'termOpen' : ''}`}>
        <div className='mx-3 my-3' style={{ borderTop: '1px solid white' }} />
        <div
          className='p-3 m-3 rounded-1'
          style={{ background: 'white', color: 'var(--gray-600)', maxHeight: '250px', overflow: 'auto' }}
          dangerouslySetInnerHTML={{ __html: data.answer }}
        />
      </div>
    </div>
  )
}

export default DropDown
