import React from 'react'
import Left from './Left'
import Right from './Right'

const Main = ({ title, type }) => {
  return (
    <div className='col-md-10 pt-3 mb-3 col-11 mx-auto'>
      <div className='fw-medium font3' style={{ color: 'var(--gray-200)' }}>{title}</div>
      <div className='my-4 d-flex justify-content-between flex-wrap gap-3'>
        <Left type={type} />
        <Right type={type} />
      </div>
    </div>
  )
}

export default Main
