import React, { useEffect } from 'react'

export default function NotFound() {
    useEffect(() => {
        window.location.replace('/');
    }, [])
    
  return (
    <div>
    </div>
  )
}
