import React from 'react'
import Loader from '../components/Loader'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import BottomBar from '../components/BottomBar'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import bg from '../components/Home/home_assets/background.jpg';
import { useEffect } from 'react'
import Main from '../components/Results/Main'

const Results = () => {
    const dispatch = useDispatch()
    const [showLoginCanvas, setShowLoginCanvas] = useState(false)
    const [active, setActive] = useState(2);
    const jwtToken = useSelector((state) => state.user.jwtToken)
    const { loading } = useSelector((state) => state.app)

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Contest | Unbind';
    }, []);
    return (
        <div className='d-flex flex-column justify-content-between' style={{ minHeight: '100vh' }}>
            {loading && <Loader />}
            <div>
                <Navbar showCategories={true} showButtons={true} active={active} setActive={setActive} showLoginCanvas={showLoginCanvas} setShowLoginCanvas={setShowLoginCanvas} />
                <Main />
            </div>
            <div>
                <Footer />
                <BottomBar active={active} setActive={setActive} />
            </div>
        </div>
    )
}

export default Results
