import React from 'react'
import '../styles/table.css'
import multiavatar from '@multiavatar/multiavatar'
import { useNavigate } from 'react-router-dom'

export default function Table({ data, heading, type, activePage }) {
    const navigate = useNavigate()
    return (<>
        {type === "registeredusers" && registeredusersTable(data, heading, navigate, activePage)}
    </>
    )
}

const registeredusersTable = (data, heading, navigate, activePage) => {
    return (<div className='table-outer-cnt w-100'>
        <div className="table-heading fw-sb fs-sm mb-4">
            {heading}
        </div>
        <div className="table-inner-cnt">
            <div className='table-entry py-4 align-items-center'>
                <div style={{ width: "40px", color: 'white' }} className="fs-xsm me-4 text-2">S.no</div>
                <div className="fs-xsm text-2" style={{ color: 'white' }}>User</div>
                <div className="fs-xsm text-2" style={{ color: 'white' }}>Rating</div>
            </div>
            {data.map((item, index) => {
                return (
                    <>
                        <div key={index} className='table-entry py-4 align-items-center'>
                            <div className="table-entry-first me-3 text-2 fw-r d-flex justify-content-center align-items-center rounded-2" style={{ border: '1px solid var(--white)', color: 'white', minWidth: '30px', minHeight: '30px' }}>{activePage * 15 + index + 1}</div>
                            <div onClick={() => {
                                window?.ReactNativeWebView?.postMessage(JSON.stringify({ userId: item.user.userId }))
                            }} className="table-entry-second d-flex fw-sb hover-pointer">
                                <div className="table-entry-first me-4 fw-r hover-pointer" style={{ width: '35px' }} dangerouslySetInnerHTML={{ __html: multiavatar(item.user.name) }}></div>
                                <div>
                                    <div >
                                        {item.user.name}
                                    </div>
                                    <div className='fs-xsm fw-l text-2'>
                                        @{item.user.userId}
                                    </div>
                                </div>
                            </div>
                            <div className="table-entry-third">{item.user.rating ? item.user.rating : 0}</div>
                        </div>
                    </>
                )
            })}

            {data.length === 0 &&
                <div className='py-5 d-flex align-items-center justify-content-center text-2'>
                    No registrations yet
                </div>
            }
        </div>
    </div>)
}
