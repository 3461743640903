import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie';


export default function VerifyToken() {
    const params = useParams();
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const navigate = useNavigate()
    useEffect(() => {
        if (params.token) {
            removeCookie('token', { path: '/' })
            removeCookie('token', { path: '/token' })
            let token = params.token;
            token = token.replace('unbindTokenSeparator', '.');
            token = token.replace('unbindTokenSeparator', '.');
            setCookie('token', token, {
                path: '/'
            });
            navigate('/')
        }
    }, [params])

    return (
        <div className='container d-flex align-item-center justify-content-center' style={{ height: "100vh" }}>VerifyOtp</div>
    )
}
