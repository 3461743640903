import React from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { BiCalendar } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkStatus } from './ContestsData';
import multiavatar from '@multiavatar/multiavatar';

const CardComponent = ({ data, type, img, status }) => {
    const user = useSelector((state) => state.user.user);
    const buttonType = [
        { title: 'Register', link: `/contest/contest-detail/${data?.id}`, link1: `/contest/contestRegistrants/${data?.id}` },
        { title: 'Virtual', link: `/contest/contest-detail/${data?.id}`, link1: `/contest/standings/${data?.id}` },
        { title: 'Result', link: `/contest/result/${user?.userId}/${data?.id}`, link1: `/contest/contestRegistrants/${data?.id}` },
    ]
    return (
        <div className='rounded-4 py-3 px-2 d-flex justify-content-between' style={{ backgroundColor: 'var(--gray-100)' }}>
            <div className='col-md-3 col-0'>
                <img src={img} className='col-md-12 d-none d-md-block' alt={data?.name} />
            </div>
            <div className='col-md-5 col-7 d-flex flex-column gap-2 justify-content-center'>
                <div className='fw-medium'>
                    <Link className='linkFoot font-150' to={`/contest/contest-detail/${data?.id}`}>{data?.name}</Link>
                </div>
                <div className='d-flex gap-2 align-items-center font-80' style={{ color: 'var(--gray-700)' }}>
                    <div className='d-flex gap-1 align-items-center'>
                        <div className='font-100 d-flex align-items-center'>
                            <BiCalendar />
                        </div>
                        <span>
                            {(new Date(data?.start_time)).toLocaleDateString("en-IN", { timeZone: "Asia/Kolkata" }) + " "}
                        </span>
                    </div>
                    <div className='d-flex gap-1 align-items-center'>
                        <span className='font-100 d-flex align-items-center'>
                            <AiOutlineClockCircle />
                        </span>
                        <span>
                            {(new Date(data?.start_time)).toLocaleTimeString('en-IN', { timeZone: "Asia/Kolkata" })}
                        </span>
                    </div>
                </div>
                <div className='rounded-5 px-2 my-1 font-80' style={{ width: 'fit-content', color: 'var(--gray-700)', backgroundColor: '#FFD592' }}>
                    Duration: {data?.duration}
                </div>
            </div>
            <div className='col-md-3 col-5 gap-2 d-flex flex-column justify-content-center align-items-end p-md-3 p-1'>
                <Link
                    aria-label='unbind-contest-info-button'
                    to={redirectBtn(type, data, status, user)}
                    className='fw-bold rounded-1 px-md-3 px-2 py-md-2 py-1 font-100 d-flex justify-content-center contest-card-btn'
                    style={{
                        backgroundColor: `${checkBtnStatus(type, data, status) ? '#3D944B' : 'var(--gray-500)'}`,
                        cursor: `${checkBtnStatus(type, data, status) ? 'pointer' : 'default'}`,
                        color: 'white',
                        border: '0',
                        outline: 'none'
                    }}
                >
                    {
                        type === 0 ?
                            data?.btnStatus ?
                                data?.btnStatus
                                :
                                'Register'
                            :
                            type === 2 ?
                                status === 'REGISTERED' ?
                                    (!checkStatus(data?.start_time, data?.duration) && new Date() > new Date(data?.end_time)) ?
                                        "Unattempted"
                                        :
                                        'Registered'
                                    :
                                    'Result'
                                :
                                buttonType[type].title
                    }
                </Link>
                <Link aria-label='unbind-users' to={buttonType[type].link1} className='d-flex align-items-center justify-content-center position-relative font-80' style={{ cursor: 'pointer' }}>
                    <div className='position-relative d-flex' style={{ right: '25px' }}>
                        {
                            data?.noOfStudentsRegistered > 0 &&
                            <div className='rounded-5' style={{ width: '20px' }} dangerouslySetInnerHTML={{ __html: multiavatar(data?.name[data?.start_time]) }}></div>
                        }
                        {
                            data?.noOfStudentsRegistered > 1 &&
                            <div className='rounded-5 position-absolute' style={{ width: '20px', left: '10px' }} dangerouslySetInnerHTML={{ __html: multiavatar(data?.end_time) }}></div>
                        }
                        {
                            data?.noOfStudentsRegistered > 2 &&
                            <div className='rounded-5 position-absolute' style={{ width: '20px', left: '20px' }} dangerouslySetInnerHTML={{ __html: multiavatar(data?.name) }}></div>
                        }
                    </div>
                    <div className='' style={{ color: 'var(--gray-600)' }}>{type === 1 ? 'Standings' : 'Register'} {type !== 1 && data?.noOfStudentsRegistered}</div>
                </Link>
            </div>
        </div>
    )
}

export default CardComponent

const checkBtnStatus = (type, data, status) => {
    if (type === 0) {
        if (checkStatus(data?.start_time, data?.duration)) {
            // if (data?.registerStatus === 'REGISTERED')
            return true;
            // else
            //     return false
        }
        else {
            if (!data?.registerStatus)
                return true;
            else
                return false;
        }
    }
    else if (type === 2) {
        if (status === 'REGISTERED')
            return false
        else
            return true
    }
    else {
        return true
    }
}

const redirectBtn = (type, data, status, user) => {
    if (type === 2) {
        if (status === 'REGISTERED')
            return `/contest/contest-detail/${data?.id}`;
        else
            return `/contest/result/${user?.userId}/${data?.id}`;
    }
    else {
        return `/contest/contest-detail/${data?.id}`;
    }
}
