import React from 'react'
import cup from '../Contest_assets/cup.png';
import { useSelector } from 'react-redux';

const RatingCard = () => {
  const user = useSelector((state) => state.user.user)
  return (
    <div className='d-flex gap-3 col-md-12 d-flex justify-content-center align-items-center py-4 rounded-4' style={{ background: 'linear-gradient(91.1deg, #192F4D 0.83%, #4550C7 99.15%)', color: 'white' }}>
      <img src={cup} className='col-md-3 col-2' alt={'unbind-user-rating'} />
      <div className='d-flex flex-column justify-content-center'>
        <div className='font-120'>
          Your Rating
        </div>
        <div className='font-150 fw-bolder'>
          {user?.rating ? user?.rating : 0}
        </div>
      </div>
    </div>
  )
}

export default RatingCard
