
import axios from "axios";
import { API_URL } from "../config";
import { setDefaultCategory } from "../reducers/user";

export const postDeaultCategory = async (category, jwtToken) => {
    try {
        const res = await axios.post(API_URL + '/user/setChosenCategory', category, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + jwtToken
            }
        });
    } catch (e) {
        console.log(e)
    }
}


