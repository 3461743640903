import React from 'react'
import { AiFillCaretRight } from 'react-icons/ai'
import status1 from '../ContestLists/contestLists_assets/ac.png';
import status2 from '../ContestLists/contestLists_assets/pr.png';
import status3 from '../ContestLists/contestLists_assets/wr.png';

const QuestionListCard = ({ data, handleClick, no, activeQuestionRef }) => {

    return (
        <div className='d-flex align-items-center gap-0 col-12'>

            {/* Question Content Start */}
            <div onClick={() => handleClick(no, data)} ref={data?.active ? activeQuestionRef : null} className='d-flex flex-column gap-3 col-md-11 col-12 p-md-4 p-3 rounded-2' style={{ background: 'var(--gray-200)', border: data?.active ? '2px solid var(--green-100)' : '0px', cursor: 'pointer' }}>

                {/* Question Title Section Start */}
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='px-2 py-1 rounded-5' style={{ background: 'var(--gray-100)', fontWeight: '600', color: 'var(--gray-700)' }}>
                        Question {data?.number}
                    </div>
                    <div className=''>
                        {
                            data?.questionStatus!=='NA' &&
                            <img src={getStatusImg(data?.questionStatus)} alt={data?.questionStatus} />
                        }
                    </div>
                </div>
                {/* Question Title Section End */}

                {/* Question Start */}
                <div className='' style={{ maxHeight: '100px', overflowX: 'auto' }} dangerouslySetInnerHTML={{ __html: data?.question }}>
                </div>
                {/* Question End */}

            </div>
            {/* Question Content End */}

            {/* Question Select Start */}
            {
                data?.active &&
                <div className='col-md-1 d-none d-md-block' style={{ color: 'var(--green-100)', fontSize: '1.5rem' }}>
                    <AiFillCaretRight className='position-relative' style={{ left: '-0.5rem' }} />
                </div>
            }
            {/* Question Select End */}

        </div>
    )
}

export default QuestionListCard;

const getStatusImg = (status) => {
    if (status === 'AC')
        return status1
    else if (status === 'PR')
        return status2
    else if (status === 'WR')
        return status3
    return status;
}
