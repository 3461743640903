import React from 'react'

const MarksCategory = ({title, marks, color}) => {
    return (
        <div className='d-flex gap-2 align-items-center'>
            <div className='' style={{ width: '1rem', height: '1rem', background: color }}>
            </div>
            <div className=''>
                {title}: <span className='fw-bolder'>{marks}</span>
            </div>
        </div>
    )
}

export default MarksCategory
