import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { API_URL } from '../config'
import Navbar from '../components/Navbar'
import Table from '../components/Table'
import { useParams } from 'react-router-dom'
import { Pagination } from 'react-bootstrap'
import { setCategories } from '../reducers/contest'

export default function ContestRegistrantions() {
    const dispatch = useDispatch()
    const [active, setActive] = useState(2);
    const [data, setData] = useState([])
    const [prevPageNumber, setPrevPageNumber] = useState(0)
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [nextPageNumber, setNextPageNumber] = useState(2)
    const [totalPages, setTotalPages] = useState(1)
    const [showLoginCanvas, setShowLoginCanvas] = useState(false)

    const { id } = useParams()

    useEffect(() => {
        getRegisteredUsers();
        getCategories();
        document.title = 'Contest | Unbind';

    }, [currentPageNumber])

    const getRegisteredUsers = async () => {
        try {
            const res = await axios.get(`${API_URL}/registerForContest/query?contestId=${id}&page=${currentPageNumber - 1}&size=15`);
            console.log("registered users=============", res.data)
            setTotalPages(res.data.totalPages)
            setData(res.data.content);
        } catch (e) {
            console.log(e)
        }
    }

    const getCategories = async () => {
        try {
            const res = await axios.get(API_URL + "/contestCategory");
            console.log("API URL===========", res.data);
            dispatch(setCategories(res.data))
        } catch (e) {
            console.log(e.message);
        }
    }



    const prevPage = () => {
        setCurrentPageNumber(currentPageNumber - 1)
        setPrevPageNumber(prevPageNumber - 1)
        setNextPageNumber(nextPageNumber - 1)
    }
    const nextPage = () => {
        setCurrentPageNumber(currentPageNumber + 1)
        setPrevPageNumber(prevPageNumber + 1)
        setNextPageNumber(nextPageNumber + 1)
    }
    const setPage = (page) => {
        setCurrentPageNumber(page)
        setPrevPageNumber(page - 1)
        setNextPageNumber(page + 1)
    }

    return (
        <div>
            <Navbar showCategories={true} showButtons={true} active={active} setActive={setActive} showLoginCanvas={showLoginCanvas} setShowLoginCanvas={setShowLoginCanvas} />
            <div className="grid-1 container mt-3 pt-5 d-flex flex-column align-items-center">
                <Table heading="Registered users" data={data} type="registeredusers" activePage={currentPageNumber-1} />
                <Pagination bsPrefix="pagination my-5 fw-l">
                    <Pagination.First onClick={() => { setCurrentPageNumber(1) }} />
                    <Pagination.Prev onClick={prevPage} disabled={prevPageNumber === 0} />
                    <Pagination.Item onClick={() => { setPage(prevPageNumber) }} disabled={prevPageNumber === 0} >{prevPageNumber}</Pagination.Item>
                    <Pagination.Item onClick={() => { setCurrentPageNumber(1) }} active>{currentPageNumber}</Pagination.Item>
                    <Pagination.Item onClick={() => setPage(nextPageNumber)} disabled={totalPages <= nextPageNumber - 1} >{nextPageNumber}</Pagination.Item>
                    <Pagination.Next onClick={nextPage} disabled={totalPages <= nextPageNumber - 1} />
                    <Pagination.Last onClick={() => { setCurrentPageNumber(totalPages) }} />
                </Pagination>
            </div>

        </div>
    )
}
