import React, { useEffect, useState } from 'react';
import './Profile.css';
import Sidebar from './Sidebar';
import Dashboard from './dashboard/Dashboard';
import Refer from './refer/Refer';
import { useLocation } from 'react-router-dom';
import BookMark from './bookmarks/BookMark';
import Talk from './talk/Talk';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';

const Main = ({ setActivePage, bookmarks, totalBookmarks, activePage, totalPages, getBookmarks, pageSize }) => {
  const location = useLocation();

  const { jwtToken } = useSelector((state) => state?.user);

  // console.log(totalBookmarks)

  useEffect(() => {

  }, [location]);

  return (
    <div className={`col-md-12 col-12 mx-auto ${jwtToken ? 'ProfileContainer' : ''}`} style={{ flex: '1', overflowY: 'auto' }}>
      {/* ---------------------------------------------------------- */}
      {/* Sidebar Section Start */}
      {/* ---------------------------------------------------------- */}
      <div className={`d-lg-${jwtToken ? 'block' : 'none'} d-none`} style={{ gridArea: '1/1/2/2' }}>
        <Sidebar totalBookmarks={totalBookmarks} />
      </div>
      {/* ---------------------------------------------------------- */}
      {/* Sidebar Section Start */}
      {/* ---------------------------------------------------------- */}

      {/* ---------------------------------------------------------- */}
      {/* Profile Overview Section Start */}
      {/* ---------------------------------------------------------- */}
      <div className={`d-flex flex-column gap-4 ${jwtToken ? 'profile-overview-container mx-lg-0 me-lg-3' : ''} mt-1 mx-2 mb-4 mb-sm-2`} style={{ overflowY: 'auto' }}>
        {
          location.pathname?.includes('profile') ?
            <Dashboard />
            :
            location.pathname?.includes('refer') ?
              <Refer />
              :
              location.pathname?.includes('bookmark') ?
                <BookMark totalPages={totalPages} bookmarks={bookmarks} activePage={activePage} setActivePage={setActivePage} getBookmarks={getBookmarks} pageSize={pageSize} />
                :
                location.pathname?.includes('talk-sessions') ?
                  <Talk />
                  :
                  <Dashboard />
        }
      </div>
      {/* ---------------------------------------------------------- */}
      {/* Profile Overview Section End */}
      {/* ---------------------------------------------------------- */}

      {/* ------------------------------------------------------------- */}
      {/* popup section */}
      {/* ------------------------------------------------------------- */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export default Main
