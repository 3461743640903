import React from 'react'
import multiavatar from '@multiavatar/multiavatar';
import { Link } from 'react-router-dom';


const Table2 = ({ data, tableStructure, startRank, fontStyle, headingBg, headingColor }) => {
    return (
        <table className='col-12'>
            <thead>
                <tr className={`rounded-2 ${fontStyle}-100`} style={{ background: headingBg ? headingBg : 'var(--primary)', color: headingColor ? headingColor : 'var(--white)' }}>
                    {
                        tableStructure?.map((fields, index) =>
                            <th className='fw-5 p-2' style={{ width: index == 1 ? '60%' : '20%' }} key={index}>
                                {fields?.title}
                            </th>
                        )
                    }
                </tr>
            </thead>
            <tbody>
                {
                    data?.map((item, index) => (
                        <tr key={index} className={`${fontStyle}-100 border border-b`} style={{ background: 'var(--white)' }}>
                            {
                                tableStructure?.map((fields, index2) =>
                                    index2 === 0 ?
                                        <td className='p-2 px-3' key={index2}>
                                            {
                                                (startRank + index + 1) < 10 ?
                                                    <div className='p-2 rounded d-flex justify-content-center align-items-center' style={{ background: 'var(--gray-200)', width: 'fit-content', minWidth: '2.2rem' }}>{'0' + (startRank + index + 1)}</div>
                                                    :
                                                    <div className='p-2 rounded d-flex justify-content-center align-items-center' style={{ background: 'var(--gray-200)', width: 'fit-content' }}>{(startRank + index + 1)}</div>
                                            }
                                        </td>
                                        :
                                        index2 === 1 ?
                                            <td className='p-2 px-1 px-md-3' key={index2}>
                                                <div aria-label={item?.name} className='d-flex align-items-center'>
                                                    <div className='icon-cnt rounded-5' dangerouslySetInnerHTML={{ __html: multiavatar(item?.name) }}></div>
                                                    <Link to={`/profile/${item?.userId}`} className='d-flex flex-column gap-1 cursor'>
                                                        <div style={{ color: 'var(--gray-800)' }}>{item?.name}</div>
                                                        <div className={`${fontStyle}-80`} style={{ color: 'var(--gray-600)' }}>{item?.userId}</div>
                                                    </Link>
                                                </div>
                                            </td>
                                            :
                                            <td className='p-2' key={index2}>
                                                {
                                                    item[fields?.value]
                                                }
                                            </td>
                                )
                            }
                        </tr>
                    ))
                }
            </tbody>
        </table>

    )
}

export default Table2
