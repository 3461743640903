import React from 'react'
import Loader from '../components/Loader'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import BottomBar from '../components/BottomBar'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { useEffect } from 'react'
import Main from '../components_App/Standings_App/Main'

const Standings_App = () => {
    const dispatch = useDispatch()
    const [showLoginCanvas, setShowLoginCanvas] = useState(false)
    const [active, setActive] = useState(2);
    const jwtToken = useSelector((state) => state.user.jwtToken)
    const { loading } = useSelector((state) => state.app)

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Contest | Unbind';
    }, []);
    return (
        <div className='d-flex flex-column justify-content-between app_container' style={{ minHeight: '100vh' }}>
            {loading && <Loader />}
            {/* <div> */}
                {/* <Navbar showCategories={true} showButtons={true} active={active} setActive={setActive} showLoginCanvas={showLoginCanvas} setShowLoginCanvas={setShowLoginCanvas} /> */}
                <Main />
                {/* <Footer /> */}
            {/* </div> */}
            {/* <BottomBar active={active} setActive={setActive} /> */}
        </div>
    )
}

export default Standings_App
