import React from 'react'
import { BiCalendar } from 'react-icons/bi'
import { BsPeopleFill } from 'react-icons/bs'
import { MdOutlineWatchLater, MdShare } from 'react-icons/md'
import { AiOutlineLink } from 'react-icons/ai';
import { Link } from 'react-router-dom'
import { handleCopyLink } from '../Profile/talk/TalkData';

const TalkDetailCard = ({ data, handleButton }) => {
    return (
        <div className='position-relative d-flex flex-column gap-3' style={{ width: '100%', height: '100%', maxHeight: '83vh', overflowY: 'auto' }}>
            {/* ------------------------------------------------------------------- */}
            {/* image Section start  */}
            {/* ------------------------------------------------------------------- */}
            <img src={data?.imageUrl ? data?.imageUrl : talk} alt='' className='talk-container-img rounded-2' />
            {/* ------------------------------------------------------------------- */}
            {/* image Section end  */}
            {/* ------------------------------------------------------------------- */}

            <div className='font-150 fw-6' >
                {data?.name}
            </div>

            {/* ------------------------------------------------------------------- */}
            {/* brief Section start  */}
            {/* ------------------------------------------------------------------- */}
            <div className='px-3 d-flex flex-column gap-2'>
                <div className='d-flex gap-2 align-items-center'>
                    <div className='font-150'>
                        <BiCalendar />
                    </div>
                    <div className=''>
                        {data?.formattedDateTime}
                    </div>
                </div>
                <div className='d-flex gap-2 align-items-center'>
                    <div className='font-150'>
                        <MdOutlineWatchLater />
                    </div>
                    <div className=''>
                        {data?.duration} min
                    </div>
                </div>
                <div className='d-flex gap-2 align-items-center'>
                    <div className='font-150'>
                        <BsPeopleFill />
                    </div>
                    <div className=''>
                        {data?.noOfStudentsRegistered} registrations
                    </div>
                </div>
                <div className='d-flex gap-2 align-items-center'>
                    <div className='font-150'>
                        <AiOutlineLink />
                    </div>
                    <div className='color-primary cursor' onClick={() => { handleCopyLink(data?.joinLink) }}>
                        {data?.joinLink}
                    </div>
                </div>
            </div>
            {/* ------------------------------------------------------------------- */}
            {/* brief Section start  */}
            {/* ------------------------------------------------------------------- */}

            {/* ------------------------------------------------------------------- */}
            {/* detail Section start  */}
            {/* ------------------------------------------------------------------- */}
            <div className='p-3 px-1 d-flex flex-column gap-2' style={{ flexGrow: '1' }}>
                <div className='font-100 fw-6' >
                    Description
                </div>
                <div className='font-100 fw-4' style={{ whiteSpace: 'pre-line' }}>
                    {data?.description}
                </div>
            </div>
            {/* ------------------------------------------------------------------- */}
            {/* detail Section end  */}
            {/* ------------------------------------------------------------------- */}

            <div className=''>
                <div onClick={handleButton} className='px-4 py-2 rounded-1 font-120 fw-5 cursor mb-3 me-3 position-fixed bottom-0 end-0' style={{ background: 'var(--primary)', color: 'var(--white)' }}>
                    {
                        !data?.isRegistered ?
                            'Register'
                            :
                            (new Date(data?.start_time) <= new Date() && new (data?.start_time + data?.duration * 60000) >= new Date()) ?
                                'Join'
                                :
                                'Registered'
                    }
                </div>
            </div>
        </div >
    )
}

export default TalkDetailCard
