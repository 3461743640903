import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { getColor } from './ProfileData';

const MenuItem = ({ data, setShowSidebar }) => {
    const { id } = useParams();
    const location = useLocation();
    return (
        <Link to={`/${data?.link}`} onClick={() => setShowSidebar && setShowSidebar(false)} className='d-flex gap-2 align-items-center fs-5 fw-5' style={{ color: getColor(location, data?.link), fontWeight: '300' }}>
            <div className='d-flex justify-content-center align-items-center'>
                {data?.icon}
            </div>
            <div className=''>
                {data?.name}
            </div>
        </Link>
    )
}

export default MenuItem
