import React from 'react';
import { AiOutlinePoweroff } from 'react-icons/ai';
import bg from './Profile_assets/background.png';
import { getMenuItems } from './ProfileData';
import MenuItem from './MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import multiavatar from '@multiavatar/multiavatar';
import { CiSettings } from 'react-icons/ci';
import { BsBookmark, BsGraphUpArrow } from 'react-icons/bs';
import { alertMsg } from '../Alert/AlertData';
import { setDefaultCategory, setJwtToken, setUser } from '../../reducers/user';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ totalBookmarks, setShowSidebar }) => {
  const { user } = useSelector((state) => state.user);
  const selectedCategory = useSelector((state) => state.user.defaultCategory);

  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  const navigate=useNavigate();

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(setJwtToken(null));
    dispatch(setDefaultCategory(null));
    dispatch(setUser(null))
    removeCookie("token", {
      path: "/"
    })
    navigate('/');
  }

  // console.log(totalBookmarks);

  return (
    <div className='d-flex flex-column gap-4 p-3' style={{ background: 'var(--white)', height: '100%' }}>
      {/* --------------------------------------------------------------- */}
      {/* Card section start */}
      {/* --------------------------------------------------------------- */}
      <div className='rounded-4 d-flex flex-column gap-3 p-4 font-profile-90' style={{ color: 'var(--white)', background: `url(${bg})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
        {/* --------------------------------------------------------------- */}
        {/* Name section start */}
        {/* --------------------------------------------------------------- */}
        <div className='d-flex gap-2 align-items-center'>
          <div className='width-profile-50' dangerouslySetInnerHTML={{ __html: multiavatar(user?.name) }}>
          </div>
          <div className='d-flex flex-column'>
            <div className=''>
              {user?.name}
            </div>
            <div className='font-profile-80'>
              @{user?.userId}
            </div>
          </div>
        </div>
        {/* --------------------------------------------------------------- */}
        {/* Name section end */}
        {/* --------------------------------------------------------------- */}

        {/* --------------------------------------------------------------- */}
        {/* Category section start */}
        {/* --------------------------------------------------------------- */}
        <div className='d-flex gap-2 align-items-center'>
          <div className=''>
            <img src={selectedCategory?.imageUrl} style={{ width: '30px' }} alt='' />
          </div>
          <div className=''>
            {selectedCategory?.name}
          </div>
        </div>
        {/* --------------------------------------------------------------- */}
        {/* Category section end */}
        {/* --------------------------------------------------------------- */}

        <div className='d-flex gap-2 align-items-center font-5 font-profile-80' style={{ color: 'var(--yellow-200)' }}>
          <div className='rounded-5 p-1 px-2 d-flex align-items-center gap-1' style={{ border: `2px solid var(--yellow-200)` }}>
            <BsGraphUpArrow />
            <div>{user?.rating ? user?.rating : 0}</div>
          </div>
          <div className='rounded-5 p-1 px-2 d-flex align-items-center gap-1' style={{ border: `2px solid var(--yellow-200)` }}>
            <BsBookmark />
            <div>{totalBookmarks}</div>
          </div>
        </div>
      </div>
      <div className='d-flex flex-column justify-content-between gap-3 px-lg-4 px-3' style={{ flexGrow: '1' }}>
        {/* --------------------------------------------------------------- */}
        {/* Menu items section start */}
        {/* --------------------------------------------------------------- */}
        <div className='d-flex flex-column gap-3'>
          <div className='d-flex flex-column gap-3'>
            {
              getMenuItems(user?.userId).map((menu, index) =>
                <MenuItem data={menu} key={index} setShowSidebar={setShowSidebar} />
              )
            }
            <div onClick={() => alertMsg('error', 'Currently Unavailable')} className='d-flex gap-2 align-items-center fs-5 fw-5 cursor' style={{ color: 'var(--gray-700)', fontWeight: '300' }}>
              <div className='d-flex justify-content-center align-items-center'>
                <CiSettings />
              </div>
              <div className=''>
                Settings
              </div>
            </div>
          </div>
        </div>
        {/* --------------------------------------------------------------- */}
        {/* Menu items section start */}
        {/* --------------------------------------------------------------- */}

        <div className='d-flex justify-content-center align-items-center rounded-2 fs-5 gap-2 p-2 py-1 cursor profile-btn' onClick={handleLogout}>
          <AiOutlinePoweroff />
          <div className=''>
            Log Out
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
