import React, { useEffect, useRef } from 'react'
import QuestionListCard from '../Card_components/QuestionListCard'
import { useNavigate, useParams } from 'react-router-dom'

const QuestionList = ({ data, activePage, pageSize, query, setData }) => {
    const questionData = {
        question: '<p>Green chemistry means such reactions which:</p>',
        questionStatus: 'accepted'
    }
    const { subjectId, topicId, questionId } = useParams();
    const activeQuestionRef = useRef(null);
    const navigate = useNavigate();

    const handleClick = (number, d) => {
        const updatedData = data?.filter((ques, index) => index === number)[0];
        setData(updatedData);
        navigate(`/app/practice/${subjectId}/${topicId}/${d?.id}?page=${activePage + 1}&question=${activePage * pageSize + number + 1}${query}`);
    }

    useEffect(() => {
        if (activeQuestionRef.current) {
            activeQuestionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [questionId]);

    return (
        <div className='d-flex flex-column gap-4' style={{ overflowY: 'auto', height: '60vh', flexGrow: '1' }}>
            {
                data?.map((d, index) =>
                    <QuestionListCard
                        data={{ ...d, number: activePage * pageSize + index + 1, active: d.id === questionId }}
                        no={index}
                        handleClick={handleClick}
                        activeQuestionRef={activeQuestionRef}
                        key={index}
                    />
                )
            }
        </div>
    )
}

export default QuestionList
