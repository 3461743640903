import React from 'react'
import { Link } from 'react-router-dom';
import referImg from './Referral_assets/Refer.png';
import { handleCopyClick } from './Refer_data';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdContentCopy } from 'react-icons/md';

const TopSection = ({ code }) => {

    return (
        <div className='d-flex flex-row flex-wrap-reverse justify-content-between align-items-center gap-2'>
            {/* ------------------------------------------------------------- */}
            {/* Left Section Start */}
            {/* ------------------------------------------------------------- */}
            <div className='d-flex flex-column gap-4 col-md-6 col-12'>
                <div className='font-300 fw-bold text-center text-md-start referral-title'>
                    Refer friends, win exciting rewards
                </div>
                <div className='shadow-sm d-flex justify-content-between p-3 rounded align-items-center gap-3 referral-card'>
                    <div className='d-flex flex-column gap-1' style={{ maxWidth: '70%' }}>
                        <div className='d-flex align-items-center gap-2'>
                            <div className='font-refer-100'>
                                Your Referral link
                            </div>
                            <div className='cursor' onClick={() => handleCopyClick(code)}>
                                <MdContentCopy />
                            </div>
                        </div>
                        <div className='font-refer-95' style={{ fontWeight: '600' }}>
                            <Link to='/refer'>www.unbind.co.in/refer/{code}</Link>
                        </div>
                    </div>
                    {/* <div className='rounded p-2 cursor referral-btn' onClick={() => handleCopyClick(code)}>
                        Copy Code
                    </div> */}
                </div>
            </div>
            {/* ------------------------------------------------------------- */}
            {/* Left Section End */}
            {/* ------------------------------------------------------------- */}
            <div className='d-flex justify-content-center col-md-4 col-12'>
                <img src={referImg} className='col-md-11 col-sm-6 col-6' alt='' />
            </div>
            {/* ------------------------------------------------------------- */}
            {/* popup section */}
            {/* ------------------------------------------------------------- */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default TopSection
