import React from 'react'

const GradientBlob3 = () => {
    return (
        <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100%" id="blobSvg">
            <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style={{ stopColor: "#3F39E8" }}></stop>
                    <stop offset="100%" style={{ stopColor: "#2823C6" }}></stop>
                </linearGradient>
            </defs>
            <path fill="url(#gradient)">
                <animate
                    attributeName='d'
                    dur={'9000ms'}
                    repeatCount={'indefinite'}

                    values='M417,318Q386,386,318,438.5Q250,491,191,429.5Q132,368,126.5,309Q121,250,109.5,174Q98,98,174,67Q250,36,335.5,57.5Q421,79,434.5,164.5Q448,250,417,318Z;
            
            M398.5,324Q398,398,324,431Q250,464,191,416Q132,368,117,309Q102,250,117,191Q132,132,191,105Q250,78,306.5,107.5Q363,137,381,193.5Q399,250,398.5,324Z;
            
            M431,319.5Q389,389,319.5,383Q250,377,164.5,399Q79,421,56.5,335.5Q34,250,68.5,176.5Q103,103,176.5,96Q250,89,306,113.5Q362,138,417.5,194Q473,250,431,319.5Z;
            
            M377.5,306Q362,362,306,378Q250,394,184.5,387.5Q119,381,93.5,315.5Q68,250,97.5,188.5Q127,127,188.5,112Q250,97,328.5,95Q407,93,400,171.5Q393,250,377.5,306Z;
            
            M385.5,306Q362,362,306,398.5Q250,435,179,413.5Q108,392,78,321Q48,250,73.5,174.5Q99,99,174.5,66Q250,33,322.5,69Q395,105,402,177.5Q409,250,385.5,306Z;
            
            M396,295.5Q341,341,295.5,389Q250,437,199,394.5Q148,352,79,301Q10,250,59,179Q108,108,179,77Q250,46,308.5,89.5Q367,133,409,191.5Q451,250,396,295.5Z;
            
            M411,322Q394,394,322,414.5Q250,435,181.5,411Q113,387,82,318.5Q51,250,98,197.5Q145,145,197.5,85.5Q250,26,316,72Q382,118,405,184Q428,250,411,322Z;
            
            M417,318Q386,386,318,438.5Q250,491,191,429.5Q132,368,126.5,309Q121,250,109.5,174Q98,98,174,67Q250,36,335.5,57.5Q421,79,434.5,164.5Q448,250,417,318Z'
                >
                </animate>
            </path>
        </svg>
    )
}

export default GradientBlob3
