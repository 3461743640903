import React from 'react'
import './Community.css';
import DownloadAppV2 from './DownloadAppV2';
import BriefInfoV2 from './BriefInfoV2';

const Main = () => {
    return (
        <div className='community-page-wrapper community-page-wrapperV2'>
            <div className=''>
                <div className=''>
                    <DownloadAppV2 />
                </div>
                <div className=''>
                    <BriefInfoV2 />
                </div>
            </div>
        </div>
    )
}

export default Main
