import React from 'react'

const IconValue = ({ icon, value }) => {
    return (
        <div className='d-flex align-items-center gap-2' style={{color:'var(--gray-500)'}}>
            <div className='d-flex align-items-center justify-content-center'>
                {icon}
            </div>
            <div className='text-nowrap'>
                {value}
            </div>
        </div>
    )
}

export default IconValue
