import React from 'react'

const Loading = ({ height, classes }) => {
    return (
        <div style={{ background: 'var(--gray-200)', height:height }} className={classes}>
        </div>
    )
}

export default Loading
