import axios from 'axios';
import React, { useState } from 'react'
import { API_URL } from '../../../config';
import { header } from '../../HeaderData';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import BookMarkCard from '../../Card_components/BookMarkCard';
import Loading from '../../Loading/Loading';
import { alertMsg } from '../../Alert/AlertData';
import Pagination from '../../Paginations/Pagination';
import ConfirmBox from '../../Alert/ConfirmMsg';

const BookMark = ({bookmarks, activePage, setActivePage, totalPages, getBookmarks, pageSize}) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const [bookmarkedId, setBookmarkedId] = useState('');

    const { jwtToken } = useSelector((state) => state.user);

    const handleBookmark = async (bookmarkId) => {
        const res = await axios.delete(`${API_URL}/bookmarks/${bookmarkId}`,
            header(jwtToken)
        );
        alertMsg('success', 'Removed from bookmarks');
        setBookmarkedId('');
        getBookmarks();
        // console.log(res)
    }

    const handleConfirm = () => {
        console.log('Confirmed!');
        handleBookmark(bookmarkedId)
        setShowConfirm(false);
    };

    const handleCancel = () => {
        setShowConfirm(false);
    };

    return (
        <div className='p-3 rounded-3 d-flex flex-column gap-4' style={{ background: 'var(--white)' }}>
            {
                bookmarks === null ?
                    [1, 2, 3].map((d) =>
                        <Loading key={d} classes={'col-12'} height={'8rem'} />
                    )
                    :
                    bookmarks?.length === 0 ?
                        <div className=''>No any bookmarked questions</div>
                        :
                        bookmarks?.map((data, index) =>
                            <BookMarkCard data={{ ...data?.question, number: activePage * pageSize + index + 1 }} setBookmarkedId={setBookmarkedId} setShowConfirm={setShowConfirm} bookmarkId={data?.id} key={index} />
                        )
            }
            {
                bookmarks?.length > 0 &&
                <Pagination activePage={activePage} setActivePage={setActivePage} totalPages={totalPages} />
            }
            {/* confirm modal  */}
            <ConfirmBox
                message="Are you sure you want to proceed?"
                show={showConfirm}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
        </div>
    )
}

export default BookMark;
