import React from 'react';
import logo from './footer_asset/logo.svg';
import { BsFacebook } from 'react-icons/bs';
import { AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';
import { IoLogoGooglePlaystore } from 'react-icons/io5';
import qr from './footer_asset/qrcode.png';
import './Footer.css';
import { Link, useNavigate } from 'react-router-dom';
import { downloadLink } from '../../utils/usableData';
import { IoMdCall } from "react-icons/io";

const Footer = () => {
    const companyOptions = [
        { title: 'About Us', link: '/about' },
        { title: 'Privacy Policy', link: '/privacy' },
        { title: 'Pricing', link: '/pricing' },
        { title: 'Terms & Conditions', link: '/terms&conditions' },
        { title: 'Refund/Cancellation Policy', link: '/refund' },
    ];
    const quickLinks = ['Community', 'Practice', 'Contest'];

    // const downloadLink = 'https://link.unbind.co.in/HcFVd8';

    return (
        <div className='py-4' style={{ color: 'white', backgroundColor: 'var(--black)' }}>
            <div className='col-md-10 col-11 mx-auto d-flex flex-column gap-4' style={{ fontSize: '1.1rem' }}>
                <div className=''>
                    <img src={logo} alt='unbind-logo' className='width400' />
                </div>
                <div className='d-flex wrapBoxCol gap-3'>
                    <div className='d-flex flex-column gap-3' style={{ width: '40%', maxWidth: '400px' }}>
                        <div className=''>
                            Unbind is a place where Students can Practice and Compete with each other, Make Friends & Find Mentors, Express his/her thoughts in the community.
                        </div>
                        <div className='d-flex gap-3 align-items-center fs-3'>
                            <Link to={'https://www.facebook.com/Unbind-111230841912578/'} target='_blank' className='linkFoot'><BsFacebook style={{ cursor: 'pointer' }} /></Link>
                            <Link to={'https://instagram.com/unbind.community?igshid=ZDdkNTZiNTM='} target='_blank' className='linkFoot'><AiFillInstagram className='fs-2' style={{ cursor: 'pointer' }} /></Link>
                            <Link to={'https://www.linkedin.com/company/unbind-community/'} target='_blank' className='linkFoot'><AiFillLinkedin className='fs-2' style={{ cursor: 'pointer' }} /></Link>
                        </div>
                        <div className='' style={{ fontSize: '0.9rem' }}>
                            <div className='fs-6'>
                                Have a query?
                            </div>
                            <div className='fw-light' style={{ fontSize: '0.8rem', marginTop: '5px', marginBottom: '4px' }}>
                                Feel free to reach us at any moment
                            </div>
                            <Link aria-label='unbind-contacts' to={'tel:+919306326289'} className='d-flex flex-row justify-content-start align-items-center text-white call-link' style={{ fontSize: '0.8rem' }}>
                                <div className=''>
                                    <IoMdCall />
                                </div>
                                <div className='ps-1 text-nowrap'>
                                    Call on
                                </div>
                                <div className='ps-2 text-nowrap'>
                                    +91 9306326289
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='d-flex gap-3 justify-content-between' style={{ width: '30%', maxWidth: '350px' }}>
                        <div className='d-flex flex-column' style={{ width: '50%' }}>
                            <div className='fw-bold mb-4'>Company</div>
                            <div className='d-flex flex-column gap-2'>
                                {
                                    companyOptions.map((option, index) =>
                                        <Link className='linkFoot' to={option.link} key={index}>{option.title}</Link>
                                    )
                                }
                            </div>
                        </div>
                        <div className='d-flex flex-column' style={{ width: '50%' }}>
                            <div className='fw-bold mb-4'>Quick Link</div>
                            <div className='d-flex flex-column gap-2'>
                                {
                                    quickLinks.map((option, index) =>
                                        <Link to={`/${option.toLowerCase()}`} className='linkFoot' key={index}><div className='' key={index} style={{ cursor: 'pointer' }}>{option}</div></Link>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column' style={{ width: '30%', maxWidth: '300px' }}>
                        <div className='fw-bold mb-4'>DOWNLOAD THE APP</div>
                        <div className='d-flex flex-column gap-3'>
                            <button aria-label='download-unbind-app-button' type='button' className='d-flex align-items-center gap-1 py-2 px-3 fw-bold' style={{ color: 'var(--black)', backgroundColor: 'white', borderRadius: '2rem', cursor: 'pointer', border: 0, outline: 'none' }}>
                                <IoLogoGooglePlaystore className='fs-3' />
                                <Link to={downloadLink} className='' style={{ color: 'inherit' }} target='_blank'>Download on Google Play</Link>
                            </button>
                            <div className='d-flex align-items-center gap-2'>
                                <div className=''>
                                    <img src={qr} style={{ width: '100%', maxWidth: '100px' }} alt='unbind-qrcode' />
                                </div>
                                <div className=''>
                                    Or Scan the QR <br />Code to Download
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-center'>
                    © 2023 Unbind
                </div>
            </div>
        </div>
    )
}

export default Footer
