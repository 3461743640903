import React from 'react'
import { useState } from 'react';
import { FaAngleDoubleDown, FaAngleDoubleUp } from 'react-icons/fa';
import Table2 from '../Table_component/Table2';
import { Link, useNavigate } from 'react-router-dom';

const TopFives = ({ tableStructure, data, link }) => {


    const navigate = useNavigate()
    return (
        <div className='d-flex flex-column rounded-3 gap-4' style={{ overflow: 'hidden', background: 'white' }}>
            <div
                className='d-flex gap-2 justify-content-center p-2 font-150 fw-5'
                style={{ background: 'var(--primary)', color: 'white' }}
            >
                <div className=''>
                    Top 5 Standings
                </div>
            </div>
            {/* ------------------------------------------------------------------- */}
            {/* virtual lists start */}
            {/* ------------------------------------------------------------------- */}
            <div className='px-3 pb-2 d-flex flex-column gap-2 justify-content-center align-items-center'>
                <div className='' style={{ width: '100%' }}>
                    <Table2
                        tableStructure={tableStructure}
                        data={data}
                        startRank={0}
                        fontStyle={'font'}
                        headingBg={'var(--gray-200)'}
                        headingColor={'var(--gray-900)'}
                    />
                </div>
                <div onClick={() => navigate(link)} className='font-80 cursor' style={{ color: 'var(--primary)' }}>
                    See All
                </div>
            </div>
            {/* ------------------------------------------------------------------- */}
            {/* virtual lists end */}
            {/* ------------------------------------------------------------------- */}
        </div>
    )
}

export default TopFives
