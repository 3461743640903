import React from 'react';
import { FaStar } from 'react-icons/fa';
import RatingGraph from '../Graph_components/RatingGraph';

const ProfileRatingCard = ({ data }) => {
    return (
        <div className='d-flex flex-column rounded-2 p-3 gap-3' style={{ background: 'var(--white)' }}>
            <div className='fw-5 d-flex justify-content-between'>
                <div className=''>
                    Rating
                </div>
                <div className='d-flex gap-0 align-items-end px-2' style={{ color: 'var(--primary)' }}>
                    <FaStar className='font-120' />
                    <FaStar className='font-200' />
                    <FaStar className='font-120' />
                </div>
            </div>
            <div className=''>
                {/* Rating chart coming soon... */}
                <RatingGraph ratings={getRatings(data)} contests={getContestNames(data)} />
            </div>
        </div>
    )
}

export default ProfileRatingCard;


const getRatings = (data) => {
    const ratingData = data?.map((d) => {
        return d?.finalRating;
    });

    ratingData?.reverse();

    return ratingData;
}

const getContestNames = (data) => {
    const contestNames = data?.map((d) => {
        return d?.contest?.name + `\nChange in rating:  ${d?.changeFromLastRating}`;
    });

    contestNames?.reverse();

    return contestNames;
}
