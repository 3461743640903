// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDOR-za__YkCS1hGvubzfl4ZglgHSB__zo",
    authDomain: "unbind-77977.firebaseapp.com",
    projectId: "unbind-77977",
    storageBucket: "unbind-77977.appspot.com",
    messagingSenderId: "352742543082",
    appId: "1:352742543082:web:61ca41698b2b2320ff9193",
    measurementId: "G-NKFS9MC8VX"
};

const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
auth.languageCode = 'en';

export default { firebase, auth };