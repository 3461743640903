import React, { useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { sendOtpApi, verifyOtpApi } from '../api';
import { API_URL } from '../config';
import { useCookies } from 'react-cookie';
import rightArrowIcon from '../assets/right-arrow.svg'

import '../styles/navbar.css'
import logo from './Footer/footer_asset/logo.svg'

import TestContext from '../context/testContext';
import { useTimer } from 'react-timer-hook';
import { FiChevronRight } from 'react-icons/fi';

export default function VirtualTestTopbar({ data, setContestEnded }) {

    const context = useContext(TestContext)
    const dispatch = useDispatch();
    const [expiryTimestamp, setExpiryTimestamp] = useState(false)
    const jwtToken = useSelector((state) => state.user.jwtToken)
    const saveResponse = () => {
        console.log('save')
        context.updateTimeTaken();
        if (context.currentQuestion) {
            context.sendResponse(context.cache.current["submittedResponse"][context.currentQuestion.id]);
        }
    }
    const onClick = () => {
        console.log('save')
        saveResponse();
        context.setShowContestEndedPopUp(true)
    }

    useEffect(() => {
        if (data) {
            setExpiryTimestamp(new Date(new Date(data?.creationDate).getTime() + data?.contest?.duration * 60000))
        }
    }, [data])


    return (
        <>
            <nav className="navbar sticky-top app_container">
                <div className=" d-flex container-lg flex-nowrap ">
                    <div id='logo-test' className="navbar-brand align-items-center d-flex" href="#">
                        <div >
                            <Link to={'/'}>
                                <img className='d-block' id='logo' src={logo} />
                            </Link>
                        </div>
                    </div>
                    <div id='test-navbar-btn' className='d-flex align-items-center'>
                        {
                            expiryTimestamp && <div className='fw-sb me-4 d-flex flex-column align-items-center'>
                                <Timer onExpire={() => setContestEnded(true)} expiryTimestamp={expiryTimestamp} />
                            </div>
                        }
                        {jwtToken && <button disabled={context.fetchingContestData} onClick={onClick} className='login-logout-btn btn text-nowrap' type="submit">
                            End test
                        </button>}
                    </div>
                </div>
                <div className='w-100 question-sidebar-btn-cnt py-2 d-flex justify-content-end app_container'>
                    <button className='btn fw-m fs-sm d-flex align-items-center' style={{ background: 'var(--green-200)', color: 'white' }} onClick={() => context.setShowOffCanvasSideBar(true)}>
                        Questions
                        {/* <img height="15px" src={rightArrowIcon} /> */}
                        <FiChevronRight />
                    </button>
                </div>
            </nav>
        </>
    )
}


function Timer({ expiryTimestamp, onExpire }) {
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({ expiryTimestamp, onExpire });

    return (
        <div className='fw-sb me-4 d-flex flex-column align-items-center'>
            <div className="fs-xsm text-secondary text-nowrap">
                Time remaining
            </div>
            {hours}:
            {(minutes < 10 ? `0${minutes}` : minutes)}:
            {(seconds < 10 ? `0${seconds}` : seconds)}
        </div>
    );
}