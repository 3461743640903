import React, { useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Offcanvas, Carousel } from 'react-bootstrap';
import { setDefaultCategory, setJwtToken, setUser } from '../reducers/user';
import { sendOtpApi, verifyOtpApi } from '../api';
import { API_URL } from '../config';
import { useCookies } from 'react-cookie';
import multiavatar from '@multiavatar/multiavatar';
import '../styles/navbar.css'
import flagIcon from '../assets/india.png'
import logo from '../assets/logo.png'
// import logo from '../assets/logo-no-background.svg'
import dropdownIcon from '../assets/dropdown.svg'
import whatsappIcon from '../assets/whatsapp-icon.svg'

import NavbarButtons from './NavbarButtons';
import TestContext from '../context/testContext';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { sendOtp } from '../actions/auth';
import { useTimer } from 'react-timer-hook';

import firebase from '../firebaseConfig';
import Loader from './Loader';
import { setLoading } from '../reducers/app';
import { postDeaultCategory } from '../actions/user';
import { alertMsg } from './Alert/AlertData';
import { ToastContainer } from 'react-toastify';
import Sidebar from './Profile/Sidebar';
import { encryptData } from '../utils/utility';

export default function Navbar({ showLoginCanvas, showButtons, setShowLoginCanvas, active, setActive, showCategories, totalBookmarks }) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const [inputFocused, setInputFocused] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("")
  const [verifyOtpPlaceholder, setVerifyOtpPlaceholder] = useState("Enter otp")
  const [otp, setOtp] = useState("")
  const [canResendOtp, setCanResendOtp] = useState(true)
  const [timeRemaining, setTimeRemaining] = useState(0)
  const [recaptchaVariable, setRecaptchaVariable] = useState(null)
  const [sendingOtp, setSendingOtp] = useState(false)
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false)

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp: new Date(), onExpire: () => { setCanResendOtp(true) } });



  useEffect(() => {
    if (!canResendOtp) {
      let time = new Date();
      time.setSeconds(time.getSeconds() + 30);
      restart(time)
    }
  }, [canResendOtp])


  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const jwtToken = useSelector((state) => state.user.jwtToken)
  const user = useSelector((state) => state.user.user)
  const selectedCategory = useSelector((state) => state.user.defaultCategory)
  const categories = useSelector((state) => state.contest.categories)

  const otpInputCnt = document.getElementById("otp-input-cnt");
  const userIconCnt = document.getElementById("user-icon");




  // useEffect(() => {
  //   if (categories && categories.length >= 0) {
  //     // console.log(categories)
  //   }
  // }, [categories])
  // console.log(categories)


  // useEffect(() => {

  // }, [selectedCategory])

  /////////////// todo//////////
  const setCategory = async () => {
    try {
      let temp = user;
      const res = await axios.put(API_URL + '/user', {

      })
    } catch (e) {
      console.log(e)
    }
  }


  const resetRecaptcha = () => {

  }
  const setUpRecaptcha = async () => {
    try {
      setRecaptchaVariable(
        new RecaptchaVerifier('sign-in-button', {
          'size': 'invisible',
          'callback': async (response) => {
            console.log("captcha resolved", response)
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // await handleSendOtp();
          }
        }, firebase.auth)
      )
    } catch (e) {
      console.log(e)
    }

  }

  const handleSendOtp = async () => {
    // e.preventDefault()
    try {
      // setUpRecaptcha();
      // console.log(process.env.REACT_APP_MOBILE_SECRET_KEY)
      const encryptedPhoneNumber = encryptData(mobileNumber, process.env.REACT_APP_MOBILE_SECRET_KEY);
      // console.log(encryptedPhoneNumber);
      const res = await axios.post(`${API_URL}/sendOtp/encrypted`,
        {
          mobileNumber: encryptedPhoneNumber
        }
      );
      // setSendingOtp(true)
      if (res?.status === 200)
        setIndex(1);
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  }
  const resendOtp = () => {
    let ele = document.getElementById('sign-in-button');
    ele.remove();
    ele = document.createElement("div");
    ele.id = 'sign-in-button'
    document.body.appendChild(ele);
    setUpRecaptcha();
  }

  useEffect(() => {
    if (recaptchaVariable) {
      const appVerifier = recaptchaVariable;
      console.log("appVerifier", appVerifier)
      signInWithPhoneNumber(firebase.auth, "+91" + mobileNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setSendingOtp(false)
          setIndex(1);
          // ...
        }).catch((error) => {
          setSendingOtp(false)
          console.log(error)
        });
      // setSendingOtp(false)
    }

  }, [recaptchaVariable])


  const verifyOtp = async () => {
    try {
      dispatch(setLoading(true))
      // window.confirmationResult.confirm(otp).then((result) => {
      //   // User signed in successfully.
      //   const user = result.user;
      //   axios.get(API_URL + '/tokenFromFbToken?idToken=' + user.accessToken).then(function (res) {
      //     console.log("res================", res.data)
      //     dispatch(setJwtToken(res.data));
      //     setShowLoginCanvas(false);
      //     dispatch(setLoading(false))
      //   }).catch(function (e) {
      //     dispatch(setLoading(false))
      //     console.log(e)
      //   });
      // }).catch((error) => {
      //   console.log(error)
      //   // User couldn't sign in (bad verification code?)
      //   // ...
      // });
      console.log(otp);
      const res = await axios.post(`${API_URL}/verifyOtp`,
        {
          mobileNumber,
          otp
        }
      )
      console.log(res);
      if (res?.data?.jwtToken !== 'INVALID_OTP') {
        dispatch(setJwtToken(res.data?.jwtToken));
        setShowLoginCanvas(false);
      }
      else {
        console.log('error')
        alertMsg('error', 'You entered wrong otp')
      }
      dispatch(setLoading(false))
    } catch (e) {
      console.log(e);
    }
  }

  const logOut = (e) => {
    e.preventDefault()
    dispatch(setJwtToken(null));
    dispatch(setDefaultCategory(null));
    dispatch(setUser(null))
    removeCookie("token", {
      path: "/"
    })
    navigate('/')
    // window.location.reload(true)
  }
  const goToProfilePage = (e) => {
    e.preventDefault();
    console.log(window.innerWidth)
    if (window.innerWidth <= '992') {
      setShowSidebar(true);
    }
    else {
      navigate('/profile/' + user.userId)
    }
    // window.location.reload(true)
  }

  const editMobileNumber = () => {
    setTimeout(() => {
      setVerifyOtpPlaceholder("Enter otp")
      otpInputCnt.classList.remove("border-color-3")
    }, 1000);
    resetRecaptcha();
    setIndex(0);
  }

  const onWhatsappLogin = () => {
    const message = "https://wa.me/918595602023?text=Login";
    window.location.href = message;
  }

  // console.log(user);

  return (
    <nav className="navbar bg-white sticky-top">
      <div id='sign-in-button'></div>
      <div className=" d-flex container-lg flex-nowrap ">
        <div className="navbar-brand d-flex align-items-center" href="#">
          <Link to={'/'}>
            <img id='logo' src={logo} alt='unbind-logo' />
          </Link>
          {showCategories && <div className="dropdown ms-3 dropdown-navbar">
            <div disabled={loadingBtn} id='category-btn' className="d-flex justify-content-center align-items-center bg-white" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              {selectedCategory ? selectedCategory.name : "NEET"}
              <img id='dropdown-icon' src={dropdownIcon} />
            </div>
            <ul className="dropdown-menu shadow border-success border-0  mt-2">
              {categories.map((category, index) => (
                <li key={index}><a onClick={async () => {
                  dispatch(setDefaultCategory(category))
                  postDeaultCategory(category, jwtToken);
                }} className="dropdown-item fs-m text-hover-1 text-secondary" role='button'>{category.name}</a></li>
              ))}
            </ul>
          </div>}
        </div>
        <div className='d-flex align-items-center'>
          {showButtons && <NavbarButtons active={active} setActive={setActive} />}
          <div style={{ minWidth: "45px" }}>
            {!jwtToken &&
              <button aria-label='Login' className='login-logout-btn' type="button" onClick={() => setShowLoginCanvas(true)}>
                LogIn
              </button>}
            {user &&
              <div onClick={goToProfilePage} className='icon-cnt rounded-5 cursor' dangerouslySetInnerHTML={{ __html: multiavatar(user.name) }}></div>

            }
          </div>
        </div>
        <Offcanvas placement='end' className="offcanvas px-4 pt-4" show={showLoginCanvas} onHide={() => { setShowLoginCanvas(false) }}>
          <Offcanvas.Header closeButton>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
              <li className="nav-item">
                <div className="fs-1 fw-sb">Login</div>
                {/* <div className="fs-6 d-flex align-items-end mt-2 mb-5"><span className='text-secondary'>or</span> <span className='ms-3 pb-1 border-bottom border-2 border-1 text-1 fs-sm fw-sb hover-pointer'>create your account</span></div> */}
              </li>

            </ul>
            <Carousel touch={false} activeIndex={index} onSelect={handleSelect} indicators={false} controls={false} interval={null}>
              <Carousel.Item>
                <form className={`d-flex mt-5 flex-column align-items-start`} role="search">
                  <div className={`d-flex w-100 border border-hover align-items-center rounded-2 no-focus   mb-3 py-3 ${inputFocused ? "border-dark" : "border-secondary-subtle"}`}>
                    <img className='flag-icon' src={flagIcon} alt="" />
                    <input autoComplete='off' value={mobileNumber} onChange={(e) => setMobileNumber((v) => (e.target.validity.valid ? e.target.value : v))} onFocus={() => setInputFocused(true)} onBlur={() => setInputFocused(false)} id='phone-input' className="no-focus flex-1 fs-6 border-0 w-100 fw-r" type="text" pattern='[0-9]*' maxLength={10} placeholder="Enter mobile number" aria-label="Mobile number" />
                  </div>
                  <div style={{ display: "none" }} ></div>
                  <button disabled={(mobileNumber.length !== 10) || sendingOtp}
                    onClick={(e) => { e.preventDefault(); handleSendOtp(); }}
                    data-bs-target="#loginCarousel" data-bs-slide="next" className={`btn bg-hover-1 rounded-1 border-0 py-2 px-5  fw-sb text-light bg-1`} type="submit">Login</button>
                </form>


              </Carousel.Item>
              <Carousel.Item>
                <form className={`d-flex mt-5 flex-column align-items-start`} role="search">
                  <div id="otp-input-cnt" className={`d-flex w-100 border border-hover rounded-2 no-focus   mb-3 py-3 ps-3 ${inputFocused ? "border-dark" : "border-secondary-subtle"}`}>
                    <input autoComplete='off' value={otp} onChange={(e) => setOtp((v) => (e.target.validity.valid ? e.target.value : v))} onFocus={() => setInputFocused(true)} onBlur={() => setInputFocused(false)} id='otp-input' className="no-focus flex-1 fs-6 border-0 w-100 fw-r" type="text" pattern='[0-9]*' maxLength={6} placeholder={verifyOtpPlaceholder} aria-label="Search" />
                  </div>
                  {/* <input placeholder='Enter mobile number' id="phone" className='no-focus border-0 px-0' type="tel" name="phone" /> */}
                  <div className="d-flex w-100 justify-content-between">
                    <button id='verify-btn' disabled={otp.length !== 6} onClick={(e) => { e.preventDefault(); verifyOtp() }} className={`btn bg-hover-1 rounded-1 border-0 py-2 px-5  fw-sb text-light bg-1`} type="submit">Verify</button>
                    <button onClick={editMobileNumber} data-bs-target="#loginCarousel" data-bs-slide="prev" className={`bg-white fs-6 border-0 fw-sb text-1`} type="button">Edit</button>
                  </div>
                  <button onClick={() => {
                    sendOtp();
                    setCanResendOtp(false);
                  }} data-bs-target="#loginCarousel" data-bs-slide="prev" disabled={!canResendOtp} className={`bg-white fs-6 border-0 fw-m text-gray-1 mt-3 align-self-end`} type="button">{canResendOtp ? "Resend otp" : "Retry again in " + seconds + " sec"}</button>
                </form>
              </Carousel.Item>
            </Carousel>
            {/* <div style={{ display: "grid", gridTemplateColumns: "1fr auto 1fr", alignItems: "center" }} className='mt-4'> */}
            {/* <div className='border border-1' ></div> */}
            {/* <div className='mx-3 text-gray-2' >or</div> */}
            {/* <div className='border border-1' ></div> */}
            {/* </div> */}
            {/* <button onClick={onWhatsappLogin} style={{ backgroundColor: "#40C351" }} className='text-white py-2 w-100 mt-4 rounded-2 border-0 fs-sm fw-sb d-flex align-items-center justify-content-center'>
              <img height={32} className="me-2" src={whatsappIcon} alt="" />
              Login with WhatsApp
            </button> */}
            <div className='mt-4 fs-xsm text-secondary'>
              By signing up, <br /> you agree to <a className='text-dark' role="button">Terms & Conditions</a> and <a role="button" className='text-dark'>Privacy Policy</a>
            </div>

            {/* ------------------------------------------------------------- */}
            {/* popup section */}
            {/* ------------------------------------------------------------- */}
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />

          </Offcanvas.Body>
        </Offcanvas>

        {/* ------------------------------------------------- */}
        {/* profile section sidebar start */}
        {/* ------------------------------------------------- */}
        {
          user && showSidebar &&
          <Offcanvas placement='end' className={`offcanvas pt-4 d-lg-none`} style={{ maxWidth: '350px' }} show={showSidebar} onHide={() => { setShowSidebar(false) }}>
            <Offcanvas.Header closeButton>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Sidebar totalBookmarks={totalBookmarks} setShowSidebar={setShowSidebar} />
            </Offcanvas.Body>
          </Offcanvas>
        }
        {/* ------------------------------------------------- */}
        {/* profile section sidebar end */}
        {/* ------------------------------------------------- */}
      </div>

    </nav>
  )
}

