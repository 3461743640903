import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './Home.css';

const CarouselHome = ({ slide }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    console.log(imageLoaded)

    return (
        <Link to={`${slide?.redirectUrl}`} className='rounded-5 d-flex bannerCH borderCH' style={{ background: `url(${slide?.imageUrl})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: '100%', border: imageLoaded ? '8px solid #E4E4E4' : '', color: 'white' }}>
            {
                !imageLoaded &&
                <div className='bg-gray-1' style={{ height: 'inherit', width: 'inherit', borderRadius: 'inherit' }}>
                </div>
            }
            <img src={slide?.imageUrl} onLoad={() => { setImageLoaded(true) }} style={{ display: 'none' }} alt='unbind-carousel' />
        </Link>
    )
}

export default CarouselHome
