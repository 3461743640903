import React from 'react'
import { ImArrowLeft2 } from 'react-icons/im';
import { FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import DropDown from '../../DropDowns/DropDown';

const trimData = (data) => {
    if (data?.length > 25) {
        return data?.slice(0, 18) + '...' + data?.slice(-5);
    }
    return data
}

const TopSection = ({ section, title, activeSubject, selectTitle, options, setFilter }) => {
    const navigate = useNavigate();
    return (
        <div className='practice-briefInfo-wrapper py-5 pt-2 pt-md-5' style={{color:'var(--gray-400)'}}>
            <div className='col-md-10 col-11 mx-auto py-3'>
                <div className='d-flex gap-2 align-items-center mb-2 mb-md-0 font-type-5'>
                    <span className='cursor' onClick={() => navigate(section[section.length - 2].link)}>
                        <ImArrowLeft2 />
                    </span>
                    {
                        section?.map((item, index) =>
                            <div key={index} className='d-flex align-items-center gap-1'>
                                <span className={`cursor ${index === section.length - 1 ? 'color-primary' : ''}`} onClick={() => navigate(item.link)}>
                                    {trimData(item.menu)}
                                </span>
                                {
                                    index < section.length - 1 &&
                                    <FaChevronRight />
                                }
                            </div>
                        )
                    }
                </div>
                <div className='d-flex gap-5 gap-md-3 justify-content-between align-items-center'>
                    {/* ---------------------------------------------------- */}
                    {/* Left Side Section Start */}
                    {/* ---------------------------------------------------- */}
                    <div className='d-flex flex-column gap-5'>

                        <div className='text-center mx-auto text-md-start mx-md-0'>
                            <div className='font-type-4'>
                                {title}
                            </div>
                            <div className='d-flex flex-wrap gap-3 my-2 pt-2 fw-5'>
                                <DropDown
                                    title={selectTitle}
                                    options={options}
                                    setFilter={setFilter}
                                />
                            </div>
                        </div>
                    </div>
                    {/* ---------------------------------------------------- */}
                    {/* Left Side Section End */}
                    {/* ---------------------------------------------------- */}

                    {/* ---------------------------------------------------- */}
                    {/* Right Side Section Start */}
                    {/* ---------------------------------------------------- */}
                    <div className=''>
                        <img src={activeSubject?.iconUrl} className='practice-side-image' alt='image' />
                    </div>
                    {/* ---------------------------------------------------- */}
                    {/* Right Side Section Start */}
                    {/* ---------------------------------------------------- */}
                </div>
            </div>
        </div>
    )
}

export default TopSection;