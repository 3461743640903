import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import TopSection from './components/TopSection';
import { FiFilter } from 'react-icons/fi';
import { useState } from 'react';
import QuestionListCard2 from '../Card_components/QuestionListCard2';
import { useEffect } from 'react';
import Pagination from '../Paginations/Pagination';
import { filterOptions } from './ContestListsData';

const Questions = ({ activeSubject, activeTopic, topics, questionLists, activePage, totalPages, setActivePage, pageSize, questionStatus, questionType, setQuestionStatus, setQuestionType }) => {
    const { subjectId, topicId } = useParams();
    const [topic, setTopic] = useState(topicId);
    // const [filters, setFilters] = useState(filterOptions);

    const navigate = useNavigate();

    const menuFlow = [
        {
            menu: 'Practice',
            link: '/app/practice'
        },
        {
            menu: activeSubject?.name,
            link: `/app/practice/${subjectId}`
        },
        {
            menu: activeTopic?.name,
            link: `/app/practice/${subjectId}/${topicId}`
        }
    ];

    const handleFilter = (option) => {
        if (option.type === 2) {
            setQuestionStatus((prev) => prev === option.value ? '' : option.value)
        }
        else if (option.type === 1) {
            setQuestionType((prev) => prev === option.value ? '' : option.value)
        }
        // setFilters((prev) => prev.map((item) => { item.title === option.title ? item.active = !item.active : ''; return item }))
    }

    useEffect(() => {
        navigate(`/app/practice/${subjectId}/${topic}`);

    }, [topic]);

    return (
        <div className='font-type-5 pb-3' style={{ color: 'var(--gray-400)' }}>
            <TopSection
                section={menuFlow}
                title={'Select Topic'}
                activeSubject={activeSubject}
                selectTitle={activeTopic?.name}
                options={getOptions(topics)}
                setFilter={setTopic}
            />

            <div className='d-flex gap-md-3 gap-2 align-items-center overflow-x-auto col-md-10 col-11 mx-auto my-2 mt-5 font-type-6'>
                <span className='cursor'>
                    <FiFilter />
                </span>
                {
                    filterOptions.map((option, index) =>
                        <span
                            className='cursor rounded-pill px-2 py-1 text-nowrap'
                            style={{ background: (option.value === questionStatus || option.value === questionType) ? '#1FB35B' : 'var(--gray-800)' }}
                            onClick={() => handleFilter(option)}
                            key={index}
                        >
                            {option.title}
                        </span>
                    )
                }
            </div>
            <div className='col-md-10 col-11 mx-auto my-2 mb-md-5'>
                <div className='font-type-2 fw-6'>
                    Questions
                </div>
                <div className='d-flex flex-column gap-4 my-2'>
                    {
                        questionLists?.map((item, index) =>
                            <QuestionListCard2
                                key={item.id}
                                data={getUpdatedItem(item)}
                                no={activePage * pageSize + index + 1}
                                activePage={activePage}
                                questionStatus={questionStatus}
                                questionType={questionType}
                            />
                        )
                    }
                </div>
                {
                    totalPages > 0 ?
                        <Pagination activePage={activePage} totalPages={totalPages} setActivePage={setActivePage} />
                        :
                        'Please stay tuned till we add data for you soon...'
                }
            </div>
        </div>
    )
}

export default Questions


const getOptions = (data) => {
    const options = data?.map((option) => {
        return { value: option?.id, title: option?.name }
    });

    return options;
}

const getUpdatedItem = (data) => {

    let solution = false;

    let difficulty = 'Easy';
    let color = 'var(--green-200)';

    let bg1 = 'linear-gradient(102deg, rgba(255, 255, 255, 0.81) 0%, rgba(255, 255, 255, 0.40) 100%)';
    let bg2 = '#FAFAFA';

    //acceptance percent
    let total = data?.noOfPeopleCorrectlyAttempted
        + data?.noOfPeopleIncorrectlyAttempted + data?.noOfPeopleUnAttempted;

    let acceptance = total === 0 ? 0 : (data?.noOfPeopleCorrectlyAttempted * 100 / total);

    //solution
    if (data?.solution?.length > 0)
        solution = true;

    //difficulty
    if (data?.rating > 2700) {
        difficulty = 'Hard';
        color = 'var(--red-200)';
    } else if (data?.rating > 2000) {
        difficulty = 'Medium';
        color = 'var(--yellow-200)'
    }


    //status
    if (data?.questionStatus === 'AC') {
        bg1 = 'linear-gradient(102deg, rgba(203, 251, 211, 0.80) 0%, rgba(255, 255, 255, 0.40) 100%)';
        bg2 = '#E1FFE5';
    } else if (data?.questionStatus === 'PR') {
        bg1 = 'linear-gradient(102deg, rgba(255, 255, 204, 0.21) 0%, rgba(255, 255, 255, 0.00) 100%)';
        bg2 = '#FFFFF5';
    } else if (data?.questionStatus === 'WR') {
        bg1 = 'linear-gradient(102deg, rgba(237, 59, 51, 0.61) 0%, rgba(255, 255, 255, 0.50) 100%)';
        bg2 = '#FBD7D6';
    }

    return { ...data, solution, color, bg1, bg2, difficulty, acceptance: `${acceptance?.toFixed(2)}%`, };
}