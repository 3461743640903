import React from 'react';
import { intro } from './Data';

const Left = ({ type, title }) => {

    return (
        <div className='col-md-4 col-12 d-flex flex-column gap-3 p-5 shadow' style={{ background: 'white', height: 'fit-content' }}>
            <div className='fw-medium font1half'>{type === 'terms' ? 'Terms & Conditions' : type === 'pricing' ? 'Pricing for Specialized Learning' : title}</div>
            <div
                className='d-flex flex-column gap-2 font1'
                style={{ color: 'var(--gray-600)' }}
                dangerouslySetInnerHTML={{ __html: intro[type] }}
            />
        </div>
    )
}

export default Left
