export const data = [
    [
        {
            question: 'What is  UNBIND platform?',
            answer: 'UNBIND is a platform that allows users to create and participate in quizzes, and also allows them to engage with other users within the platform.'
        },
        {
            question: 'How do I sign up for the platform?',
            answer: 'To sign up for the platform, simply visit the UNBIND’s website and follow the registration process. This typically involves providing your mobile no. and we will automatically create a cool username and password.'
        },
        {
            question: 'Are there any fees associated with using a UNBIND platform?',
            answer: 'UNBIND is completely free for students participating in contests and quizzes.'
        },
        {
            question: 'How can I report inappropriate content on the UNBIND platform?',
            answer: `If you come across inappropriate content on a community-based quiz platform, you should report it to the platform's administrators or moderators. Platform has a reporting system in place that allows users to flag inappropriate content or behavior.`
        }
    ],
    [
        {
            question: 'How do I participate in quizzes on UNBIND?',
            answer: 'To participate in quizzes on a UNBIND platform, simply browse through the available quizzes and select one that interests you. Then, follow the instructions to take the quiz and submit your answers.'
        },
        {
            question: 'Can I access a UNBIND platform on my mobile device?',
            answer: 'Yes, the platform is accessible on mobile, computer, tablet, laptop etc. with internet connectivity.'
        },
        {
            question: 'How will I know about my improvement on UNBIND?',
            answer: 'UNBIND will regularly update your rating based on the quizzes attempted which will show your growth.'
        },
    ],
    [
        {
            question: 'Is my data on UNBIND secured?',
            answer: 'Every data mentioned by the user is safe & secured as per privacy policy of UNBIND.'
        },
        {
            question: 'Can I update my profile later on UNBIND?',
            answer: 'Yes, users can update their profile anytime as per their convenience'
        },
        {
            question: `Would I lose access of my profile on UNBIND if I don't use it regularly?`,
            answer: 'No,access to the platform shall remain intact even if you miss using it. However, it is suggested to use this platform regularly for better learning & growth.'
        }
    ],
    [
        {
            question: `What are the benefits of using a UNBIND platform?`,
            answer: `UNBIND platform offers a number of benefits, including the ability to learn new things, connect with like-minded people, and have fun while doing it. It allows users to participate and compete in the contests, which can be a great way to showcase their knowledge and expertise.`
        },
        {
            question: `Can I compete with other users on the UNBIND platform?`,
            answer: `Yes, users can compete with each other.`
        },
        {
            question: `Can I contact support system in case of error in evaluation?`,
            answer: `UNBIND uses very robust & error-free automated evaluation system leaving no scope of error. However,you can contact support staff in case you are not satisfied with any service offered`
        }
    ]
]

export const searchData = [
    {
        question: 'What is  UNBIND platform?',
        answer: 'UNBIND is a platform that allows users to create and participate in quizzes, and also allows them to engage with other users within the platform.'
    },
    {
        question: 'How do I sign up for the platform?',
        answer: 'To sign up for the platform, simply visit the UNBIND’s website and follow the registration process. This typically involves providing your mobile no. and we will automatically create a cool username and password.'
    },
    {
        question: 'Are there any fees associated with using a UNBIND platform?',
        answer: 'UNBIND is completely free for students participating in contests and quizzes.'
    },
    {
        question: 'How can I report inappropriate content on the UNBIND platform?',
        answer: `If you come across inappropriate content on a community-based quiz platform, you should report it to the platform's administrators or moderators. Platform has a reporting system in place that allows users to flag inappropriate content or behavior.`
    },
    {
        question: 'How do I participate in quizzes on UNBIND?',
        answer: 'To participate in quizzes on a UNBIND platform, simply browse through the available quizzes and select one that interests you. Then, follow the instructions to take the quiz and submit your answers.'
    },
    {
        question: 'Can I access a UNBIND platform on my mobile device?',
        answer: 'Yes, the platform is accessible on mobile, computer, tablet, laptop etc. with internet connectivity.'
    },
    {
        question: 'How will I know about my improvement on UNBIND?',
        answer: 'UNBIND will regularly update your rating based on the quizzes attempted which will show your growth.'
    },
    {
        question: 'Is my data on UNBIND secured?',
        answer: 'Every data mentioned by the user is safe & secured as per privacy policy of UNBIND.'
    },
    {
        question: 'Can I update my profile later on UNBIND?',
        answer: 'Yes, users can update their profile anytime as per their convenience'
    },
    {
        question: `Would I lose access of my profile on UNBIND if I don't use it regularly?`,
        answer: 'No,access to the platform shall remain intact even if you miss using it. However, it is suggested to use this platform regularly for better learning & growth.'
    },
    {
        question: `What are the benefits of using a UNBIND platform?`,
        answer: `UNBIND platform offers a number of benefits, including the ability to learn new things, connect with like-minded people, and have fun while doing it. It allows users to participate and compete in the contests, which can be a great way to showcase their knowledge and expertise.`
    },
    {
        question: `Can I compete with other users on the UNBIND platform?`,
        answer: `Yes, users can compete with each other.`
    },
    {
        question: `Can I contact support system in case of error in evaluation?`,
        answer: `UNBIND uses very robust & error-free automated evaluation system leaving no scope of error. However,you can contact support staff in case you are not satisfied with any service offered`
    }
]