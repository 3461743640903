import React, { useState } from 'react'
import CardComponent from './CardComponent';
import img1 from '../Contest_assets/contest1.png';
import img2 from '../Contest_assets/contest2.png';
import img3 from '../Contest_assets/contest3.png';
import img4 from '../Contest_assets/contest4.png';
import img5 from '../Contest_assets/contest5.png';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../../config';
import Pagination from '../../Paginations/Pagination';
import { Link, useParams } from 'react-router-dom';
import { checkStatus } from './ContestsData';

const Contests = ({ view }) => {
    const headings = [
        { title: 'Ongoing Contests', link: '' },
        { title: 'Past Contests', link: '/past' },
        { title: 'My Contests', link: '/mycontests' }
    ];

    const selectedCategory = useSelector((state) => state.user.defaultCategory)
    const { user, fetchingUser } = useSelector((state) => state.user)
    const jwtToken = useSelector((state) => state.user.jwtToken)
    const [contestData1, setContestData1] = useState(null);
    const [updatedContestData1, setUpdatedContestData1] = useState(null)
    const [registeredContest, setRegisteredContest] = useState(null)
    const [contestData2, setContestData2] = useState(null);
    const [contestData3, setContestData3] = useState(null);
    const [loadingRegistrationStatus, setLoadingRegistrationStatus] = useState(false)
    const [activePage, setActivePage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const pageSize = 8;
    const { type } = useParams();

    const images = [img1, img2, img3, img4, img5]

    const fetchContests = async () => {
        try {
            setLoading(true);
            if (type === '' || !type) {
                const contestRes = await axios.get(API_URL + `/contest?chosen_cat=${selectedCategory.id}&page=0&results_declared=false&size=100`);
                const contestData = contestRes?.data?.content;
                // if (jwtToken) {
                //     console.log(jwtToken)
                //     console.log('jwtToken')
                //     const userData = await axios.get(API_URL + `/registerForContest/query?page=0&userId=${user.userId}`, { headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + jwtToken } });
                //     const userContent = userData?.data?.content;
                //     const content = contestData?.map((contest) => {
                //         const registeredContest = userContent.find((regContest) => contest.id === regContest.contest.id);
                //         const registerStatus = registeredContest?.registerStatus;
                //         const btnStatus =
                //             checkStatus(contest.start_time, contest.duration) ?
                //                 registerStatus === "SUBMITTED" ?
                //                     'Submitted'
                //                     :
                //                     registerStatus ?
                //                         'Take Test'
                //                         :
                //                         'Closed'
                //                 : new Date() > new Date(contest.end_time) ?
                //                     'Processing'
                //                     :
                //                     registerStatus ?
                //                         'Registered'
                //                         :
                //                         'Register';
                //         return { ...contest, registerStatus, btnStatus };
                //     });
                //     setContestData1([type, content]);
                // }
                // else {
                //     console.log('not jwt')
                //     setContestData1([type, contestData]);
                // }
                setContestData1([type, contestData]);
                setTotalPages(contestRes?.data?.totalPages);
            } else if (type === 'past') {
                const res = await axios.get(API_URL + `/contest?chosen_cat=${selectedCategory.id}&page=${activePage}&results_declared=true&size=${pageSize}`);
                setContestData2([type, res?.data?.content]);
                setTotalPages(res?.data?.totalPages);
            } else {
                const res = await axios.get(API_URL + `/registerForContest/query?page=${activePage}&size=${pageSize}&userId=${user.userId}&contestCategoryId=${selectedCategory.id}`, { headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + jwtToken } });
                setContestData3([type, res?.data?.content]);
                setTotalPages(res?.data?.totalPages);
            }
        } catch (e) {
            console.log(e.message);
        } finally {
            // console.log(contestData)
            console.log('finally block')
            setLoading(false);
        }
    };

    const fetchRegisteredContests = async () => {
        try {
            setLoadingRegistrationStatus(true);
            console.log("fetching registered contest====================");
            const res = await axios.get(API_URL + `/registerForContest`, { headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + jwtToken } });
            setRegisteredContest(res.data)
            console.log("registered contest===========", res.data)
            setLoadingRegistrationStatus(false);
        } catch (e) {
            console.log(e.message);
            setUpdatedContestData1([type, content]);
            setLoadingRegistrationStatus(false);
        }
    }
    useEffect(() => {
        if (jwtToken) {
            fetchRegisteredContests();
        }
    }, [jwtToken])
    useEffect(() => {
        if (!registeredContest && !loadingRegistrationStatus && contestData1) {
            setUpdatedContestData1(contestData1)
        }
        if (registeredContest && contestData1) {
            const content = contestData1[1]?.map((contest) => {
                const temp = registeredContest.find((regContest) => contest.id === regContest.contest.id);
                const registerStatus = temp?.registerStatus;
                const btnStatus =
                    checkStatus(contest.start_time, contest.duration) ?
                        registerStatus === "SUBMITTED" ?
                            'Submitted'
                            :
                            registerStatus ?
                                'Take Test'
                                :
                                'Register'
                        : new Date() > new Date(contest.end_time) ?
                            'Processing'
                            :
                            registerStatus ?
                                'Registered'
                                :
                                'Register';
                return { ...contest, registerStatus, btnStatus };
            });
            setUpdatedContestData1([type, content]);
        }
    }, [registeredContest, contestData1])

    useEffect(() => {
        if (activePage === 0) {
            fetchContests();
        }
        else {
            setActivePage(0);
        }

        return () => {
            window.scrollTo(0, 0);
        }
    }, [selectedCategory, type]);

    useEffect(() => {
        console.log("hit=========================")
        fetchContests();
        return () => {
            window.scrollTo(0, 0);
        }
    }, [activePage]);

    // console.log(contestData)

    return (
        <div className={`d-lg-flex ${view === 0 ? 'd-flex col-12' : 'd-none col-0'} col-lg-8  flex-column gap-5`}>
            <div className='contestCategoryTitle d-flex gap-md-3 gap-sm-1 gap-1' style={{ overflowX: 'auto' }}>
                {
                    headings.map((title, index) =>
                        <Link to={title.link === '' ? `/app/contest` : `/app/contest/category${title.link}`} className={`rounded-5 px-3 py-1 text-nowrap font-120 justify-content-center align-items-center ${index === 2 && (!jwtToken || jwtToken === '' || jwtToken === null) ? 'd-none' : 'd-flex'}`}
                            style={{
                                color: 'white',
                                backgroundColor: `${(type === title.link || ('/' + type === title.link) || (!type && index === 0)) ? 'var(--green-200)' : 'var(--black)'}`,
                                border: `${(type === title.link || ('/' + type === title.link) || (!type && index === 0)) ? '0px' : '1px solid var(--gray-600)'}`,
                                cursor: 'pointer',
                            }}
                            key={index}>
                            {title.title}
                        </Link>
                    )
                }
            </div>
            <div className='d-flex flex-column gap-3'>
                {
                    (loadingRegistrationStatus || fetchingUser || loading || (type === 'mycontests' && contestData3 === null) || (type === 'past' && contestData2 === null) || ((type === '' || !type) && updatedContestData1 === null)) ?
                        [1, 2, 3].map((data) =>
                            <div key={data} className='rounded-4 py-3 px-2 d-flex justify-content-between' style={{ backgroundColor: 'var(--gray-300)', height: '120px', width: '100%' }}>
                            </div>
                        )
                        :
                        type === 'mycontests' ?
                            contestData3 && contestData3[1]?.map((data, index) =>
                                <CardComponent
                                    data={type === 'mycontests' ? data?.contest : data}
                                    img={images[index % 5]}
                                    key={index}
                                    type={type === 'mycontests' ? 2 : type === 'past' ? 1 : 0}
                                    status={data?.registerStatus}
                                />
                            )
                            :
                            type === 'past' ?
                                contestData2 && contestData2[1]?.map((data, index) =>
                                    <CardComponent
                                        data={type === 'mycontests' ? data?.contest : data}
                                        img={images[index % 5]}
                                        key={index}
                                        type={type === 'mycontests' ? 2 : type === 'past' ? 1 : 0}
                                        status={data?.registerStatus}
                                    />
                                )
                                :
                                updatedContestData1 && updatedContestData1[1]?.map((data, index) =>
                                    <CardComponent
                                        data={type === 'mycontests' ? data?.contest : data}
                                        img={images[index % 5]}
                                        key={index}
                                        type={type === 'mycontests' ? 2 : type === 'past' ? 1 : 0}
                                        status={data?.registerStatus}
                                    />
                                )
                }
            </div>
            <div className=''>
                <Pagination activePage={activePage} setActivePage={setActivePage} totalPages={totalPages} />
            </div>
        </div>
    )
}

export default Contests
