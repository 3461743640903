import React, { useContext } from 'react'
import TestContext from '../context/testContext';

export default function Response() {
  let context = useContext(TestContext);
  const saveResponse = () => {
    context.updateTimeTaken();
    if (context.currentQuestion) {
      context.sendResponse(context.cache.current["submittedResponse"][context.currentQuestion.id]);
    }
  }
  const goToNextQuestion = () => {
    let temp = isNext(context.currentQuestion.questionSubjectId.name, context.currentQuestionNumber, context.questionIds);
    if (temp) {
      context.setCurrentQuestionId(temp.nextQuestionId);
      context.setCurrentQuestionNumber(temp.nextQuestionNumber);
    }
  }
  const goToPreviousQuestion = () => {
    let temp = isPrevious(context.currentQuestion.questionSubjectId.name, context.currentQuestionNumber, context.questionIds);
    if (temp) {
      context.setCurrentQuestionId(temp.previousQuestionId);
      context.setCurrentQuestionNumber(temp.previousQuestionNumber);
    }
  }
  return (
    <>
      {context.currentQuestion && <div id='test-bottom-tab' className='bg-light d-flex justify-content-end'>
        <button
          disabled={(!isPrevious(context.currentQuestion.questionSubjectId.name, context.currentQuestionNumber, context.questionIds)) || context.fetchingQuestionData}
          onClick={() => {
            saveResponse();
            goToPreviousQuestion();
          }}
          id='previous-btn'
          className='btn ms-4 me-4 bg-white text-1 border-color-1'>
          Previous
        </button>
        <button
          disabled={context.fetchingQuestionData}
          onClick={() => {
            saveResponse();
            if ((isNext(context.currentQuestion.questionSubjectId.name, context.currentQuestionNumber, context.questionIds))) {
              goToNextQuestion();
            }
          }} className=' next-que-btn btn  me-4'>
          {
            (!isNext(context.currentQuestion.questionSubjectId.name, context.currentQuestionNumber, context.questionIds)) ? "Save" : "Save & Next"
          }
        </button>
      </div>}
    </>
  )
}


const isNext = (currentCategory, currentQuestionNumber, questionIds) => {
  if (questionIds[currentCategory][currentQuestionNumber]) {
    return {
      nextQuestionNumber: currentQuestionNumber + 1,
      nextQuestionId: questionIds[currentCategory][currentQuestionNumber],

    };
  }
  let categories = Object.keys(questionIds);
  let temp = categories.indexOf(currentCategory);
  if (questionIds[categories[temp + 1]]) {
    return {
      nextQuestionNumber: 1,
      nextQuestionId: questionIds[categories[temp + 1]][0],
    };
  }
  return {
    nextQuestionNumber: 1,
    nextQuestionId: questionIds[categories[0]][0],
  };
}
const isPrevious = (currentCategory, currentQuestionNumber, questionIds) => {
  if (questionIds[currentCategory][currentQuestionNumber - 2]) {
    return {
      previousQuestionNumber: currentQuestionNumber - 1,
      previousQuestionId: questionIds[currentCategory][currentQuestionNumber - 2],

    };
  }
  let categories = Object.keys(questionIds);
  let temp = categories.indexOf(currentCategory);
  if (questionIds[categories[temp - 1]]) {
    let length = questionIds[categories[temp - 1]].length
    return {
      previousQuestionNumber: length,
      previousQuestionId: questionIds[categories[temp - 1]][length - 1],
    };
  }
  return false;
}

const stringify = (temp) => {
  let t = "";
  temp.forEach((option, index) => {
    if (index !== 0) {
      t += `,${option}`
    }
    else t += option;
  });
  return t;
}
