import React from 'react'
import Table2 from '../../Table_component/Table2'
import { referralTableStructure, restructureTable } from './Refer_data'
import Pagination from '../../Paginations/Pagination'

const TopReferrers = ({ tableData, activePage, setActivePage, pageSize, totalPages }) => {
  return (
    <div className='d-flex flex-column gap-4'>
      <div className='font-180 fw-5 my-2'>Your Referrals</div>
      <div className='col-12'>
        <Table2 tableStructure={referralTableStructure} data={restructureTable(tableData)} startRank={activePage * pageSize} fontStyle={'font'} />
      </div>
      <Pagination activePage={activePage} setActivePage={setActivePage} totalPages={totalPages} />
    </div>
  )
}

export default TopReferrers
