import React from 'react'
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap'

const trimData = (data) => {
    if (data?.length > 20) {
        return data?.slice(0, 14) + '...' + data?.slice(-5);
    }
    return data
}

const DropDown = ({ title, options, setFilter }) => {
    const [selectedValue, setSelectedValue] = useState(null);

    return (
        <Dropdown onSelect={(eventKey) => { setSelectedValue(eventKey); setFilter((JSON.parse(eventKey))?.value) }}>
            <Dropdown.Toggle className='font-type-6' style={{ width: '100%', background: 'white', color: 'var(--gray-600)', border: '1px solid var(--gray-600)', display: 'flex', justifyContent: 'space-between', alignItems: 'center', overflow:'hidden', textOverflow: 'ellipsis' }} id="dropdown-basic">
                {
                    selectedValue !== null ?
                        trimData(JSON.parse(selectedValue)?.title)
                        :
                        trimData(title)
                }
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {
                    options?.map((option, index) =>
                        <Dropdown.Item className='font-100' key={index} eventKey={JSON.stringify(option)}>{option?.title}</Dropdown.Item>
                    )
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default DropDown
