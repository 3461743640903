import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import axios from 'axios'
import { API_URL } from '../config'
import { useParams, useNavigate } from 'react-router-dom'
import { setJwtToken, setUser } from '../reducers/user'
import TestContext from '../context/testContext'
import Sidebar from '../components_App/Sidebar'
import Question from '../components_App/Question'
import Response from '../components_App/Response'
import TestTopbar from '../components_App/TestTopbar'

import '../styles/test.css'
import TestPopUp from '../components_App/TestPopUp'
import QuestionSidebar from '../components_App/QuestionSidebar'



export default function Test_App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, registerId } = useParams();
  const [contestData, setContestData] = useState(null)
  const [showContestEndedPopUp, setShowContestEndedPopUp] = useState(false)
  const [fetchingRegistrationStatus, setFetchingRegistrationStatus] = useState(true)
  const [registrationData, setRegistrationData] = useState(null)
  const [isRegistered, setIsRegistered] = useState(false)
  const [fetchingQuestionIds, setFetchingQuestionIds] = useState(true);
  const [fetchingQuestionData, setFetchingQuestionData] = useState(true);
  const [fetchingContestData, setFetchingContestData] = useState(true);
  const [currentQuestionId, setCurrentQuestionId] = useState(null)
  const [currentQuestion, setCurrentQuestion] = useState(null)
  const [integerResponse, setIntegerResponse] = useState(null)
  const [singleTypeResponse, setSingleTypeResponse] = useState(null)
  const [multipleTypeResponse, setMultipleTypeResponse] = useState([])
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1)
  const [questionIds, setQuestionIds] = useState(null)
  const user = useSelector((state) => state.user.user)
  const jwtToken = useSelector((state) => state.user.jwtToken)
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const [showOffCanvasSideBar, setShowOffCanvasSideBar] = useState(false);
  const [lastQuestionStartTime, setLastQuestionStartTime] = useState(new Date());
  const [timeRemaining, setTimeRemaining] = useState(null)
  const [contestEnded, setContestEnded] = useState(false)

  const [timer, setTimer] = useState(0)
  const cache = useRef({ questionData: {}, submittedResponse: {}, timetaken: {} });


  useEffect(() => {
    if (contestData) {
      let now = new Date();
      let end = new Date(contestData.end_time);
      if (now > end) {
        navigate('/')
      };
    }
  }, [contestData])




  useEffect(() => {
    if (contestEnded) {
      setTimeout(() => {
        navigate('/')
      }, 2000);
    }

  }, [contestEnded])


  useEffect(() => {
    if (cookies && cookies.token && jwtToken === null) {
      dispatch(setJwtToken(cookies["token"]));
    }
    else if (jwtToken === null && !cookies.token) {
      navigate('/')
    }

  }, [cookies])

  useEffect(() => {
    if (!user && jwtToken) {
      fetchCurrentUser(jwtToken);
    }
  }, [jwtToken])


  useEffect(() => {
    if (isRegistered && !fetchingRegistrationStatus) {
      getContestData()
      getQuestionIds();
      getSubmittedResponse();
    }
  }, [isRegistered, fetchingRegistrationStatus])
  useEffect(() => {
    if (jwtToken && user) {
      getRegisterStatus();
      // getRegistrationData();
      // getContestData()
      // getQuestionIds();
      // getSubmittedResponse();
    }

  }, [jwtToken, user])

  useEffect(() => {
    if (questionIds) {
      setCurrentQuestionId(questionIds[Object.keys(questionIds)[0]][0]);
      setCurrentQuestionNumber(1);
    }
  }, [questionIds])

  const updateTimeTaken = () => {
    if (lastQuestionStartTime) {
      if (!cache.current["timetaken"]) {
        cache.current["timetaken"] = [];
      }
      let temp = cache.current["timetaken"];
      cache.current["timetaken"][currentQuestion.id] = (temp[currentQuestion.id] ? temp[currentQuestion.id] : 0) + ((new Date()) - lastQuestionStartTime);
      setLastQuestionStartTime(new Date());
      console.log("timeTaken===============", cache.current['timetaken'])
    }
  }


  const fetchCurrentUser = async (jwtToken) => {
    const res = await axios.get(API_URL + "/currentUser", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtToken}`
      }
    });
    console.log("current user==========", res.data)
    dispatch(setUser(res.data));
  }



  const getQuestionIds = async () => {
    try {
      setFetchingQuestionIds(true)
      if (jwtToken) {
        const res = await axios.get(API_URL + '/question?contestId=' + id);
        const data = groupQuestions(res.data.content);
        console.log('grouped question ids============', data)
        setQuestionIds(data)
      }
      setFetchingQuestionIds(false)
    } catch (e) {
      setFetchingQuestionIds(false)
      console.log(e.message);
    }
  }



  const getSubmittedResponse = async () => {
    try {
      const res = await axios.get(API_URL + '/studentQuestionResponse?contestId=' + id + "&forVirtual=false&isStudentAnswered=true&studentId=" + user.userId);
      console.log("submitted responses================", res.data)
      console.log('/studentQuestionResponse?contestId=' + id + "&forVirtual=false&isStudentAnswered=true&studentId=" + user.userId)
      res.data.forEach((item) => {
        cache.current['submittedResponse'][item.questionId] = item.studentAnswer
        cache.current['timetaken'][item.questionId] = item.timeTaken;
      })
    } catch (e) {
      console.log(e)
    }
  }


  const getRegisterStatus = async () => {
    try {
      setFetchingRegistrationStatus(true)
      const res = await axios.get(API_URL + '/registerForContest/check?contestId=' + id, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        }
      });
      setFetchingRegistrationStatus(false)
      setIsRegistered(res.data)
      console.log("checking registration status:::: is registered=", res.data)
    } catch (e) {
      setFetchingRegistrationStatus(false)
      console.log(e)
    }
  }
  // const getRegistrationData = async () => {
  //   try {
  //     const res = await axios.get(API_URL + '/registerForContest/' + registerId, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${jwtToken}`
  //       }
  //     });
  //     setRegistrationData(res.data)
  //     console.log("registration data==============",res.data)
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  const sendResponse = async (value) => {
    try {
      if (currentQuestion && user && contestData) {

        console.log("sending user response==================")
        const res = await axios.post(API_URL + '/studentQuestionResponse', {
          contestId: contestData.id,
          id: user.mobileNumber + contestData.id + currentQuestion.id,
          questionId: currentQuestion.id,
          registerResponseId: null,
          studentAnswer: value,
          studentId: user.mobileNumber,
          timeTaken: cache.current["timetaken"][currentQuestion.id] ? cache.current["timetaken"][currentQuestion.id] : 0
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`
          }
        });
        console.log("save response==============", res);
      }
    } catch (e) {
      console.log(e)
    }
  }
  const getContestData = async () => {
    try {
      setFetchingContestData(true)
      if (jwtToken) {
        const res = await axios.get(API_URL + '/contest/' + id);
        console.log('contest data============', res.data)
        setContestData(res.data);
      }
      setFetchingContestData(false)
    } catch (e) {
      setFetchingContestData(false)
      console.log(e);
    }
  }

  const endContest = async () => {
    try {
      let temp = { ...isRegistered };
      temp.registerStatus = "SUBMITTED";
      temp.contest = { id: temp.contest.id };
      temp.user = { userId: user.userId };
      console.log(temp);
      const res = await axios.put(API_URL + "/registerForContest", temp, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        }
      });
      console.log("contest ended=============", res);
      navigate('/app/contest')
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <TestContext.Provider value={{
      currentQuestion,
      setCurrentQuestion,
      questionIds,
      currentQuestionNumber,
      setCurrentQuestionNumber,
      currentQuestionId,
      setCurrentQuestionId,
      setFetchingQuestionData,
      fetchingQuestionData,
      singleTypeResponse,
      setSingleTypeResponse,
      multipleTypeResponse,
      setMultipleTypeResponse,
      cache,
      integerResponse,
      setIntegerResponse,
      timeRemaining,
      lastQuestionStartTime,
      setLastQuestionStartTime,
      updateTimeTaken,
      sendResponse,
      contestData,
      fetchingContestData,
      isRegistered,
      endContest,
      setShowContestEndedPopUp,
      showOffCanvasSideBar,
      setShowOffCanvasSideBar
    }}>
      {contestEnded && <TestPopUp type="endContest" text="Contest Ended" />}
      {showContestEndedPopUp && <TestPopUp type="confirmEndContest" text="Are you sure you want to end the test ?" />}
      {!fetchingRegistrationStatus && !isRegistered && <TestPopUp type="notRegistered" text="You are not registered for the contest" />}
      {contestData && (new Date(contestData.start_time) > (new Date())) && <TestPopUp type="notStarted" text="Contest has not been started" />}
      <div className='d-flex flex-column' style={{ minHeight: "100vh", backgroundColor: "var(--gray-900)" }}>
        <TestTopbar setContestEnded={setContestEnded} contestData={contestData} />
        <QuestionSidebar />
        <div className='container-lg d-flex' style={{ flex: 1 }}>
          <Question />
          {!fetchingQuestionIds && <Sidebar />}
        </div>
        <Response />
      </div>
    </TestContext.Provider>
  )
}


const groupQuestions = (questionIds) => {
  const groupedData = questionIds.reduce((result, current) => {
    const category = current.questionSubjectId.name;
    if (!result[category]) {
      result[category] = [];
    }
    result[category].push(current);
    return result;
  }, {});

  return groupedData;
}




const convertTime = (ms) => {
  return {
    minutes: Math.floor(ms / 60000),
    seconds: Math.floor((ms % 60000) / 1000)
  }
}

