import React, { useState } from 'react'
import { TbArrowsSort } from 'react-icons/tb';
import multiavatar from '@multiavatar/multiavatar';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import './Table.css';
import { useEffect } from 'react';
import { filterData } from './StandingsData';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Table = ({ data, asc, ascData, setAsc, setAscData }) => {
    const headings = ['Rank', 'Name', 'Scored'];
    const [extraHeadings, setExtraHeadings] = useState([])
    const [startIndex, setStartIndex] = useState(0);
    const [activeGrid, setActiveGrid] = useState(1);
    const { loading } = useSelector((state) => state.app);

    const handleSort = (index) => {
        if (index % 2 === ascData) {
            setAsc(!asc);
        }
        else {
            setAscData((ascData + 1) % 2);
            setAsc(true);
        }
    }

    useEffect(() => {
        if (data?.length > 0) {
            setExtraHeadings(data[0].studentSubjectContestResults?.map((sub) => { return { subId: sub?.questionSubjectId, subName: sub?.questionSubjectName, totalMarks: sub?.subjectFullMarks } }));
        }
    }, [data]);
    useEffect(() => {
        if (extraHeadings.length > 2) {
            setActiveGrid(3)
        }
        else {
            setActiveGrid(extraHeadings.length);
        }
    }, [extraHeadings])

    return (
        <div className='my-3 font-120'>
            {
                loading ?
                    <div className='d-flex flex-column gap-3' style={{ width: '90%' }}>
                        {
                            [1, 2, 3, 4, 5].map((no) =>
                                <div className='rounded-3' key={no} style={{ height: '3rem', background: 'var(--gray-200)', width: '100%' }}>
                                </div>
                            )
                        }
                    </div>
                    :
                    <div className='d-flex text-start' style={{ overflowX: 'auto' }}>
                        <div className={`table-fixed-cols${activeGrid}`}>
                            <div className='fixed-cols-grid align-items-center' style={{ background: '#5764ED', color: 'white', height: '3rem' }}>
                                {
                                    headings.map((title, index) =>
                                        <div className={`d-flex gap-2 px-2 justify-content-center`} style={{ whiteSpace: 'nowrap' }} key={index}>
                                            <span>{title} {(index === 2 && data) && `(${data[0]?.totalMarks})`}</span>
                                            <div className='rounded-3 p-1 d-flex justify-content-center align-items-center' style={{ background: '#5E6CFB', cursor: 'pointer' }} onClick={() => handleSort(index)}><TbArrowsSort /></div>
                                        </div>
                                    )
                                }
                            </div>
                            {
                                data?.map((d, index) =>
                                    <div className='' key={index}>
                                        <div className='py-2 fixed-cols-grid' style={{ height: '5rem' }}>
                                            <div className={`d-flex p-2 justify-content-center align-items-center`} style={{ width: '100%' }}>
                                                <div className='p-1 rounded-3 d-flex justify-content-center align-items-center' style={{ border: '1px solid var(--gray-500)', height: 'fit-content', minWidth: '2rem' }}>{d?.rank}</div>
                                            </div>
                                            <div onClick={() => {
                                                window?.ReactNativeWebView?.postMessage(JSON.stringify({ userId: d?.userId?.userId }))
                                            }} className={`d-flex justify-content-start gap-1 p-2`} style={{ width: '100%' }}>
                                                <div className='rounded-5' style={{ width: '30px' }} dangerouslySetInnerHTML={{ __html: multiavatar(d?.userId?.name) }}></div>
                                                <div className='d-flex flex-column gap-1'>
                                                    <div className='fw-medium' style={{ color: 'var(--gray-200)' }}>{d?.userId?.name}</div>
                                                    <div className='font-80' style={{ color: 'var(--gray-500)' }}>@{d?.userId?.userId}</div>
                                                </div>
                                            </div>
                                            <div className={`d-flex p-2 justify-content-center fw-medium`} style={{ color: '#79C285', width: '100%' }}>
                                                {d?.studentTotalMarks}
                                            </div>
                                        </div>
                                        <div className='' style={{ borderBottom: '1px solid var(--gray-200)' }}></div>
                                    </div>
                                )
                            }
                        </div>
                        <div className={`position-relative table-scroll-cols${activeGrid}`}>
                            <div className={`d-flex align-items-center position-relative scroll-cols-grid${activeGrid}`} style={{ background: '#6975F4', color: 'white', height: '3rem' }}>
                                {
                                    startIndex > 0 &&
                                    <div className='position-absolute rounded-5 fs-5 d-flex justify-content-center align-items-center p-1 shadow' onClick={() => setStartIndex(startIndex - 1)} style={{ color: 'white', top: '1rem', left: '0rem', background: 'var(--primary-dark)', cursor: 'pointer' }}>
                                        <BiChevronLeft />
                                    </div>
                                }
                                {
                                    extraHeadings?.slice(startIndex, startIndex + 3)?.map((title) =>
                                        <div className={`text-center px-2`} style={{ width: '100%' }} key={title?.subId}>
                                            <span>{title?.subName}({title?.totalMarks})</span>
                                        </div>
                                    )
                                }
                                {
                                    startIndex + 2 < (extraHeadings?.length - 1) &&
                                    <div className='position-absolute rounded-5 fs-5 d-flex justify-content-center align-items-center p-1 shadow' onClick={() => setStartIndex(startIndex + 1)} style={{ color: 'white', top: '1rem', right: '0rem', background: 'var(--primary-dark)', cursor: 'pointer' }}>
                                        <BiChevronRight />
                                    </div>
                                }
                            </div>
                            {
                                data?.map((d, index) =>
                                    <div className='' key={index}>
                                        <div className={`scroll-cols-grid${activeGrid} py-2`} style={{ height: '5rem', width: '100%' }}>
                                            {
                                                extraHeadings?.slice(startIndex, startIndex + 3)?.map((title) =>
                                                    <div className='text-center p-2 col-4' style={{ width: '100%' }} key={title?.subId}>
                                                        {filterData(title, d?.studentSubjectContestResults)}
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className='' style={{ borderBottom: '1px solid var(--gray-200)', width: '100%' }}></div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
            }
        </div >
    )
}

export default Table
