import React from 'react'
import Loader from '../components/Loader'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import BottomBar from '../components/BottomBar'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { useEffect } from 'react'
import Main from '../components/Profile/Main'
import { setLoading } from '../reducers/app'
import { setCategories } from '../reducers/contest'
import { API_URL } from '../config'
import axios from 'axios'
import { header } from '../components/HeaderData'

const ProfileUpdated = () => {
    const jwtToken = useSelector((state) => state.user.jwtToken)
    const dispatch = useDispatch()
    const [showLoginCanvas, setShowLoginCanvas] = useState(false)
    const [active, setActive] = useState(2);
    const { loading } = useSelector((state) => state.app)

    const [totalBookmarks, setTotalBookmarks] = useState(0);
    const [bookmarks, setBookmarks] = useState(null);
    const [activePage, setActivePage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const pageSize = 10;

    const getBookmarks = async () => {
        setBookmarks(null)
        const res = await axios.get(`${API_URL}/bookmarks?page=${activePage}&size=${pageSize}`, header(jwtToken));
        console.log('bookmarks questions=======================================================>', res);
        setBookmarks(res?.data?.content);
        setTotalPages(res?.data?.totalPages);
        setTotalBookmarks(res?.data?.totalElements);
    }

    console.log(totalBookmarks)

    useEffect(() => {
        if (jwtToken)
            getBookmarks();

        return () => { }
    }, [jwtToken, activePage]);

    useEffect(() => {
        getCategories();
    }, [])

    const getCategories = async () => {
        try {
            dispatch(setLoading(true))
            const res = await axios.get(API_URL + "/contestCategory");
            console.log("API URL===========", res.data);
            dispatch(setCategories(res.data))
            dispatch(setLoading(false))
        } catch (e) {
            dispatch(setLoading(false))
            console.log(e.message);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Profile | Unbind';
    }, []);
    return (
        <div className='d-flex flex-column justify-content-between' style={{ minHeight: '100vh', background: 'var(--gray-200)' }}>
            {loading && <Loader />}
            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <Navbar showCategories={true} showButtons={true} active={active} setActive={setActive} showLoginCanvas={showLoginCanvas} setShowLoginCanvas={setShowLoginCanvas} totalBookmarks={totalBookmarks} />
                <Main
                    setActivePage={setActivePage}
                    bookmarks={bookmarks}
                    totalBookmarks={totalBookmarks}
                    activePage={activePage}
                    totalPages={totalPages}
                    getBookmarks={getBookmarks}
                    pageSize={pageSize}
                />
            </div>
            <BottomBar active={active} setActive={setActive} />
        </div>
    )
}

export default ProfileUpdated
