import { CiBookmark, CiGift, CiSettings, CiUser } from 'react-icons/ci';
import { SlUser } from 'react-icons/sl'

export const getRankData = (data) => {
    const rankData = [
        {
            title: 'Followers',
            value: data?.follower ? data.follower : 0,
        },
        {
            title: 'Following',
            value: data?.following ? data.following : 0,
        },
        {
            title: 'Your Rating',
            value: data?.rating ? data.rating : 0,
        },
        {
            title: 'Contest Given',
            value: data?.total_contest ? data?.total_contest : 0,
        }
    ];
    return rankData;
}


export const getMenuItems = (id) => {
    const menuItems = [
        {
            icon: <CiUser />,
            name: 'Your Profile',
            link: `profile/${id}`
        },
        {
            icon: <CiBookmark />,
            name: 'Bookmarks',
            link: 'bookmark'
        },
        {
            icon: <SlUser />,
            name: "Talk's Sessions",
            link: 'talk-sessions'
        },
        {
            icon: <CiGift />,
            name: 'Referrals',
            link: 'refer'
        },
        // {
        //     icon: <CiSettings />,
        //     name: 'Setting',
        //     link: ''
        // }
    ]
    return menuItems;
}

export const getColor = (location, link) => {
    const color = location.pathname === '/' + link ? 'var(--primary)' : 'var(--gray-700)';
    return color;
}