
export const filterQuery = (location) => {
    const type = new URLSearchParams(location.search).get('questionType');
    const status = new URLSearchParams(location.search).get('questionStatus');
    const minRating = new URLSearchParams(location.search).get('minRating');
    const maxRating = new URLSearchParams(location.search).get('maxRating');
    console.log(type, status)
    let queryString = '';
    if (minRating)
        queryString += `&minRating=${minRating}`;
    if (maxRating)
        queryString += `&maxRating=${maxRating}`;
    if (status)
        queryString += `&questionStatus=${status}`;
    if (type)
        queryString += `&questionType=${type}`;
    return queryString;
}

export const findPrevQuesId = (data, number) => {
    if (number > 1 && data) {
        return data[0]?.id;
    }
    return null;
}

export const findNextQuesId = (data, number, total) => {
    if (total > number && data) {
        return data[1]?.id;
    }
    return null;
}

export const handleOptionBg = (viewTab, index, selected, userResponse, correctResponse, multipleSelect) => {
    if (selected === index || multipleSelect?.includes(index))
        return 'var(--green-100)';
    else if (viewTab === 3 && userResponse?.includes(index)) {
        if (correctResponse?.includes(index))
            return 'var(--green-100)';
        else
            return 'var(--red-100)';
    }
    return 'transparent'
}

export const checkForSave = (single, integer, multipleLength) => {
    if (single !== -1 || (integer !== '' && integer !== null) || multipleLength > 0)
        return true;
    return false;
}